import moment from "moment-timezone";
import request from "superagent";
import { logEvent } from "frontend-admin/src/utils/segment/logEvents";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";

import {
  logout,
  FIREBASE_LOGIN_SUCCESS,
  get as fetchSession,
  LOGOUT,
  LOGIN_REQUESTED,
  FIREBASE_TOKEN_READY,
  ADMIN_PROFILE_SUCCESS,
} from "./../session";
import { FirebaseAuth } from "../../utils/firebase";
import firebase from "firebase/app";

import { getFacilityUser } from "../../api/facilityUsers";
import { initLogging } from "../../utils/mixpanel/mixpanel";
import { firebaseAppAnalytics } from "../../utils/firebaseAnalytics";
import meta from "../../utils/meta";
import { getAuthHeader } from "../..//utils/superagent";
import { removeDatadogRUMUser } from "../../utils/datadogRUM";

const initFirebaseSession = () => async (dispatch) => {
  initAnalytics();
  /*
   * @Warning
   * onAuthStateChanged() listener causes onAuthChanged() function to be triggered twice, hence commented out
   */
  // FirebaseAuth.onAuthStateChanged(onAuthChanged(dispatch))

  // This method has the same behavior as firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0. (Firebase Docs)
  FirebaseAuth.onIdTokenChanged(onAuthChanged(dispatch));
  await FirebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  dispatch({
    type: FIREBASE_TOKEN_READY,
    data: {
      ready: false,
    },
  });
};

const initAnalytics = () => {
  const { mixpanelToken, firebaseConfig } = meta();
  firebaseAppAnalytics.init(firebaseConfig);
  initLogging(mixpanelToken);
};

const onFacilityLogin = async ({ dispatch, user, tokenResult }) => {
  const facilityIdsArray = user?.facilities.map((facility) => facility.userId);
  const selectedFacility = localStorage.getItem("selectedFacility");
  const selectedUser = localStorage.getItem("selectedUser");
  const isSelectedFacilityExists =
    selectedFacility && facilityIdsArray.includes(selectedFacility);
  const userId = isSelectedFacilityExists
    ? selectedFacility
    : facilityIdsArray[0] || selectedUser;

  // when facility user logins, selectedFacility is empty (we clear localStorage after loggin out)
  if (!isSelectedFacilityExists) {
    localStorage.setItem("selectedFacility", facilityIdsArray[0]);
    logEvent(HCF_USER_EVENTS.LOGGED_IN, {
      workplaceId: userId,
      workplaceUserId: user?._id,
    });
  }

  dispatch(
    fetchSession({
      userId,
      isFirebaseSession: true,
    }),
  );

  dispatch({
    type: FIREBASE_LOGIN_SUCCESS,
    data: {
      firebaseToken: tokenResult.token,
      user: {
        facilityIds: facilityIdsArray,
        tmz: user.tmz,
        access: user.roles,
        permissions: user.permissions,
        _id: user._id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        notify: user.notify,
        isEmailVerified: user.isEmailVerified,
        isSuspicious: user.isSuspicious,
        ...(user?.intercomHash && { intercomHash: user.intercomHash }),
      },
    },
  });
};

const onCBHAdminLoggedIn = async ({ dispatch, user, tokenResult }) => {
  const selectedUser = localStorage.getItem("selectedUser");

  dispatch({
    type: LOGIN_REQUESTED,
  });

  if (tokenResult) {
    dispatch(
      fetchSession({
        userId: selectedUser || user._id,
        isFirebaseSession: false,
      }),
    );
  }
  dispatch({
    type: FIREBASE_LOGIN_SUCCESS,
    data: {
      firebaseToken: tokenResult.token,
      user: {
        access: user.roles,
        _id: user._id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        ...(user?.intercomHash && { intercomHash: user.intercomHash }),
      },
    },
  });
};

const handleEmployeeLogin = async (dispatch, tokenResult) => {
  const resp = await request
    .get(`${global.api}/user/getByEmail`)
    .set(await getAuthHeader());

  dispatch({ type: ADMIN_PROFILE_SUCCESS, data: { user: resp.body } });

  const user = { ...resp.body };
  onCBHAdminLoggedIn({ dispatch, user, tokenResult });
};

const handleFacilityLogin = async (dispatch, tokenResult) => {
  const { user } = await getFacilityUser();
  onFacilityLogin({ dispatch, user, tokenResult });
};

const onLoggedIn = async (dispatch, user) => {
  const tokenResult = await user.getIdTokenResult();
  const { email } = tokenResult.claims;
  localStorage.setItem("AUTH_TOKEN", tokenResult.token);
  dispatch({
    type: FIREBASE_TOKEN_READY,
    data: {
      ready: true,
    },
  });
  email?.includes("clipboardhealth.com")
    ? await handleEmployeeLogin(dispatch, tokenResult)
    : await handleFacilityLogin(dispatch, tokenResult);
};

const keepUserSessionAlive = async (dispatch, user) => {
  const idTokenResult = await user.getIdTokenResult();
  if (moment().diff(moment(idTokenResult.authTime), "days") > 30) {
    dispatch(logout());
    localStorage.clear();
    removeDatadogRUMUser();
  }
};

const onLoggedOut = async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  removeDatadogRUMUser();
};

const onAuthChanged = (dispatch) => (user) => {
  if (user) {
    keepUserSessionAlive(dispatch, user);
    onLoggedIn(dispatch, user);
  } else {
    onLoggedOut(dispatch);
  }
};

export { initFirebaseSession };
