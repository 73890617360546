import { FirebaseAuth } from "../../../utils/firebase";

const onFirebaseError = (error) => {
  switch (error.code) {
    case "auth/user-disabled":
      return {
        error:
          "Your account has been deactivated, if you believe this is in error please call or text us.",
      };

    case "auth/network-request-failed":
      return {
        error:
          "Network Connectivity error. Check your network connection and try again later.",
      };

    case "auth/quota-exceeded":
      return {
        error: "Verification code error. Try again after after an hour.",
      };

    case "auth/expired-action-code":
      return {
        error: "Verification code expired. Try requesting again",
      };

    case "auth/invalid-email":
      return {
        error: "Your Email Id is not correct",
        code: error.code,
      };

    case "auth/invalid-verification-code":
      return {
        error: "Your link is expired or invalid!",
      };

    case "auth/invalid-action-code":
      return {
        error: "Your link is expired or invalid !",
      };

    default:
      return {
        error: error.message,
      };
  }
};

const signInWithEmailLink = async (email) => {
  const emailToLogin = email || localStorage.getItem("emailToVerify");
  const url = window.location.href;

  try {
    await FirebaseAuth.signInWithEmailLink(emailToLogin, url);
    localStorage.removeItem("emailToVerify");
    return { success: true };
  } catch (error) {
    return onFirebaseError(error);
  }
};

const signInWithCustomToken = async (token) => {
  try {
    await FirebaseAuth.signInWithCustomToken(token);
    return { success: true };
  } catch (error) {
    return onFirebaseError(error);
  }
};

export { signInWithEmailLink, signInWithCustomToken };
