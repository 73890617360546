import { ComponentType, lazy, LazyExoticComponent } from "react";
import { NewWebVersionModal } from "frontend-admin/src/components/NewWebVersionModal/NewWebVersionModal";
import retry from "async-retry";

// in order to mimic what React.lazy does, we use the same function's signature
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/index.d.ts#L881
export function loadLazily<T extends ComponentType<unknown>>(
  factory: () => Promise<{ default: T }>,
): LazyExoticComponent<T> {
  return lazy(async () => {
    try {
      return await retry(factory, {
        retries: 2,
        minTimeout: 200,
      });
    } catch {
      return Promise.resolve({ default: NewWebVersionModal as unknown as T });
    }
  });
}
