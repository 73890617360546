import { StarFilled } from "@ant-design/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StringParam, useQueryParam } from "use-query-params";
import { FeatureFlag } from "frontend-admin/src/constants/FEATURE_FLAGS";
import {
  checkAndShowWorkerRatingCarouselModal,
  hideWorkerReviewsBanner,
  hideWorkerRatingCarouselModal,
  showWorkerRatingCarouselModal,
} from "frontend-admin/src/modules/workerReviews";
import { WorkerRatingCarouselModal } from "../WorkerRatingCarouselModal/WorkerRatingCarouselModal";
import { logBannerClick, logBannerClosed } from "./logs";
import { SessionType } from "frontend-admin/src/modules/interface";
import { Button } from "frontend-admin/src/designsystem/Button/Button";
import { Banner } from "frontend-admin/src/designsystem/Banner/Banner";
import { includes } from "lodash";
import { FACILITY_ROLES } from "../../constants/facility";

export const WorkerReviewsBanner = (): JSX.Element => {
  const { admin, userId, user, type, profile } = useSelector(
    (state: SessionType) => state.session || {},
  );
  const workerReviews = useSelector(
    (state: SessionType) => state.workerReviews,
  );
  const dispatch = useDispatch();
  const [showWorkerReviewsCarousel, setShowWorkerReviewsCarousel] =
    useQueryParam("worker-reviews-carousel", StringParam);
  const ldFlags = useFlags();
  const frequencyInDays =
    ldFlags[FeatureFlag.WORKER_RATINGS_CAROUSEL]?.frequencyInDays;

  const handleWorkerRatingCarouselModalClosed = () => {
    hideWorkerRatingCarouselModal(dispatch, userId);
    setShowWorkerReviewsCarousel(undefined);
  };

  const handlerBannerClosed = () => {
    hideWorkerReviewsBanner(dispatch, userId);
    if (user) {
      logBannerClosed(
        userId,
        user._id,
        workerReviews.numberOfAwaitingWorkerReviews,
      );
    }
  };

  const handleOpenModal = () => {
    showWorkerRatingCarouselModal(dispatch);
    if (user) {
      logBannerClick(
        userId,
        user._id,
        workerReviews.numberOfAwaitingWorkerReviews,
      );
    }
  };

  useEffect(() => {
    // User with supervisor role (FACILITY_ROLES.DOCUMENT)
    // can not see the worker reviews modal.
    const shouldShowWorkerRatingCarouselModal =
      includes(user?.access, FACILITY_ROLES.ADMIN) ||
      includes(user?.access, FACILITY_ROLES.INVOICES) ||
      includes(user?.access, FACILITY_ROLES.SHIFT_MANAGEMENT);

    if (
      frequencyInDays &&
      !admin &&
      userId &&
      shouldShowWorkerRatingCarouselModal
    ) {
      checkAndShowWorkerRatingCarouselModal(
        dispatch,
        userId,
        !!showWorkerReviewsCarousel,
        frequencyInDays,
      );
    }
  }, [frequencyInDays, admin, userId, user, showWorkerReviewsCarousel]);

  return (
    <>
      {workerReviews.workerReviewsBannerVisible &&
        workerReviews.numberOfAwaitingWorkerReviews > 0 && (
          <Banner
            title={
              <b>
                You have {workerReviews.numberOfAwaitingWorkerReviews}{" "}
                {workerReviews.numberOfAwaitingWorkerReviews > 1
                  ? "workers"
                  : "worker"}{" "}
                waiting to be rated!
              </b>
            }
            description={
              <>
                The more ratings you give, the more likely you are to be
                connected with highly rated workers.
              </>
            }
            type="warning"
            closable
            action={
              <Button variant="ghost" onClick={handleOpenModal}>
                <b>Rate these workers now</b>
              </Button>
            }
            onClose={handlerBannerClosed}
            data-testid="worker-carosel-banner"
          />
        )}
      <br />
      {frequencyInDays &&
        workerReviews.workerReviewsModalVisible &&
        userId &&
        user?._id &&
        !admin && (
          <WorkerRatingCarouselModal
            workplaceId={userId}
            workplaceUserId={user._id}
            visible={workerReviews.workerReviewsModalVisible}
            onClose={handleWorkerRatingCarouselModalClosed}
            timezone={profile?.tmz}
            actionBy={type}
            triggeredBy={workerReviews.triggeredBy}
          />
        )}
    </>
  );
};
