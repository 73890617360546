import { Checkbox, Modal } from "antd";
import { Button } from "frontend-admin/src/designsystem/Button/Button";
import { Input } from "frontend-admin/src/designsystem/Input/Input";
import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 16px;
  max-width: 1232px;
  color: #333;
  margin: 16px 48px 0 48px;
`;

export const PageTitle = styled.div`
  font-size: 24px;
`;
export const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SearchField = styled(Input)`
  border-radius: 8px;
  background-color: rgba(160, 160, 160, 0.15);
  max-width: 100%;
  margin: 16px 0;

  .ant-input-prefix {
    color: rgba(134, 134, 134, 0.8);
    padding-left: 4px;
    font-size: 20px;
  }

  .ant-input.ant-input {
    flex: 1;
    max-width: unset;
    background: transparent;
    padding-left: 8px;
  }

  input::placeholder {
    color: #868686;
  }
`;

export const FilterButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Workers = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 400px);
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-header {
    border-radius: 4px 4px 0 0;
  }
`;

export const CheckboxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
`;

export const CheckboxCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  > div {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: #032e68;

  .ant-checkbox-inner.ant-checkbox-inner {
    border-color: #032e68;
  }

  .ant-checkbox-checked {
    &:after {
      border: 1px solid #032e68;
    }
    .ant-checkbox-inner {
      background-color: #032e68;
    }
  }
`;

export const FilterLabel = styled.h3`
  font-size: 20px;
  margin-bottom: 24px;
`;

export const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
`;
