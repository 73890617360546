import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { FacilityShiftWithDocument } from "frontend-admin/src/containers/facilityDashboard/ShiftCalendar/dayView/types";
import { WorkerTypeValues } from "frontend-admin/src/workers/WorkerTypes";

export enum NegotiationStatus {
  OPEN = "OPEN",
  EXPIRED = "EXPIRED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  CLOSED = "CLOSED",
}

export enum NegotiationType {
  RATE = "RATE",
  TIME = "TIME",
}

export enum NegotiationHistoryAction {
  STARTED = "STARTED",
  PROPOSED = "PROPOSED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  BOOKED_BY_HCP = "BOOKED_BY_HCP",
  BOOKED_BY_ANOTHER_HCP = "BOOKED_BY_ANOTHER_HCP",
  BOOKED_CONFLICTING_SHIFT = "BOOKED_CONFLICTING_SHIFT",
  SHIFT_ASSIGNED_BY_ADMIN = "SHIFT_ASSIGNED_BY_ADMIN",
  SHIFT_LEAD_TIME_EXPIRED = "SHIFT_LEAD_TIME_EXPIRED",
  WORKER_SUSPENDED = "WORKER_SUSPENDED",
  WORKPLACE_SUSPENDED = "WORKPLACE_SUSPENDED",
  WORKER_DEACTIVATED = "WORKER_DEACTIVATED",
  WORKPLACE_DEACTIVATED = "WORKPLACE_DEACTIVATED",
  WORKER_BLOCKED = "WORKER_BLOCKED",
  WORKPLACE_BLOCKED = "WORKPLACE_BLOCKED",
}

export enum NegotiationRateRole {
  WORKER = "WORKER",
  WORKPLACE = "WORKPLACE",
  ADMIN = "ADMIN",
}

export interface NegotiationHistoryItem {
  _id: string;
  action: NegotiationHistoryAction;
  createdByUserId?: string;
  createdByUserRole?: NegotiationRateRole;
  payRate?: number;
  start?: string;
  end?: string;
  createdAt: string;
}

interface NegotiationMetadata {
  workerName?: string;
  workerPhoto?: string;
  workerRating?: number;
  lastOffer?: NegotiationHistoryItem;
}

export interface Negotiation {
  _id: string;
  status: NegotiationStatus;
  type: NegotiationType;
  shiftId: string;
  workerId: string;
  workplaceId?: string;
  multiplier?: number;
  history: NegotiationHistoryItem[];
  initialChargeRate: number;
  initialPayRate: number;
  initialStart?: string;
  initialEnd?: string;
  createdAt?: string;
  updatedAt?: string;
  metadata?: NegotiationMetadata;
}

export interface NegotiationWithShiftDetails {
  _id: string;
  status: NegotiationStatus;
  shiftId: string;
  workerId: string;
  workplaceId: string;
  shift: {
    _id: string;
    start: string;
    name: string;
    end: string;
    agentReq: WorkerTypeValues;
  };
}

export interface INegotiationHistoryProps {
  negotiation: Negotiation;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
  admin: boolean;
  facilityTimezone: string;
}

export interface INegotiationsListProps {
  admin: boolean;
  negotiations: Negotiation[];
  selectedNegotiation: Negotiation;
  facilityTimezone: string;
  setSelectedNegotiationId: Dispatch<SetStateAction<string>>;
  showWorkerRatings?: boolean;
}

export enum ListNegotiationsCustomField {
  LAST_OFFER = "LAST_OFFER",
  WORKER_DETAILS = "WORKER_DETAILS",
  SHIFT_DETAILS = "SHIFT_DETAILS",
}

export interface IProposalComponentProps {
  negotiation: Negotiation;
  updateNegotiationInState: (
    updatedNegotiationData: Negotiation,
    setSelected?: boolean,
  ) => void;
  shift: FacilityShiftWithDocument;
  maxCharge: number;
  negotiations: Negotiation[];
}

export interface INegotiationActionsProps {
  negotiation: Negotiation;
  facilityTimezone: string;
  otherNegotiations: Negotiation[];
  updateNegotiationInState: (
    updatedNegotiationData: Negotiation,
    setSelected?: boolean,
  ) => void;
  handleRefetchFacilityShifts?: () => void;
  chargeBreakdown?: IChargeBreakdown;
}

export interface IRateSummaryProps {
  admin: boolean;
  chargeBreakdown?: IChargeBreakdown;
  stateCode?: string;
}

export interface IRateNegotiationModalProps {
  admin?: boolean;
  isOpen: boolean;
  shift: FacilityShiftWithDocument;
  workerTypeLabel: string;
  date: string;
  onClose: () => void;
  negotiations: Negotiation[];
  setNegotiations: Dispatch<SetStateAction<Negotiation[]>>;
  handleRefetchFacilityShifts?: (lastRefreshedDateCellAt?: boolean) => void;
  selectedShiftNegotiationId?: string;
}

export interface IChargeBreakdown {
  payRate: string;
  deltaPayRate: string;
  initialChargeRate: string;
  deltaChargeRate: string;
  totalCharge: string;
  maxCharge: string;
}

export interface INegotiationAcceptedEvent {
  negotiation: Negotiation;
  otherNegotiations: Negotiation[];
}

export interface INegotiationEndedEvent {
  negotiation: Negotiation;
  otherNegotiations: Negotiation[];
}

export interface INegotiationProposedEvent {
  negotiation: Negotiation;
  rate: number;
  negotiations: Negotiation[];
}

export enum RateNegotiationConfigEnum {
  ON = "ON",
  OFF = "OFF",
}
