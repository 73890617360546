import { Descriptions, Modal, Select } from "antd";
import {
  BATCH_DESCRIPTIONS,
  BATCH_HOURS,
  BATCH_MERIDIEM,
} from "frontend-admin/src/containers/myAccount/constants";
import { Dropdown } from "frontend-admin/src/containers/myAccount/styles";
import { convertMilitaryTime } from "frontend-admin/src/containers/myAccount/utilities";
import { SessionType } from "frontend-admin/src/modules/interface";
import {
  toggleModal,
  updateBatchNotification,
} from "frontend-admin/src/modules/myAccount/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

export const BatchModal = () => {
  const dispatch = useDispatch();
  const { showModal, modalData } = useSelector(
    (state: SessionType) => state.myAccount,
  );
  const [batchTime, setBatchTime] = useState({
    hour: 1,
    meridiem: "am",
  });

  useEffect(() => {
    if (!modalData) {
      return;
    }
    const {
      alert: { time },
    } = modalData;
    const { hour, meridiem } = convertMilitaryTime(time);

    setBatchTime({
      hour,
      meridiem,
    });
  }, [modalData]);

  if (!modalData) {
    return null;
  }

  return (
    <Modal
      title="Daily Settings"
      centered={true}
      visible={showModal}
      onCancel={() => dispatch(toggleModal(!showModal))}
      onOk={() => updateBatchNotification(dispatch, batchTime)}
      okText={"Set time preference"}
      width={800}
      closeIcon={<></>}
    >
      <Descriptions
        colon={false}
        labelStyle={{ width: 225, fontWeight: "bold", fontSize: 16 }}
        style={{ paddingBottom: 0 }}
        contentStyle={{ fontSize: 16 }}
        column={2}
        bordered
        size={"small"}
        layout="vertical"
      >
        <Descriptions.Item label="Time">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Dropdown
              style={{ minWidth: 72 }}
              value={batchTime.hour}
              onChange={(hour) => {
                setBatchTime((currentHour) => ({ ...currentHour, hour }));
              }}
            >
              {BATCH_HOURS.map((hour) => (
                <Option value={hour} key={hour}>
                  {hour}
                </Option>
              ))}
            </Dropdown>
            <span style={{ width: 10 }}></span>
            <Dropdown
              style={{ minWidth: 72 }}
              value={batchTime.meridiem}
              onChange={(meridiem) => {
                setBatchTime((currentHour) => ({ ...currentHour, meridiem }));
              }}
            >
              {BATCH_MERIDIEM.map((meridiem) => (
                <Option value={meridiem} key={meridiem}>
                  {meridiem.toUpperCase()}
                </Option>
              ))}
            </Dropdown>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {BATCH_DESCRIPTIONS}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};
