import { useState, useEffect, useCallback } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "../constants/FEATURE_FLAGS";
import { RateNegotiationConfigEnum } from "frontend-admin/src/components/RateNegotiationModal/interfaces";

interface IUseRateNegotiationFlag {
  isAdmin: boolean;
  facilityRateNegotiationFlag: RateNegotiationConfigEnum | undefined;
  facilityId: string;
  facilityMsa: string;
  facilityState: string;
}

interface INegotiationFeatureFlag {
  id?: string[];
  state?: string[];
  msa?: string[];
  global?: boolean;
}

/**
 * Checks if the "rate negotiation" feature is enabled for this facility based on its attributes.
 */
export const useRateNegotiationFlag = ({
  isAdmin,
  facilityRateNegotiationFlag,
  facilityId,
  facilityMsa,
  facilityState,
}: IUseRateNegotiationFlag): {
  isActive: boolean;
  featureFlagStatus: boolean;
  timeNegotiationFeatureFlagStatus: boolean;
} => {
  const ldFlags = useFlags();
  const [isRateNegotiationActive, setIsRateNegotiationActive] =
    useState<boolean>(isAdmin);
  // Decides activation. It is a combination of FF + DB. For admin it is always true.

  const [
    rateNegotiationFeatureFlagStatus,
    setRateNegotiationFeatureFlagStatus,
  ] = useState<boolean>(false);
  // This is just the FF value. Does not decide activation of feature.

  const [
    timeNegotiationFeatureFlagStatus,
    setTimeNegotiationFeatureFlagStatus,
  ] = useState<boolean>(isAdmin);
  // This is FF value BUT also decides activation unlike rate, so always true for admins

  const getFeatureFlagStatus = useCallback(
    (activationConditions: INegotiationFeatureFlag): boolean => {
      const facilityUserIdEnabled = !!activationConditions.id?.includes(
        facilityId?.toString(),
      );
      const facilityStateEnabled =
        !!activationConditions.state?.includes(facilityState);
      const facilityMsaEnabled =
        !!activationConditions?.msa?.includes(facilityMsa);
      const ffStatus =
        facilityUserIdEnabled ||
        facilityStateEnabled ||
        facilityMsaEnabled ||
        !!activationConditions.global;
      return ffStatus;
    },
    [facilityState, facilityMsa, facilityId],
  );

  useEffect(() => {
    const rateActivationConditions: INegotiationFeatureFlag =
      ldFlags[FeatureFlag.RATE_NEGOTIATION_ACTIVATION] ?? {};
    const timeActivationConditions: INegotiationFeatureFlag =
      ldFlags[FeatureFlag.TIME_NEGOTIATION_ACTIVATION] ?? {};

    const rateFFStatus = getFeatureFlagStatus(rateActivationConditions);
    const timeFFStatus = getFeatureFlagStatus(timeActivationConditions);
    setRateNegotiationFeatureFlagStatus(rateFFStatus);
    setTimeNegotiationFeatureFlagStatus(isAdmin || timeFFStatus);
    setIsRateNegotiationActive(
      isAdmin ||
        (facilityRateNegotiationFlag === RateNegotiationConfigEnum.ON &&
          rateFFStatus),
    );
  }, [
    facilityId,
    facilityMsa,
    facilityRateNegotiationFlag,
    facilityState,
    isAdmin,
    ldFlags[FeatureFlag.RATE_NEGOTIATION_ACTIVATION],
    ldFlags[FeatureFlag.TIME_NEGOTIATION_ACTIVATION],
  ]);

  return {
    isActive: isRateNegotiationActive,
    featureFlagStatus: rateNegotiationFeatureFlagStatus,
    timeNegotiationFeatureFlagStatus,
  };
};
