import { FormInstance } from "antd";
import { FacilityRole } from "frontend-admin/src/api/facilityRoles";
import { FacilityFlags } from "frontend-admin/src/containers/facilityUser/helpers";
import {
  MyAccountActionTypes,
  MYACCOUNT_ACTIONS,
} from "frontend-admin/src/modules/myAccount/actions";
import {
  IEmailModalData,
  IFormData,
  IFormValue,
  IModalData,
  IUser,
  TypeAlertSettings,
} from "frontend-admin/src/modules/myAccount/types";

export type MyAccountStateType = {
  loading: boolean;
  user?: IUser;
  showForm: boolean;
  formData?: IFormData;
  form?: FormInstance<IFormValue>;
  shouldLogout: boolean;
  showModal: boolean;
  emailModalData?: IEmailModalData;
  showEmailModal: boolean;
  modalData?: IModalData;
  existingUser?: IUser;
  roles?: FacilityRole[];
  userRoles?: string[];
  redirectUrl?: string;
  flags?: FacilityFlags;
  deactivateModal: boolean;
  alertSettings?: TypeAlertSettings;
};

export const initialState: MyAccountStateType = {
  loading: false,
  user: undefined,
  showForm: false,
  formData: undefined,
  form: undefined,
  shouldLogout: false,
  showModal: false,
  modalData: undefined,
  showEmailModal: false,
  emailModalData: undefined,
  existingUser: undefined,
  roles: undefined,
  userRoles: undefined,
  redirectUrl: undefined,
  flags: undefined,
  deactivateModal: false,
  alertSettings: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const myAccount = (state = initialState, action: MyAccountActionTypes) => {
  const { type, payload } = action;
  switch (type) {
    case MYACCOUNT_ACTIONS.LOADING:
      return {
        ...state,
        loading: payload,
      };
    case MYACCOUNT_ACTIONS.TOGGLE_FORM:
      return {
        ...state,
        showForm: payload,
      };
    case MYACCOUNT_ACTIONS.TOGGLE_MODAL:
      return {
        ...state,
        showModal: payload,
      };
    case MYACCOUNT_ACTIONS.SET_USER:
      return {
        ...state,
        user: payload,
      };
    case MYACCOUNT_ACTIONS.SET_LOGOUT:
      return {
        ...state,
        shouldLogout: payload,
      };
    case MYACCOUNT_ACTIONS.SET_FORM_DATA:
      return {
        ...state,
        formData: payload,
      };
    case MYACCOUNT_ACTIONS.SET_MODAL_DATA:
      return {
        ...state,
        modalData: payload,
      };
    case MYACCOUNT_ACTIONS.SET_FORM_REFERENCE:
      return {
        ...state,
        form: payload,
      };
    case MYACCOUNT_ACTIONS.TOGGLE_EMAIL_MODAL:
      return {
        ...state,
        showEmailModal: payload,
      };
    case MYACCOUNT_ACTIONS.SET_EMAIL_MODAL_DATA:
      return {
        ...state,
        emailModalData: payload,
      };
    case MYACCOUNT_ACTIONS.SET_EXISTING_USER:
      return {
        ...state,
        existingUser: payload,
      };
    case MYACCOUNT_ACTIONS.SET_ROLES:
      return {
        ...state,
        roles: payload,
      };
    case MYACCOUNT_ACTIONS.SET_USER_ROLES:
      return {
        ...state,
        userRoles: payload,
      };
    case MYACCOUNT_ACTIONS.SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: payload,
      };
    case MYACCOUNT_ACTIONS.SET_FLAGS:
      return {
        ...state,
        flags: payload,
      };
    case MYACCOUNT_ACTIONS.TOGGLE_DEACTIVATE_MODAL:
      return {
        ...state,
        deactivateModal: payload,
      };
    case MYACCOUNT_ACTIONS.SET_ALERT_SETTINGS:
      return {
        ...state,
        alertSettings: payload,
      };
    case MYACCOUNT_ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

export default myAccount;
