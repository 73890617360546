import React from "react";
import debounce from "lodash/debounce";

interface DebounceOptions {
  delay: number;
}

// explain why 250 is the median reaction time https://stackoverflow.com/a/44755058/1349340
export const REACTION_TIME = 250;

export const useDebounce = <T extends unknown[]>(
  func: (...args: T) => void,
  deps: readonly unknown[],
  { delay }: DebounceOptions = { delay: REACTION_TIME },
) => React.useCallback(debounce(func, delay), deps);
