import { useCallback, useEffect, useRef } from "react";
import { Drawer } from "antd";
import { INotificationCenterProps } from "./interfaces";
import "./styles.scss";

const className = "notification-center";
const title = "Notifications";
const contentCardKeyValues = [
  {
    key: "type",
    value: "NOTIFICATION_CENTER",
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterContentCards = (cards: any = []) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cards.filter((card: any) =>
    contentCardKeyValues.every(
      ({ key, value }) => card.extras?.[key] === value,
    ),
  );

const NotificationCenter = ({
  onClose,
  isVisible,
}: INotificationCenterProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { braze } = window as any;
  const contentCardFeedRef = useRef<HTMLDivElement>(null);

  const filterContentCardsByKeyValuePairs = useCallback(filterContentCards, []);

  useEffect(() => {
    let parentNodeForBraze;
    if (isVisible && braze && contentCardFeedRef?.current) {
      parentNodeForBraze = contentCardFeedRef.current;
      braze.requestContentCardsRefresh(() => {
        braze.showContentCards(
          contentCardFeedRef.current,
          filterContentCardsByKeyValuePairs,
        );
      });
    }
    return () => {
      if (parentNodeForBraze) braze.hideContentCards(parentNodeForBraze);
    };
  }, [braze, filterContentCardsByKeyValuePairs, isVisible, contentCardFeedRef]);

  return (
    <Drawer
      data-testid={"notification-center-drawer"}
      className={className}
      placement="right"
      onClose={onClose}
      visible={isVisible}
      title={title}
      destroyOnClose
    >
      <div
        id="custom-feed"
        ref={contentCardFeedRef}
        className={className + " custom-feed"}
        data-testid={"notification-center-custom-feed"}
      ></div>
    </Drawer>
  );
};

export { NotificationCenter, filterContentCards };
