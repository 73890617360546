import { useMemo } from "react";
import { logErrors } from "../../components/errorBoundary/api";
import Apps from "../../constants/Apps";
import WorkPlaceType from "../interfaces/WorkPlaceType";
import { useSelector } from "react-redux";
import IWorkerAndWorkPlaceTypeStore from "../../modules/workerAndWorkplaceTypes/store/Store";
import { WorkerTypeValues } from "../WorkerTypes";

const createWorkPlaceToWorkerMapping = (
  workplaces: WorkPlaceType[],
): Record<string, WorkerTypeValues[]> => {
  try {
    if (!workplaces) return {};

    return workplaces.reduce((mapping, workplace) => {
      return {
        ...mapping,
        [workplace.name]: workplace.workerTypes.map(
          (workerType) => workerType.name,
        ),
      };
    }, {});
  } catch (error) {
    const {
      stack,
      message = "Error occurred while creating workplace to worker mapping",
    } = error as Error;

    logErrors({
      app: Apps.Admin,
      message,
      stack: JSON.stringify(stack),
    });
    return {};
  }
};

const useWorkplaceToWorkerMapper = () => {
  const { workPlaceTypes = [], workerTypes = [] } = useSelector<
    Record<"workerAndWorkPlaceTypes", IWorkerAndWorkPlaceTypeStore>,
    IWorkerAndWorkPlaceTypeStore
  >((store) => store.workerAndWorkPlaceTypes || {});
  return {
    workPlaceTypes,
    workerTypes,
    workPlaceTypesObj: useMemo(
      () => createWorkPlaceToWorkerMapping(workPlaceTypes),
      [workPlaceTypes],
    ),
  };
};

const getWorkerOldIdMapper = (
  workerTypes: IWorkerAndWorkPlaceTypeStore["workerTypes"],
): Record<string, string> => {
  return workerTypes.reduce((acc, curr) => {
    acc[String(curr.id)] = curr.name.toUpperCase().replace(/ /g, "_");
    return acc;
  }, {});
};

export {
  createWorkPlaceToWorkerMapping,
  useWorkplaceToWorkerMapper,
  getWorkerOldIdMapper,
};
