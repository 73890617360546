import axios from "axios";

const isLoggedIn = () => {
  return (
    localStorage.getItem("AUTH_TOKEN") &&
    localStorage.getItem("AUTH_TOKEN").length > 0
  );
};

const getIdTokenFromCustomToken = async (customToken) => {
  const response = await axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithCustomToken?key=${process.env.FIREBASE_API_KEY}`,
    { token: customToken, returnSecureToken: true }
  );
  return response.data.idToken;
};

export { isLoggedIn, getIdTokenFromCustomToken };
