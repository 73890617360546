import { NurseWorkerType, WorkerTypes } from "./WorkerTypes";

export const WORKER_TIMEOUT_DURATION = 5000;
export const WORKER_RETRY_COUNT = 3;
/**
 * Should appear in a sorted worker type list if the worker type is available.
 */
export const PRIORITY_WORKER_TYPE = [
  WorkerTypes.CNA,
  WorkerTypes.LVN,
  NurseWorkerType.NURSE,
  WorkerTypes.RN,
  WorkerTypes.CAREGIVER,
];
