import { logEvent } from "frontend-admin/src/utils/segment/logEvents";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";

export function logBannerClosed(
  workplaceId: string,
  workplaceUserId: string,
  numberOfAwaitingReviews: number,
) {
  logEvent(HCF_USER_EVENTS.RATINGS_BANNER_CLOSED, {
    workplaceId,
    workplaceUserId,
    createdAt: new Date().toISOString(),
    numberOfAwaitingReviews,
  });
}

export function logBannerClick(
  workplaceId: string,
  workplaceUserId: string,
  numberOfAwaitingReviews: number,
) {
  logEvent(HCF_USER_EVENTS.RATINGS_BANNER_CLICKED, {
    workplaceId,
    workplaceUserId,
    numberOfAwaitingReviews,
    createdAt: new Date().toISOString(),
  });
}
