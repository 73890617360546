import { IoMdClose } from "react-icons/io";
import { StyledPopover, Wrapper } from "./Popover.styles";
import { Popover as AntdPopover } from "antd";

interface PopoverProps {
  variant: "primary" | "secondary";
  content: string;
  open: boolean;
  showPopover: boolean;
  onHandleClose: () => void;
  placement:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  width?: number;
  children: JSX.Element;
}

export const Popover = ({
  variant,
  content,
  open,
  showPopover,
  onHandleClose,
  placement,
  width,
  children,
}: PopoverProps) => {
  return showPopover ? (
    <StyledPopover variant={variant} width={width}>
      <AntdPopover
        getPopupContainer={(triggerNode) => triggerNode}
        content={
          <Wrapper onClick={(e) => e.stopPropagation()} variant={variant}>
            <p>{content}</p>
            <div>
              <IoMdClose onClick={onHandleClose} />
            </div>
          </Wrapper>
        }
        trigger="click"
        placement={placement}
        visible={open}
      >
        {children}
      </AntdPopover>
    </StyledPopover>
  ) : (
    children
  );
};
