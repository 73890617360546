import {
  Col,
  Descriptions as AntDescriptions,
  Form as AntForm,
  List as AntList,
  Row,
  Select,
  Skeleton,
} from "antd";
import styled from "styled-components";

export const DownChevron = () => (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.0575 0.428589L4.5 3.66887L7.9425 0.428589L9 1.43103L4.5 5.66668L0 1.43103L1.0575 0.428589Z"
      fill="#868686"
    />
  </svg>
);

export const ContainerWrapper = styled("section")`
  margin-bottom: 30px;
  background: white;
  padding: 0 16px 6px;
  max-width: 959px;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  color: #868686;
`;

export const Box = styled("div")`
  p {
    color: #868686;
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export const Header = styled("header")`
  max-width: 959px;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #828282;
`;

export const Heading = styled("h2")`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #424242;
  margin-bottom: 0;
`;

export const SubHeading = styled("h3")`
  font-weight: bold;
  font-size: 18px;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const Blank = styled.span.attrs({
  "data-testid": "notification-blank",
})`
  width: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 1px;
  display: inline-block;
  margin-right: 18px;
`;

export const Wrapper = styled("div")`
  & + main {
    display: none !important;
  }
`;

export const RoleWrapper = styled("div")`
  &:not(:last-child) {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding-bottom: 20px;
  }
`;

export const ListHeaderItem = styled(Col)`
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #424242;
`;

export const List = styled(AntList)`
  .ant-spin-container > .ant-row > div {
    &:not(:last-child) {
      border-bottom: 1px solid;
    }
  }
` as typeof AntList;

export const Dropdown = styled(Select)`
  .ant-select-selector {
    border-radius: 5px !important;
    height: 22px !important;
  }

  .ant-select-selection-item {
    color: #868686;
    line-height: 22px !important;
  }

  .ant-select-arrow {
    margin-top: 1px;
    transform: translate(0, -50%);
  }
` as typeof Select;

export const Descriptions = styled(AntDescriptions)`
  .ant-descriptions-row {
    margin-bottom: 10px;
  }
  .ant-descriptions-item {
    min-height: 34px;
    display: flex;
    align-items: center;
  }
`;

export const Form = styled(AntForm)`
  margin-top: 10px;

  .group {
    display: flex;

    .ant-row {
      display: block;
    }

    .ant-form-item {
      flex: 1 1 0%;
      max-width: 258px;
    }

    .ant-form-item-control {
      max-width: 100%;
      margin-right: 20px;
    }
  }
` as typeof AntForm;

export const FormItem = styled(AntForm.Item)`
  margin-bottom: 12px;

  input {
    max-width: 240px;
    width: 100%;
    border-radius: 5px;
  }
`;

type ContainerProps = {
  heading: string;
  children: React.ReactNode;
  buttons?: React.ReactNode;
  loading?: boolean;
};

export const Container = ({
  heading,
  children,
  buttons,
  loading,
}: ContainerProps) => {
  return (
    <ContainerWrapper>
      <Header>
        <Row justify-content={"space-between"} align={"middle"}>
          <Col span={15}>
            <Heading>{heading}</Heading>
          </Col>
          <Col flex={1} style={{ textAlign: "right" }}>
            {buttons}
          </Col>
        </Row>
      </Header>
      {loading && (
        <Box data-testid="loading-container">
          <Skeleton />
        </Box>
      )}
      {!loading && children}
    </ContainerWrapper>
  );
};

export const UneditableNotificationOption = styled("span")`
  padding-right: 18px;
`;
