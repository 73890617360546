import { getLinkToUnblockPushNotifications } from "frontend-admin/src/containers/facilityUser/modifyUser/notificationInput";
import { Link } from "frontend-admin/src/designsystem/Link/Link";
import { INotification } from "frontend-admin/src/modules/myAccount/types";

export const NotificationBlockedMessage = ({
  notification,
}: {
  notification: INotification;
}) => {
  const { braze } = window as any;
  const pushBlocked = braze.isPushBlocked();
  const linkToUnblockPushNotifications = getLinkToUnblockPushNotifications();

  if (!(pushBlocked && notification.push)) {
    return null;
  }
  return (
    <Link
      target="_blank"
      href={linkToUnblockPushNotifications.link}
      style={{ color: "#032E68" }}
    >
      {" - "}
      <span>{linkToUnblockPushNotifications.text}</span>
    </Link>
  );
};
