import { useMutation } from "@tanstack/react-query";
import { Form, message, Skeleton } from "antd";
import { WorkerWithLastShift } from "frontend-admin/src/api/workers";
import {
  logEarlyClose,
  logLastStep,
  logSkipWorkerRating,
} from "frontend-admin/src/components/WorkerRatingCarouselModal/logs";
import {
  createExclusion,
  createWorkerReview,
  deleteExclusion,
  ExclusionForList,
} from "frontend-admin/src/api/workerReview";
import {
  captureWorkerReviewSubmittedEvent,
  doesRatingMakeWorkerFavorite,
  getDNRAction,
  WORKER_REVIEW_DNR_REASON,
} from "frontend-admin/src/components/WorkerRatingPerformanceModal/helpers";
import { Button } from "frontend-admin/src/designsystem/Button/Button";
import {
  showWorkerReviewsBanner,
  updateNumberOfAwaitingReviews,
} from "frontend-admin/src/modules/workerReviews";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  DnrAction,
  WhoCalledRatingPerformanceModal,
  WorkerReviewMode,
} from "frontend-admin/src/components/WorkerRatingPerformanceModal/workerReview.types";
import { Rating, RatingPerformanceForm } from "./RatingPerformanceForm";
import {
  ButtonGroup,
  Container,
  Controls,
  Slider,
  StyledModal,
} from "./styles";
import { SuccessScreen } from "./SuccessScreen";
import { useWorkerAwaitingReviews } from "./useWorkerAwaitingReviews";
import { useWorkerExclusions } from "./useWorkerExclusions";
import { useWorkersForRating } from "./useWorkersForRating";
import { convertToWorkerReviewState } from "./types";
import { errorMessage } from "frontend-admin/src/utils/errors";
import { getLocation } from "frontend-admin/src/utils/routes";

interface WorkerRatingCarouselModalProps {
  visible: boolean;
  onClose: () => void;
  workplaceId: string;
  workplaceUserId: string;
  timezone: string;
  actionBy: string;
  triggeredBy: "banner-click" | "auto-popup";
}

type WorkerWithRatingAndLastShift = {
  worker: { avatarUrl: string; name: string; userId: string };
  lastShift: WorkerWithLastShift["lastShift"];
  rating?: Rating;
  ratingRequestId?: string;
};

export const WorkerRatingCarouselModal: React.FC<
  WorkerRatingCarouselModalProps
> = ({
  visible,
  onClose,
  workplaceId,
  workplaceUserId,
  timezone,
  actionBy,
  triggeredBy,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sessionId, setSessionId] = useState(uuidv4());
  const [form] = Form.useForm();
  const [numberOfReviewsSubmitted, setNumberOfReviewsSubmitted] = useState(0);
  const [formState, setFormState] = useState<WorkerWithRatingAndLastShift[]>(
    [],
  );
  // Use this state for analytics purpose to track rating changes
  const [initialFormState, setInitialFormState] =
    useState<WorkerWithRatingAndLastShift[]>();

  const {
    data: workerAwaitingReviews,
    isLoading: workerAwaitingReviewsLoading,
    invalidateCache: invalidateCacheWorkerAwaitingReviews,
  } = useWorkerAwaitingReviews(
    workplaceId,
    workplaceUserId,
    sessionId,
    triggeredBy,
  );

  const {
    isLoading: workersForRatingLoading,
    invalidateCache: invalidateCacheWorkersForRating,
  } = useWorkersForRating(
    workplaceId,
    workerAwaitingReviews.map((worker) => worker.workerId),
    {
      onSuccess(resp) {
        const states = resp.map((workerForRating) => ({
          worker: {
            userId: workerForRating.userId,
            name: workerForRating.name,
            avatarUrl: workerForRating.profileImageUrl,
          },
          ratingRequestId: workerAwaitingReviews.find(
            (worker) => worker.workerId === workerForRating.userId,
          )?.ratingRequestId,
          lastShift: workerForRating.lastShift,
          // In workers carousel, we don't prefill the existing rating value
          // a facility user has to select values from empty state
          rating: {
            id: workerForRating.rating?.id,
            shiftId: workerForRating.rating?.shiftId,
            updatedAt: workerForRating.rating?.updatedAt,
          },
        }));
        setFormState(states);
        setInitialFormState(states);
      },
    },
  );

  const {
    isLoading: exclusionsLoading,
    data: exclusionsData,
    invalidateCache: invalidateCacheWorkerExclusions,
  } = useWorkerExclusions(
    workplaceId,
    workerAwaitingReviews.map((worker) => worker.workerId),
  );

  const createWorkerReviewMutation = useMutation({
    mutationFn: createWorkerReview,
  });
  const createExclusionMutation = useMutation({
    mutationFn: createExclusion,
  });
  const deleteExclusionMutation = useMutation({
    mutationFn: deleteExclusion,
  });

  const numberOfWorkerAwaitingReviews = workerAwaitingReviews.length;
  const isInitialLoading =
    workerAwaitingReviewsLoading ||
    workersForRatingLoading ||
    exclusionsLoading;
  const isLoading =
    isInitialLoading ||
    createExclusionMutation.isLoading ||
    createWorkerReviewMutation.isLoading ||
    deleteExclusionMutation.isLoading;

  const handleStartAgain = () => {
    setCurrentSlide(0);
    logForLastStep({ reviewTheseWorkersSelected: true });
    setNumberOfReviewsSubmitted(0);
  };

  const handleLoadMore = async () => {
    handleModalClosed();
    logForLastStep({ reviewOtherWorkersSelected: true });

    history.push(
      getLocation("workplaceWorkers", { queryParams: { category: "past" } }),
    );
  };

  const logForLastStep = (opts: {
    reviewOtherWorkersSelected?: boolean;
    reviewTheseWorkersSelected?: boolean;
    closeSelected?: boolean;
  }) => {
    logLastStep(workplaceId, workplaceUserId, sessionId, {
      ...opts,
      totalWorkerSlidesShown: numberOfWorkerAwaitingReviews,
      totalWorkersReviewed: numberOfReviewsSubmitted,
      totalWorkersSkipped:
        numberOfWorkerAwaitingReviews - numberOfReviewsSubmitted,
    });
  };

  const handlerWorkerSubmit = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      // if there is any form validation errors, bail out
      return;
    }

    const workerForRating = formState[currentSlide];
    if (!workerForRating?.rating) {
      return;
    }
    // Only update DNRs in case, when the checkbox is shown.
    const exclusion = exclusionsData[workerForRating.worker.userId];
    const dnrAction = getDNRAction(
      workerForRating.rating?.dnrWorker,
      exclusion,
    );

    try {
      const workerReview = await createWorkerReviewMutation.mutateAsync({
        rating: workerForRating.rating.rating,
        dnrWorker: workerForRating.rating.dnrWorker,
        additionalFeedback: workerForRating.rating.additionalFeedback,
        qualities: workerForRating.rating.qualities,
        workerId: workerForRating.worker.userId,
        workplaceUserId,
        ratingRequestId: workerForRating.ratingRequestId,
        shiftId: workerForRating.rating.shiftId,
        workplaceId,
        id: workerForRating.rating.id,
      });

      const showRemoveDnr =
        (dnrAction === DnrAction.DELETE ||
          doesRatingMakeWorkerFavorite(workerForRating.rating.rating)) &&
        exclusion;
      if (dnrAction === DnrAction.CREATE) {
        await createExclusionMutation.mutateAsync({
          actionBy,
          reason: WORKER_REVIEW_DNR_REASON,
          notes: workerForRating.rating.additionalFeedback ?? "",
          facilityId: workplaceId,
          agentId: workerForRating.worker.userId,
          shiftReviewId: workerReview.id,
          adminId: workplaceUserId,
        });
      } else if (showRemoveDnr) {
        // Delete the existing DNR, if DNR option is not selected and their is already a DNR database document created.
        await deleteExclusionMutation.mutateAsync(exclusion?._id);
      }

      logWorkerReviewSubmitted(
        workerForRating,
        showRemoveDnr ? undefined : exclusion,
        dnrAction,
        {
          success: true,
          id: workerReview.id,
        },
      );
      setCurrentSlide((prevState) => prevState + 1);
      setNumberOfReviewsSubmitted((prevState) => prevState + 1);
      form.resetFields();
    } catch (err) {
      const errorMessageFromAPI = errorMessage(
        err,
        "There is an error when creating rating performance.",
      );
      message.error(errorMessageFromAPI);
      logWorkerReviewSubmitted(workerForRating, exclusion, dnrAction, {
        success: false,
        method: "submitFailed",
        id: workerForRating.rating?.id,
      });
    }
  };

  const logWorkerReviewSubmitted = (
    workerForRating: WorkerWithRatingAndLastShift,
    exclusion: ExclusionForList | undefined,
    dnrAction: DnrAction | undefined,
    options: Record<string, string | boolean | undefined>,
  ) => {
    if (!workerForRating.rating || !initialFormState) {
      return;
    }

    const initialWorkerForRating = initialFormState[currentSlide];
    captureWorkerReviewSubmittedEvent(
      convertToWorkerReviewState(
        workerForRating.rating,
        exclusion,
        workerForRating.rating.id
          ? WorkerReviewMode.EDIT
          : WorkerReviewMode.SUBMIT,
        dnrAction,
      ),
      initialWorkerForRating.rating
        ? convertToWorkerReviewState(
            initialWorkerForRating.rating,
            exclusion,
            initialWorkerForRating.rating.id
              ? WorkerReviewMode.EDIT
              : WorkerReviewMode.SUBMIT,
            undefined,
          )
        : {},
      {
        workerId: workerForRating.worker.userId,
        workplaceId,
        workplaceUserId,
        dnrAction,
        whoCalledMe: WhoCalledRatingPerformanceModal.WORKER_RATINGS_CAROUSEL,
        ...options,
      },
    );
  };

  const handleSkipClicked = () => {
    setCurrentSlide((prevState) => prevState + 1);
    form.resetFields();

    const skippedWorker = workerAwaitingReviews[currentSlide];
    if (skippedWorker) {
      logSkipWorkerRating(
        skippedWorker.workerId,
        workplaceId,
        workplaceUserId,
        sessionId,
      );
    }
  };

  const handleModalClosed = () => {
    if (numberOfReviewsSubmitted < numberOfWorkerAwaitingReviews) {
      showWorkerReviewsBanner(dispatch, workplaceId);
    }
    updateNumberOfAwaitingReviews(
      dispatch,
      numberOfWorkerAwaitingReviews - numberOfReviewsSubmitted,
    );
    invalidateCacheWorkerAwaitingReviews();
    invalidateCacheWorkersForRating();
    invalidateCacheWorkerExclusions();
    onClose();
  };

  const handleXIconClicked = () => {
    if (currentSlide < numberOfWorkerAwaitingReviews) {
      logEarlyClose(
        workplaceId,
        workplaceUserId,
        numberOfWorkerAwaitingReviews,
        numberOfReviewsSubmitted,
        numberOfWorkerAwaitingReviews - numberOfReviewsSubmitted,
        sessionId,
      );
    } else {
      logForLastStep({ closeSelected: true });
    }
    handleModalClosed();
  };

  const handleFormStateChanged = (rating: Partial<Rating>) => {
    setFormState((prevState) => ({
      ...prevState,
      [currentSlide]: {
        ...prevState[currentSlide],
        rating: { ...prevState[currentSlide].rating, ...rating },
      },
    }));
    form.setFieldsValue(rating);
  };

  useEffect(() => {
    setSessionId(uuidv4());
  }, []);

  return (
    <StyledModal
      visible={visible}
      onCancel={handleXIconClicked}
      data-testid="worker-reviews-modal"
      footer={null}
      maskClosable={false}
    >
      {!isLoading ? (
        currentSlide === numberOfWorkerAwaitingReviews ? (
          <SuccessScreen
            onLoadMore={handleLoadMore}
            onStartAgain={handleStartAgain}
            isReviewedAll={
              numberOfReviewsSubmitted === numberOfWorkerAwaitingReviews
            }
          />
        ) : (
          <Container>
            <Slider>
              {currentSlide + 1} / {numberOfWorkerAwaitingReviews}
            </Slider>
            {formState[currentSlide] !== undefined && (
              <RatingPerformanceForm
                key={formState[currentSlide].worker.userId}
                worker={formState[currentSlide].worker}
                lastShift={formState[currentSlide].lastShift}
                rating={formState[currentSlide].rating}
                timezone={timezone}
                exclusion={
                  exclusionsData[formState[currentSlide].worker.userId]
                }
                onRatingChanged={handleFormStateChanged}
                form={form}
              />
            )}
            <Controls>
              <ButtonGroup>
                <Button
                  variant="secondary"
                  onClick={handleSkipClicked}
                  data-testid="worker-review-skip"
                  disabled={isLoading}
                >
                  Skip
                </Button>
                <Button
                  variant="primary"
                  data-testid="worker-review-submit"
                  onClick={handlerWorkerSubmit}
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Save & next
                </Button>
              </ButtonGroup>
            </Controls>
          </Container>
        )
      ) : (
        <Skeleton active paragraph />
      )}
    </StyledModal>
  );
};
