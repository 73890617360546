import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { FiFilter } from "react-icons/fi";
import { VerticalSpacing } from "frontend-admin/src/designsystem/VerticalSpacing/VerticalSpacing";
import { useDebouncedValue } from "frontend-admin/src/hooks/useDebounceValue";
import { SessionType } from "frontend-admin/src/modules/interface";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useSelector } from "react-redux";
import {
  ArrayParam,
  QueryParamConfig,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import {
  FilterButton,
  FilterSection,
  PageTitle,
  SearchField,
  Workers,
  Wrapper,
} from "./WorkersDirectory.styles";
import { ListWorkers } from "./components/ListWorkers";
import { useHighRatingWorkers } from "./useHighRatingWorkers";
import {
  LoadingContainer,
  LoadingIcon,
} from "frontend-admin/src/containers/workers/ListWorkers/ListWorkers.styles";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "frontend-admin/src/constants/FEATURE_FLAGS";
import { logEvent } from "frontend-admin/src/utils/segment/logEvents";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";
import { useWorkerTypes } from "frontend-admin/src/containers/liveMapView/hooks/useWorkerTypes";
import { WorkerFilterModal } from "./components/WorkerFilterModal";
import { Badge } from "antd";
import { Colors } from "frontend-admin/src/utils/colors";
import { RestrictedSignedProfile } from "frontend-admin/src/components/RestrictedSignedProfile/RestrictedSignedProfile";

export const WORKER_DIRECTORY = {
  WORKER_SEARCHED: "worker searched",
  LOAD_MORE_WORKERS: "load more workers",
  FILTER_OPENED: "filter modal opened",
  FILTER_APPLIED: "filtered applied",
  FILTER_CLEARED: "filter cleared",
  FILTER_CLOSED: "filter closed",
} as const;

type WorkerDirectoryActionKeys = keyof typeof WORKER_DIRECTORY;
type WorkerDirectoryActionValues =
  typeof WORKER_DIRECTORY[WorkerDirectoryActionKeys];

const NonNullArrayParam = withDefault(
  ArrayParam,
  undefined,
) as QueryParamConfig<string[] | undefined>;

export const WorkersDirectoryPage = (): JSX.Element => {
  const [searchQuery, setSearchQuery] = useQueryParam("q", StringParam);
  const [workerTypeFilter, setWorkerTypeFilter] = useQueryParam(
    "workerType",
    NonNullArrayParam,
  );
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const {
    userId,
    profile: { onboardingSteps, userId: workplaceId, type },
    user,
    admin,
  } = useSelector((state: SessionType) => state.session);
  const { isLoading: workerTypesLoading, workerTypes } = useWorkerTypes(type);
  const ldFlags = useFlags();
  const workplaceOnboardingSteps: Array<"favorites" | "shifts"> = useMemo(
    () =>
      ldFlags[FeatureFlag.WORKPLACE_ONBOARDING_STEPS_AB_TESTING]?.orders || [
        "favorites",
        "shifts",
      ],
    [ldFlags],
  );
  const postShiftOptionSteps: Array<"block-shifts" | "per-diem"> = useMemo(
    () =>
      ldFlags[FeatureFlag.POST_SHIFT_OPTIONS_AB_TESTING]?.orders || [
        "block-shifts",
        "per-diem",
      ],
    [ldFlags],
  );
  const {
    isLoading: workerDirectoryLoading,
    data: highRatingWorkers,
    isError: highRatingWorkersError,
    fetchNextPage: fetchHighRatingWorkersNextPage,
    hasNextPage,
    invalidateCache,
  } = useHighRatingWorkers(
    userId,
    {
      searchTerm: debouncedSearchQuery ?? undefined,
      workerType: workerTypeFilter?.length ? workerTypeFilter : undefined,
    },
    admin,
    {
      workplaceOnboardingSteps,
      postShiftOptionSteps,
      workplaceUserId: user?._id,
      onboardingState: onboardingSteps,
    },
  );

  const logWorkerDirectoryItemEvents = useCallback(
    (
      action: WorkerDirectoryActionValues,
      searchTerm?: string | null,
      workerTypesToFilter?: string[] | null,
    ) => {
      if (admin) {
        return;
      }

      const commonAttributes = {
        workplaceOnboardingSteps,
        postShiftOptionSteps,
        workplaceId: userId,
        workplaceUserId: user?._id,
        onboardingState: onboardingSteps,
      };

      logEvent(HCF_USER_EVENTS.WORKER_DIRECTORY, {
        ...commonAttributes,
        action,
        searchTerm,
        workerTypesToFilter,
      });
    },
    [
      admin,
      postShiftOptionSteps,
      onboardingSteps,
      user?._id,
      userId,
      workplaceOnboardingSteps,
    ],
  );

  useEffect(() => {
    function logPageView() {
      if (!workplaceId || admin) {
        return;
      }
      logEvent(HCF_USER_EVENTS.WORKER_DIRECTORY_PAGE_VIEWED, {
        workplaceOnboardingSteps,
        postShiftOptionSteps,
        workplaceId,
        workplaceUserId: user?._id,
        onboardingState: onboardingSteps,
      });
    }
    logPageView();
  }, [
    admin,
    postShiftOptionSteps,
    onboardingSteps,
    workplaceId,
    user?._id,
    workplaceOnboardingSteps,
  ]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      // log search event only when debouncedSearchQuery is not empty or it changes
      logWorkerDirectoryItemEvents(
        WORKER_DIRECTORY.WORKER_SEARCHED,
        debouncedSearchQuery,
        workerTypeFilter,
      );
    }
    // logWorkerDirectoryItemEvents changes with filter values too so this was being called when the filter updates, hence had to remove the dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const handleSearchChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterToggle = (value: boolean) => {
    logWorkerDirectoryItemEvents(
      value ? WORKER_DIRECTORY.FILTER_OPENED : WORKER_DIRECTORY.FILTER_CLOSED,
      debouncedSearchQuery,
      workerTypeFilter,
    );
    setShowFilterModal(value);
  };

  const handleWorkerTypeFilter = (workerTypesToFilter: string[]) => {
    setWorkerTypeFilter([...workerTypesToFilter]);
    setShowFilterModal(false);
    logWorkerDirectoryItemEvents(
      workerTypesToFilter.length
        ? WORKER_DIRECTORY.FILTER_APPLIED
        : WORKER_DIRECTORY.FILTER_CLEARED,
      debouncedSearchQuery,
      workerTypesToFilter,
    );
    invalidateCache();
  };

  const [sentryRef] = useInfiniteScroll({
    loading: workerDirectoryLoading,
    hasNextPage: !!hasNextPage,
    disabled: highRatingWorkersError,
    onLoadMore: fetchHighRatingWorkersNextPage,
  });

  const renderLoadMore = () => {
    return (
      <>
        <VerticalSpacing size="lg" />
        <LoadingContainer ref={sentryRef}>
          <LoadingIcon indicator={<LoadingOutlined spin />} />
        </LoadingContainer>
      </>
    );
  };

  return (
    <Wrapper>
      <PageTitle>Browse Workers</PageTitle>
      <VerticalSpacing />
      <div>
        This the full list of workers in your area. Mark your preferred workers
        with a heart so they get early access to book your shifts. Offer blocks
        of shifts to as many workers as you'd like.
      </div>
      <VerticalSpacing size="lg" />
      <FilterSection>
        <RestrictedSignedProfile
          action="search workers"
          page="Worker Directory"
        >
          <SearchField
            data-testid="search-workers"
            placeholder="Search worker..."
            size="large"
            value={searchQuery ?? ""}
            onChange={handleSearchChanged}
            allowClear
            prefix={<SearchOutlined />}
          />
        </RestrictedSignedProfile>
        <RestrictedSignedProfile
          action="filter workers"
          page="Worker Directory"
        >
          <FilterButton
            variant="ghost"
            size="md"
            onClick={() => handleFilterToggle(true)}
            disabled={
              workerTypesLoading ||
              workerTypes?.length === 0 ||
              workerDirectoryLoading
            }
          >
            {workerTypeFilter?.length ? (
              <Badge
                count={workerTypeFilter.length}
                style={{ backgroundColor: Colors.grey }}
              />
            ) : (
              <FiFilter />
            )}
            Filter
          </FilterButton>
        </RestrictedSignedProfile>
      </FilterSection>
      <VerticalSpacing size="lg" />
      <Workers>
        <ListWorkers
          workers={highRatingWorkers}
          workplaceId={userId}
          workplaceUserId={user?._id}
          onboardingState={onboardingSteps}
          postShiftOptionSteps={postShiftOptionSteps}
          workplaceOnboardingSteps={workplaceOnboardingSteps}
          whoCalledMe="DirectoryWorkerPage"
        />
      </Workers>
      {highRatingWorkers.length === 0 && !workerDirectoryLoading ? (
        searchQuery ? (
          <>
            We couldn't find any workers matching "{searchQuery}" in your area.
          </>
        ) : (
          <>There are no highly rated workers in your area, at the moment.</>
        )
      ) : undefined}
      {(workerDirectoryLoading || hasNextPage) && renderLoadMore()}

      {showFilterModal && (
        <WorkerFilterModal
          workerTypes={workerTypes}
          visible={showFilterModal}
          initialWorkerTypes={workerTypeFilter ?? []}
          onCancel={() => handleFilterToggle(false)}
          onFilter={handleWorkerTypeFilter}
        />
      )}
    </Wrapper>
  );
};
