import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { getEmployees } from "../../modules/employees";
import { getLocations } from "../../modules/locations";
import { get, getAdminProfile, logout, pseudo } from "../../modules/session";
import { initFirebaseSession } from "../../modules/session/init";
import { FacilityStatusObj } from "../../utils/facility";
import { AppLayout } from "./layout";

const mapStateToProps = (state) => ({
  session: state.session,
  hasSession: state.session.userId !== "",
  isAgent: state.session.userId !== "" && state.session.type === "AGENT",
  isAdmin: state.session.userId !== "" && state.session.type === "ADMIN",
  admin: state.session.admin,
  isFacility: state.session.userId !== "" && state.session.type === "FACILITY",
  isSuspended:
    state.session.profile &&
    state.session.profile.status === FacilityStatusObj.SUSPENDED,
  profile: state.session.profile,
  tz: state.session.tz,
  loadingAdminProfile: state.session.loadingAdminProfile,
  isFirebaseSession: state.session.isFirebaseSession,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      pseudo,
      get,
      getAdminProfile,
      getLocations,
      getEmployees,
      initFirebaseSession,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppLayout),
);
