import React, { useEffect, Fragment } from "react";
import { FileFilled, ArrowLeftOutlined } from "@ant-design/icons";

import {
  SendBirdProvider,
  Channel,
  useSendbirdStateContext,
  RenderCustomMessageProps,
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { Modal, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import "./chatStyle.scss";
import {
  fetchAndSetUpcomingShifts,
  setChannels,
  updateChannel,
} from "frontend-admin/src/modules/chat";
import meta from "frontend-admin/src/utils/meta";
import { logEvent } from "frontend-admin/src/utils/segment/logEvents";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";
import { ReviewSentHomeMessage } from "./ReviewSentHomeMessage";
import { SentHomeResponseMessage } from "./SentHomeResponseMessage";
import { MessageCustomTypes } from "./chat.types";
import { logProfileNavigated } from "frontend-admin/src/containers/workers/logs";
import { WhoCalledRatingPerformanceModal } from "frontend-admin/src/components/WorkerRatingPerformanceModal/workerReview.types";
import { useQuery } from "@tanstack/react-query";
import { fetchExclusion } from "frontend-admin/src/api/workerReview";
import { FeatureFlag } from "frontend-admin/src/constants/FEATURE_FLAGS";
import { getLocation } from "frontend-admin/src/utils/routes";
import {
  SendbirdChat,
  Session,
  SessionType,
} from "frontend-admin/src/modules/interface";
import { Dispatch } from "redux";

const appId = meta().sendBirdAppId;

const CHAT_CHANNEL_QUERY_LIMIT = 100;

const getLastMessageDate = (channel) => {
  const useTimestamp = channel.lastMessage
    ? channel.lastMessage.createdAt
    : channel.createdAt;
  const lastMessage = moment(useTimestamp);

  if (moment().add(-1, "day").startOf("day").isAfter(lastMessage))
    return lastMessage.format("MM/DD/YYYY");
  if (moment().startOf("day").isAfter(lastMessage)) return "Yesterday";

  return lastMessage.format("hh:mm A");
};

const getLastMessageInfo = ({ lastMessage }, currentUserId) => {
  if (lastMessage) {
    const from = lastMessage.sender ? (
      <b>
        {lastMessage.sender.userId === currentUserId
          ? "You"
          : lastMessage.sender.nickname}
        :{" "}
      </b>
    ) : (
      <></>
    );
    if (lastMessage.messageType === "file")
      return (
        <>
          <FileFilled /> {from} {lastMessage.name}{" "}
        </>
      );

    return (
      <>
        {from} {lastMessage.message}
      </>
    );
  }

  return "No messages";
};

const getStatus = (isChatOpen30DaysAfterLastShift, channel) => {
  if (!channel) return "loading";
  if (!channel.shift) {
    // if the feature flag is disabled or there's no metadata
    if (!isChatOpen30DaysAfterLastShift || !channel.metadata?.lastBooked) {
      return "nothing";
    }

    const lastBookedDate = moment(channel.metadata.lastBooked);
    if (moment().diff(lastBookedDate, "days") > 30) {
      return "closed_after_30_days";
    } else {
      return "past";
    }
  }

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const tomorrowEndOfDay = moment().add(1, "day").endOf("day");
  const todayEndOfDay = moment().endOf("day");

  if (end.isBefore(moment())) return "past";
  if (start.isAfter(moment())) {
    if (start.isBefore(todayEndOfDay)) return "today";
    if (start.isBefore(tomorrowEndOfDay)) return "tomorrow";
    return "ahead";
  }

  return "in progress";
};

function getChannelClassName(channelStatus, actionBy) {
  if (actionBy) {
    return `disable-chat ${
      actionBy === "AGENT" ? "blocked-by-worker" : "blocked-by-workplace"
    }`;
  } else if (channelStatus === "nothing") {
    return "disable-chat no-upcoming-shifts";
  } else if (channelStatus === "closed_after_30_days") {
    return "disable-chat after-30-days";
  } else {
    return "";
  }
}

const getChannelShiftTime = (isChatOpen30DaysAfterLastShift, channel) => {
  if (!channel) return "Loading...";
  if (!channel.shift) return "No upcoming shifts";

  const status = getStatus(isChatOpen30DaysAfterLastShift, channel);
  if (["nothing", "closed_after_30_days", "past"].includes(status))
    return "No upcoming shifts";

  const start = moment(channel.shift.start);
  const end = moment(channel.shift.end);

  const until =
    "until " +
    end.format("h:mm A") +
    ", " +
    end.diff(start, "hours", true) +
    "hrs";
  const at = ` ${start.format("h:mm A")}`;

  if (status === "in progress") return `Shift in progress ${until}`;

  let date = "";
  if (status === "today") date = `today ${at}`;
  if (status === "tomorrow") date = `tomorrow, ${start.format("MMM DD")} ${at}`;
  if (status === "ahead") date = `${start.format("MMM DD, YYYY")}, ${at}`;

  return "Next shift: " + date;
};

const channelTitle = (channel) => {
  if (!channel) {
    return;
  }

  const workerName = channel.name.split("-")[1]?.trim();
  return [
    channel.metadata?.hcpName || channel.shift?.agent?.name || workerName,
    channel.metadata?.agentReq,
  ]
    .filter(Boolean)
    .join(", ");
};

const ChatHeader = ({
  workerId,
  workplaceId,
  workplaceUserId,
  currentChannel,
  backAction,
}) => {
  const history = useHistory();

  const handleWorkerNameClicked = (withNavigation) => {
    if (withNavigation) {
      history.push(
        getLocation("workplaceWorkerDetails", { pathParams: { workerId } }),
      );
    }
    logProfileNavigated({
      workerId,
      workplaceId,
      workplaceUserId,
      location: WhoCalledRatingPerformanceModal.CHAT_CHANNEL,
    });
  };

  return (
    <div className="custom-header">
      <div className="custom-header-title">
        {backAction && (
          <ArrowLeftOutlined
            className="close-channel"
            onClick={() => history.push(getLocation("facilityChat"))}
          />
        )}
        <Link
          to={getLocation("workplaceWorkerDetails", {
            pathParams: { workerId },
          })}
          onClick={handleWorkerNameClicked}
          className="custom-header-worker"
        >
          {channelTitle(currentChannel)}
        </Link>
      </div>
      <Button
        className="view-btn"
        onClick={() => handleWorkerNameClicked(true)}
      >
        View profile
      </Button>
    </div>
  );
};

export const ChatPage = () => {
  const { userId, user, admin } = useSelector<SessionType, Session>(
    (state) => state.session,
  );

  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  const history = useHistory();
  const { agentId, facilityId } = useParams<{
    agentId: string;
    facilityId: string;
  }>();
  const { channels } = useSelector<SessionType, SendbirdChat>(
    (state) => state.chat,
  );
  const currentChannelUrl = facilityId ? facilityId + "_" + agentId : "";
  const currentChannel = channels.find(
    (channel) => channel.url === currentChannelUrl,
  );
  const dispatch = useDispatch();
  const ldFlags = useFlags();
  const isChatOpen30DaysAfterLastShift =
    ldFlags[FeatureFlag.CHANNEL_OPEN_30DAYS_AFTER_LAST_BOOKED_SHIFT];
  const channelStatus = getStatus(
    isChatOpen30DaysAfterLastShift,
    currentChannel,
  );

  useEffect(() => {
    async function fetchAndDispatchChannels() {
      if (sdk) {
        await fetchChannels(sdk, dispatch);
      }
    }
    fetchAndDispatchChannels();
  }, [sdk]);

  useEffect(() => {
    if (!admin && facilityId && agentId && user?._id) {
      logEvent(HCF_USER_EVENTS.CHAT_OPENED, {
        location: "chatChannel",
        workerId: agentId,
        workplaceId: facilityId,
        workplaceUserId: user._id,
      });
    }
  }, [facilityId, agentId, user?._id]);

  const { isLoading: isLoadingExclusion, data: exclusions } = useQuery(
    ["chat", "exclusion", agentId, facilityId],
    () =>
      fetchExclusion({
        facilityId,
        agentId,
      }),
    {
      enabled: !!(userId && facilityId && agentId),
    },
  );

  const channelClick = (newChannelUrl) => {
    const [facilityId, agentId] = newChannelUrl.split("_");
    history.push(
      getLocation("facilityChatWithAgent", {
        pathParams: { facilityId, agentId },
      }),
    );
  };

  return (
    <div
      className={`chat-container chat-style ${getChannelClassName(
        channelStatus,
        exclusions ? exclusions[0]?.actionBy : undefined,
      )}`}
    >
      <div className={"chat " + (currentChannelUrl ? "selected-chat" : "")}>
        <div className="channels-container">
          <div className="channels-header">Channels</div>
          {channels.map((channel, index, array) => {
            const classes = ["channel"];

            if (channel.url === currentChannelUrl) classes.push("selected");

            if (index > 0) classes.push("border"); // Not First
            if (index === array.length - 1) classes.push("shadow"); // Last

            return (
              <div
                tabIndex={0}
                role="row"
                key={channel.url}
                className={classes.join(" ")}
                onClick={() => channelClick(channel.url)}
                onKeyUp={() => channelClick(channel.url)}
              >
                <div className="channel-name">
                  <b>{channelTitle(channel)}</b>
                </div>
                <div className="channel-last">
                  {getLastMessageDate(channel)}
                </div>
                <div className="channel-message">
                  {getLastMessageInfo(channel, sdk?.currentUser?.userId)}
                </div>
                {channel.unreadMessageCount > 0 ? (
                  <div className="channel-unread">
                    {channel.unreadMessageCount}
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="channel-upcoming-shift">
                  {getChannelShiftTime(isChatOpen30DaysAfterLastShift, channel)}
                </div>
              </div>
            );
          })}
        </div>

        <Channel
          renderCustomMessage={(message) => {
            if (message.customType === MessageCustomTypes.FCM_REQUEST) {
              return () => (
                <ReviewSentHomeMessage
                  message={message}
                  facilityId={userId}
                  facilityUserId={user?._id}
                />
              );
            }

            if (
              message.customType === MessageCustomTypes.FCM_APPROVAL ||
              message.customType === MessageCustomTypes.FCM_REJECT
            ) {
              return () => <SentHomeResponseMessage message={message} />;
            }

            if (
              message.customType === MessageCustomTypes.SHIFT_REVIEW ||
              message.customType === MessageCustomTypes.INPUT_ETA ||
              message.customType === MessageCustomTypes.INPUT_ETA_SUBMITTED
            ) {
              return () => <span />;
            }

            return undefined as unknown as RenderCustomMessageProps;
          }}
          onBeforeSendFileMessage={
            sdk && user
              ? (file, quoteMesage) =>
                  handleBeforeSendFileMessage(sdk, user, file, quoteMesage)
              : undefined
          }
          onBeforeSendUserMessage={
            sdk && user
              ? (text, quoteMesage) =>
                  handleBeforeSendUserMessage(sdk, user, text, quoteMesage)
              : undefined
          }
          renderMessageInput={
            isLoadingExclusion || !sdk ? () => null : undefined
          }
          channelUrl={currentChannelUrl}
          renderChatHeader={(props) => (
            <ChatHeader
              workerId={agentId}
              workplaceId={facilityId}
              workplaceUserId={user?._id}
              currentChannel={currentChannel}
              backAction={true}
            />
          )}
        />
      </div>
    </div>
  );
};

export const ChatModal = ({ channelUrl, closeModal }) => {
  const { channels } = useSelector<SessionType, SendbirdChat>(
    (state) => state.chat,
  );
  const { userId, user } = useSelector<SessionType, Session>(
    (state) => state.session,
  );
  const currentChannel = channels.find((channel) => channel.url === channelUrl);
  const ldFlags = useFlags();
  const isChatOpen30DaysAfterLastShift =
    ldFlags[FeatureFlag.CHANNEL_OPEN_30DAYS_AFTER_LAST_BOOKED_SHIFT];
  const channelStatus = getStatus(
    isChatOpen30DaysAfterLastShift,
    currentChannel,
  );
  const [workplaceId, workerId] = (channelUrl ?? "").split("_"); // channel's url is the concatenation of workplaceId_workerId
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  const { isLoading: isLoadingExclusion, data: exclusions } = useQuery(
    ["chat", "exclusion", workerId, workplaceId],
    () =>
      fetchExclusion({
        facilityId: workplaceId,
        agentId: workerId,
      }),
    {
      enabled: !!(userId && workerId && workplaceId),
    },
  );

  return (
    <Fragment>
      <Modal
        className="chat-style"
        footer={<Fragment />}
        visible={channelUrl}
        bodyStyle={{ height: 688, maxHeight: "78vh", paddingTop: 50 }}
        onCancel={() => closeModal()}
      >
        <div
          className={`chat-container chat-style ${getChannelClassName(
            channelStatus,
            exclusions ? exclusions[0]?.actionBy : undefined,
          )}`}
        >
          <Channel
            renderCustomMessage={(message) => {
              if (
                message.customType === MessageCustomTypes.SHIFT_REVIEW ||
                message.customType === MessageCustomTypes.INPUT_ETA ||
                message.customType === MessageCustomTypes.INPUT_ETA_SUBMITTED
              ) {
                return () => <span />;
              }
              return undefined as unknown as RenderCustomMessageProps;
            }}
            renderMessageInput={
              isLoadingExclusion || !sdk ? () => null : undefined
            }
            onBeforeSendFileMessage={
              sdk && user
                ? (file, quoteMesage) =>
                    handleBeforeSendFileMessage(sdk, user, file, quoteMesage)
                : undefined
            }
            onBeforeSendUserMessage={
              sdk && user
                ? (text, quoteMesage) =>
                    handleBeforeSendUserMessage(sdk, user, text, quoteMesage)
                : undefined
            }
            channelUrl={channelUrl}
            renderChatHeader={(props) => (
              <ChatHeader
                workerId={workerId}
                workplaceId={userId}
                workplaceUserId={user?._id}
                currentChannel={currentChannel}
                backAction={false}
              />
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export const ChatProvider = ({ children }) => {
  const userId = useSelector<SessionType, string | undefined>(
    (store) => store.session.userId,
  );
  const session = useSelector<SessionType, Session>((store) => store.session);
  const sendBirdAccessToken = useSelector<SessionType, string | undefined>(
    (store) => store.session.sendBirdAccessToken,
  );
  const ldFlags = useFlags();
  let sendbirdAppId = appId;

  if (
    !ldFlags["chat"] ||
    !session.profile ||
    session.type === "ADMIN" ||
    session.admin
  ) {
    sendbirdAppId = "";
  }

  return (
    <SendBirdProvider
      appId={sendbirdAppId ?? ""}
      userId={userId ?? ""}
      nickname={session.profile?.name}
      accessToken={sendBirdAccessToken}
    >
      <ChatProviderWithStore>{children}</ChatProviderWithStore>
    </SendBirdProvider>
  );
};

const ChatProviderWithStore = ({ children }) => {
  const dispatch = useDispatch();
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;
  const ldFlags = useFlags();

  useEffect(() => {
    if (!sdk || !sdk.GroupChannel) return;

    const uuid = uuidv4();

    const channelHandler = new sdk.ChannelHandler();

    channelHandler.onChannelChanged = (channel) => {
      updateChannel(dispatch, channel);
    };

    channelHandler.onUserReceivedInvitation = (channel) => {
      updateChannel(dispatch, channel);
      fetchAndSetUpcomingShifts(dispatch);
    };

    channelHandler.onMessageReceived = (channel, message) => {
      if (message.isAdminMessage()) {
        fetchAndSetUpcomingShifts(dispatch);
      }
    };

    sdk.addChannelHandler(uuid, channelHandler);

    return () => sdk?.removeChannelHandler?.(uuid);
  }, [sdk]);

  useEffect(() => {
    async function fetchAndDispatchChannels() {
      if (sdk) {
        await fetchChannels(sdk, dispatch);
      }
    }
    fetchAndDispatchChannels();
  }, [sdk]);

  useEffect(() => {
    // Exit early and avoid adding listeners if the feature flag is turned off
    if (!ldFlags[FeatureFlag.SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION]) {
      return () => null;
    }

    const handleTabChange = async () => {
      if (document.visibilityState === "visible") {
        sdk?.setForegroundState?.();
        await fetchChannels(sdk, dispatch);
      } else {
        sdk?.setBackgroundState?.();
      }
    };

    const handleWindowChange = async () => {
      if (!document.hasFocus() && sdk?.getConnectionState?.() === "OPEN") {
        sdk?.setBackgroundState?.();
      }

      if (document.hasFocus() && sdk?.getConnectionState?.() === "CLOSED") {
        sdk?.setForegroundState?.();
        await fetchChannels(sdk, dispatch);
      }
    };

    // This is fired when user switches tabs
    // Internally, we check if the tab is focused, then reconnect, otherwise we disconnect
    document.addEventListener("visibilitychange", handleTabChange);

    // Moving away from current window and coming back to it is tracked via blur and focus events
    // Internally, we check if the tab is focused as the window gets focus back and reconnect, otherwise we disconnect
    window.addEventListener("blur", handleWindowChange);
    window.addEventListener("focus", handleWindowChange);

    return () => {
      document.removeEventListener("visibilitychange", handleTabChange);
      window.removeEventListener("blur", handleWindowChange);
      window.removeEventListener("focus", handleWindowChange);
    };
  }, [sdk]);

  return children;
};

export const fetchChannels = async (
  sdk: SendBird.SendBirdInstance,
  dispatch: Dispatch,
) => {
  if (!sdk || !sdk.GroupChannel) {
    return setChannels(dispatch, []);
  }

  const query = sdk.GroupChannel.createMyGroupChannelListQuery();
  query.includeEmpty = true;
  query.memberStateFilter = "joined_only";
  query.order = "latest_last_message";
  query.limit = CHAT_CHANNEL_QUERY_LIMIT;

  const list = await query.next();
  setChannels(dispatch, list);
};

function handleBeforeSendUserMessage(
  sdk: SendBird.SendBirdInstance,
  user: {
    access: string[];
    _id: string;
    email: string;
    name: string;
    permissions?: string[] | undefined;
  },
  text: string,
  quoteMessage: SendBird.UserMessage | SendBird.FileMessage | undefined,
) {
  const message = typeof text === "string" ? text.trim() : text;
  const params = new sdk.UserMessageParams();
  params.message = message;
  params.data = JSON.stringify({
    workplaceUserId: user._id,
    workplaceUserName: user.name,
  });

  if (quoteMessage) {
    params.isReplyToChannel = true;
    params.parentMessageId = quoteMessage.messageId;
  }

  return params;
}

function handleBeforeSendFileMessage(
  sdk: SendBird.SendBirdInstance,
  user: {
    access: string[];
    _id: string;
    email: string;
    permissions?: string[] | undefined;
    name: string;
  },
  file: File,
  quoteMessage: SendBird.UserMessage | SendBird.FileMessage | undefined,
) {
  const params = new sdk.FileMessageParams();
  params.file = file;
  params.data = JSON.stringify({
    workplaceUserId: user._id,
    workplaceUserName: user.name,
  });

  if (quoteMessage) {
    params.isReplyToChannel = true;
    params.parentMessageId = quoteMessage.messageId;
  }

  return params;
}
