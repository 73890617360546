import { forwardRef, HTMLAttributes } from "react";
import styled from "styled-components";

interface VerticalSpacingProps extends HTMLAttributes<HTMLDivElement> {
  size?: Size;
}

export const VerticalSpacing = forwardRef<HTMLDivElement, VerticalSpacingProps>(
  ({ size = "sm", ...props }, ref) => {
    return <StyledDiv size={size} {...props} ref={ref} />;
  },
);

type Size = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

const StyledDiv = styled.div<{ size: Size }>`
  block-size: ${({ size }) => getBlockSize(size)}px;
`;

const getBlockSize = (size: Size): number => {
  switch (size) {
    case "xs":
      return 2;
    case "sm":
      return 4;
    case "md":
      return 8;
    case "lg":
      return 16;
    case "xl":
      return 24;
    case "xxl":
      return 40;
  }
};
