import { FeatureFlag } from "frontend-admin/src/constants/FEATURE_FLAGS";
import { INotification } from "frontend-admin/src/modules/myAccount/types";

export const TOGGLE_BUTTON = {
  edit: "Edit information",
  save: "Save changes",
};

export const HEADINGS = {
  personalInfo: "Personal Information",
  roles: "Roles",
  notification: "Notification Preferences",
};

export const NotificationDetails: Record<string, INotification> = {
  pushNotification: {
    description: "Send me web-based push notifications",
    email: false,
    sms: false,
    push: true,
    web_push: "web_push",
  },
  assignment: {
    description: "Notify me when a worker books a shift",
    email: "assignmentEmail",
    sms: "assignmentSMS",
    push: true,
    pushFlags: [
      FeatureFlag.NOTIFICATION_CENTER_ACTIVE,
      FeatureFlag.NOTIFICATION_CENTER_COMMS_ASSIGNED,
    ],
  },
  shiftUnassignment: {
    description: "Notify me when a worker cancels a shift",
    email: "shiftUnassignmentEmail",
    sms: "shiftUnassignmentSMS",
    push: true,
    pushFlags: [
      FeatureFlag.NOTIFICATION_CENTER_ACTIVE,
      FeatureFlag.NOTIFICATION_CENTER_COMMS_UNASSIGNED,
    ],
  },
  workerEta: {
    description: "Notify me if a worker is going to be late for their shift",
    email: false,
    sms: "workerEtaSMS",
    push: false,
  },
  chatMessages: {
    description: "Notify me when a worker sends a chat message",
    email: false,
    sms: "chatMessages",
    push: false,
  },
  weeklyVerifiedShiftsSummary: {
    description: "Send me upcoming invoice charges (weekly)",
    email: "weeklyVerifiedShiftsSummary",
    sms: true,
    push: false,
  },
  invoice: {
    description: "Send me generated invoices (weekly)",
    email: "invoiceEmail",
    sms: false,
    push: false,
  },
  rateNegotiation: {
    description: "Send me rate negotiation updates",
    email: "rateNegotiationEmail",
    emailFlag: FeatureFlag.RATE_NEGOTIATION_COMMS_EMAIL,
    smsFlag: FeatureFlag.RATE_NEGOTIATION_COMMS_SMS,
    sms: "rateNegotiationSMS",
    push: false,
    flag: FeatureFlag.RATE_NEGOTIATION_ACTIVATION,
  },
  CDPH530: {
    description: "Send me digital CDPH-530 copies (daily)",
    email: "dailyCDPH530Recap",
    sms: false,
    push: false,
    flag: FeatureFlag.CALIFORNIA_TIMESHEET,
  },
};

export const BATCH_DESCRIPTIONS =
  "We will send you a daily summary for this notification, at the time of your choice.";

export const BATCH_HOURS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const BATCH_MERIDIEM = ["am", "pm"];

export const FORM_DETAILS = {
  firstName: {
    label: "First Name",
    name: "firstName",
    errorMessage: "Please enter a first name.",
  },
  lastName: {
    label: "Last Name",
    name: "lastName",
    errorMessage: "Please enter a last name.",
  },
  email: {
    label: "Email",
    name: "email",
    errorMessage:
      "Please enter an email. After editing an email, you must log out and log back in with the new email address.",
    help: "After editing an email, you must log out and log back in with the new email address.",
  },
  phone: {
    label: "Mobile",
    name: "phone",
    errorMessage: "Please enter a mobile number.",
  },
  workplacePhone: {
    label: "Landline",
    name: "workplacePhone",
    errorMessage: "Please enter a valid landline number.",
  },
  designation: {
    label: "Job Title",
    name: "designation",
    errorMessage: "Please enter a designation.",
  },
};
