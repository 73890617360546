import React, { useMemo } from "react";
import { Card } from "antd";
import styled from "styled-components";

const CustomCard = styled(Card)`
  .ant-card-body {
    padding: 5px;
  }
  img {
    width: 100%;
  }
`;

/**
 * Shows preview of the file
 *
 * File url should be direct, one of:
 *
 * 1. s3Url
 * 2. url
 * 3. type.url[0]
 */
const FilePreviewItem = ({ file, previewFile }) => {
  const timesheetUrl = useMemo(
    () =>
      (file &&
        (file.s3Url ||
          file.url ||
          (file.type && file.type.url && file.type.url[0]))) ||
      "",
    [file],
  );

  return (
    <CustomCard data={file} onClick={previewFile(timesheetUrl)}>
      <img
        title="Click on the image to rotate, zoom it"
        className="preview-img"
        src={timesheetUrl}
        alt="timecard"
      />
    </CustomCard>
  );
};

export { FilePreviewItem };
