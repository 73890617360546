import { gql } from "@apollo/client";

const CREATE_HCP_DOCUMENT = gql`
  mutation CREATE_HCP_DOCUMENT(
    $fileUrl: String!
    $hcpId: ObjectId
    $name: String!
    $uploadedFor: ObjectId!
    $covidTest: DateTime
    $fileStorageFileKey: String
    $fileStorageRootFolder: String
  ) {
    createHcpDocument(
      fileUrl: $fileUrl
      hcpId: $hcpId
      name: $name
      uploadedFor: $uploadedFor
      covidTest: $covidTest
      fileStorageFileKey: $fileStorageFileKey
      fileStorageRootFolder: $fileStorageRootFolder
    ) {
      _id
      name
      covidTest
      fileUrl
    }
  }
`;

const GET_REQUIREMENTS_STATUS = gql`
  query GET_REQUIREMENTS_STATUS($hcpId: ObjectId!) {
    hcpRequirementStatus(hcpId: $hcpId) {
      miscellaneous {
        _id
        name
        instructions
        description
      }
      requirements {
        _id
        reqId
        name
        level
        hcfType
        requiredBy
        instructions
        description
        visibleToHCP
      }
      completed
      pending
      expired
      missing
      optionalRequirements {
        _id
        reqId
        name
        level
        hcfType
        requiredBy
        instructions
        description
        visibleToHCP
      }
      validOptionalRequirements
      expiredOptionalRequirements
      missingOptionalRequirements
      rejected
      mostRecentCovidDoc {
        covidTest
      }
    }
  }
`;

const GET_HCP_REQUIREMENTS = gql`
  query GET_HCP_REQUIREMENTS($hcpId: ObjectId!, $filter: RequirementsFilter) {
    hcpRequirementList(hcpId: $hcpId, filter: $filter) {
      expiry
      baseRequirement {
        expiryType
      }
    }
  }
`;

const UPDATE_HCP_DOCUMENT = gql`
  mutation UPDATE_HCP_DOCUMENT(
    $_id: ObjectId!
    $expiry: DateTime
    $covidTest: DateTime
    $status: HcpDocumentStatus
  ) {
    updateHcpDocument(
      _id: $_id
      expiry: $expiry
      covidTest: $covidTest
      status: $status
    ) {
      _id
      name
      status
      fileUrl
      state
    }
  }
`;

const GET_DOCUMENT_LIST = gql`
  query GET_DOCUMENT_URL($hcpId: ObjectId!) {
    documentList(hcpId: $hcpId) {
      name
      _id
      expiry
      status
    }
  }
`;

export {
  CREATE_HCP_DOCUMENT,
  GET_REQUIREMENTS_STATUS,
  GET_HCP_REQUIREMENTS,
  UPDATE_HCP_DOCUMENT,
  GET_DOCUMENT_LIST,
};
