import styled from "styled-components";

export const Link = styled.a<{
  underline?: boolean;
  strong?: boolean;
  to?: string;
  color?: "primary" | "secondary";
}>`
  color: ${({ color }) => (color === "primary" ? "#032e68" : "#424242")};
  ${({ strong }) => (strong ? "font-weight: bold;" : undefined)}
  border-bottom: 1px solid transparent;
  ${({ underline, color }) =>
    underline === false
      ? undefined
      : `border-bottom: 1px solid ${
          color === "primary" ? "#032e68" : "#424242"
        };`}

  &:hover {
    color: ${({ color }) => (color === "primary" ? "#032e68" : "#424242")};
    opacity: 0.7;
  }
`;
