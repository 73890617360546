import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchWorkersForRating,
  WorkerWithLastShift,
} from "frontend-admin/src/api/workers";
import { LONG_STALE_TIME } from "frontend-admin/src/constants/query";

export function useWorkersForRating(
  workplaceId: string,
  workerIds: string[],
  { onSuccess }: { onSuccess: (data: WorkerWithLastShift[]) => void },
) {
  const queryClient = useQueryClient();
  const queryKey = [
    "worker-reviews-rating",
    workplaceId.toString(),
    workerIds.join(","),
  ];
  const { isLoading: workersForRatingLoading, data: workersForRatingData } =
    useQuery({
      queryKey,
      queryFn: () =>
        fetchWorkersForRating({
          workerIds,
          workplaceId,
          includeRating: true,
        }),
      staleTime: LONG_STALE_TIME,
      enabled: workerIds.length > 0,
      onSuccess,
    });

  const invalidateCache = () => {
    queryClient.removeQueries(queryKey);
  };

  return {
    data: workersForRatingData ?? [],
    isLoading: workersForRatingLoading,
    invalidateCache,
  };
}
