import { PermissionsGate } from "frontend-admin/src/designsystem/PermissionGate/PermissionGate";
import { useRestrictedSignedProfile } from "frontend-admin/src/hooks/useRestrictedSignedProfile";
import { ChangeEventHandler, MouseEventHandler, ReactElement } from "react";
import { OpenWorkplaceContractModal } from "frontend-admin/src/modules/workplaceContract";

interface RestrictedSignedProfileProps {
  children: ReactElement;
  action: OpenWorkplaceContractModal["data"]["triggeredBy"];
  page: OpenWorkplaceContractModal["data"]["triggeredFrom"];
}

/**
 This component is designed to be utilized for restricting access by
 clicking on an element that has an onClick(event) property.
 */
export function RestrictedSignedProfile({
  children,
  action,
  page,
}: RestrictedSignedProfileProps) {
  const { isSignedProfile, openContractModal } = useRestrictedSignedProfile();

  const handleClicked: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation?.();
    openContractModal({ triggeredBy: action, triggeredFrom: page });
  };

  const handleChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.stopPropagation?.();
    openContractModal({ triggeredBy: action, triggeredFrom: page });
  };

  return (
    <PermissionsGate<{
      onClick?: MouseEventHandler<HTMLButtonElement>;
      onChange?: ChangeEventHandler<HTMLInputElement>;
    }>
      hasPermission={isSignedProfile}
      noPermissionProps={{ onClick: handleClicked, onChange: handleChanged }}
      noPermissionMode="oneof"
    >
      {children}
    </PermissionsGate>
  );
}
