import { Dispatch } from "redux";
import { fetchWorkerAwaitingReviews } from "frontend-admin/src/api/workers";

export const LAST_WORKER_REVIEWED_AT = "LAST_WORKER_REVIEWS_AT";
export const WORKER_REVIEWS_BANNER_VISIBLE = "WORKER_REVIEWS_BANNER_VISIBLE";

export type CarouselTriggeredBy = "banner-click" | "auto-popup";

export type WorkerReviewsState = {
  workerReviewsModalVisible: boolean;
  workerReviewsBannerVisible: boolean;
  lastReviewedAt?: Date;
  triggeredBy: CarouselTriggeredBy;
  numberOfAwaitingWorkerReviews: number;
};

const initialState: WorkerReviewsState = {
  workerReviewsBannerVisible: false,
  workerReviewsModalVisible: false,
  numberOfAwaitingWorkerReviews: 0,
  triggeredBy: "auto-popup",
};

type SetupInitialState = {
  type: "SETUP_INTIAL_WORKER_REVIEWS_STATE";
  data: WorkerReviewsState;
};

type ShowWorkerRatingCarouselModal = {
  type: "SHOW_WORKER_REVIEWS_MODAL";
};

type HideWorkerRatingCarouselModal = {
  type: "HIDE_WORKER_REVIEWS_MODAL";
};

type ShowWorkerReviewsBanner = {
  type: "SHOW_WORKER_REVIEWS_BANNER";
};

type HideWorkerReviewsBanner = {
  type: "HIDE_WORKER_REVIEWS_BANNER";
};

type UpdateNumberOfAwaitingReviews = {
  type: "UPDATE_NUMBER_OF_AWAITING_REVIEWS";
  data: number;
};

type WorkerReviewsAction =
  | ShowWorkerRatingCarouselModal
  | HideWorkerRatingCarouselModal
  | ShowWorkerReviewsBanner
  | HideWorkerReviewsBanner
  | SetupInitialState
  | UpdateNumberOfAwaitingReviews;

export function hasNoReviewFromLastNDays(
  atDate: Date,
  lastReviewedAt: Date,
  nDays: number,
): boolean {
  const fromDate = new Date(atDate);
  fromDate.setDate(fromDate.getDate() - nDays);

  return lastReviewedAt.getTime() < fromDate.getTime();
}

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: WorkerReviewsState = initialState,
  action: WorkerReviewsAction,
): WorkerReviewsState => {
  switch (action.type) {
    case "SETUP_INTIAL_WORKER_REVIEWS_STATE":
      return {
        ...state,
        ...action.data,
      };

    case "SHOW_WORKER_REVIEWS_MODAL":
      return {
        ...state,
        workerReviewsModalVisible: true,
        triggeredBy: "banner-click",
      };

    case "HIDE_WORKER_REVIEWS_MODAL":
      return {
        ...state,
        workerReviewsModalVisible: false,
      };

    case "HIDE_WORKER_REVIEWS_BANNER":
      return {
        ...state,
        workerReviewsBannerVisible: false,
      };

    case "SHOW_WORKER_REVIEWS_BANNER":
      return {
        ...state,
        workerReviewsBannerVisible: true,
      };

    case "UPDATE_NUMBER_OF_AWAITING_REVIEWS":
      return {
        ...state,
        numberOfAwaitingWorkerReviews: action.data,
      };

    default:
      return state;
  }
};

export const hideWorkerRatingCarouselModal = (
  dispatch: Dispatch<WorkerReviewsAction>,
  userId: string,
) => {
  localStorage.setItem(
    `${LAST_WORKER_REVIEWED_AT}_${userId}`,
    new Date().toISOString(),
  );
  dispatch({ type: "HIDE_WORKER_REVIEWS_MODAL" });
};

export const hideWorkerReviewsBanner = (
  dispatch: Dispatch<WorkerReviewsAction>,
  userId: string,
) => {
  localStorage.removeItem(`${WORKER_REVIEWS_BANNER_VISIBLE}_${userId}`);
  dispatch({ type: "HIDE_WORKER_REVIEWS_BANNER" });
};

export const showWorkerReviewsBanner = (
  dispatch: Dispatch<WorkerReviewsAction>,
  userId: string,
) => {
  localStorage.setItem(`${WORKER_REVIEWS_BANNER_VISIBLE}_${userId}`, "true");
  dispatch({ type: "SHOW_WORKER_REVIEWS_BANNER" });
};

export const showWorkerRatingCarouselModal = (
  dispatch: Dispatch<WorkerReviewsAction>,
) => {
  dispatch({ type: "SHOW_WORKER_REVIEWS_MODAL" });
};

export const updateNumberOfAwaitingReviews = (
  dispatch: Dispatch<WorkerReviewsAction>,
  numberOfAwaitingWorkerReviews: number,
) => {
  dispatch({
    type: "UPDATE_NUMBER_OF_AWAITING_REVIEWS",
    data: numberOfAwaitingWorkerReviews,
  });
};

function getLastReviewedAt(userId: string): string | undefined {
  const lastReviewedAt = localStorage.getItem(
    `${LAST_WORKER_REVIEWED_AT}_${userId}`,
  );
  if (lastReviewedAt) {
    const parts = lastReviewedAt.split("_");
    return parts[parts.length - 1];
  }
  return undefined;
}

export const checkAndShowWorkerRatingCarouselModal = async (
  dispatch: Dispatch<WorkerReviewsAction>,
  workplaceId: string,
  showWorkerReviewsCarousel: boolean,
  frequencyInDays: number,
) => {
  let workerReviewsModalVisible = false;
  let numberOfAwaitingWorkerReviews = 0;
  let workerReviewsBannerVisible = false;
  const lastReviewedAt = getLastReviewedAt(workplaceId);

  if (localStorage.getItem(`${WORKER_REVIEWS_BANNER_VISIBLE}_${workplaceId}`)) {
    workerReviewsBannerVisible = true;
  }

  const isShowWorkerReviews =
    !lastReviewedAt ||
    hasNoReviewFromLastNDays(
      new Date(),
      new Date(lastReviewedAt),
      frequencyInDays,
    );

  if (
    isShowWorkerReviews ||
    showWorkerReviewsCarousel ||
    workerReviewsBannerVisible
  ) {
    const awaitingReviews = await fetchWorkerAwaitingReviews(workplaceId);
    if (
      (isShowWorkerReviews || showWorkerReviewsCarousel) &&
      awaitingReviews?.workers &&
      awaitingReviews.workers.length > 0
    ) {
      numberOfAwaitingWorkerReviews = awaitingReviews.workers.length;
      workerReviewsModalVisible = true;
    }
    if (workerReviewsBannerVisible && awaitingReviews?.workers) {
      numberOfAwaitingWorkerReviews = awaitingReviews.workers.length;
    }
  }

  dispatch({
    type: "SETUP_INTIAL_WORKER_REVIEWS_STATE",
    data: {
      lastReviewedAt: lastReviewedAt ? new Date(lastReviewedAt) : undefined,
      workerReviewsBannerVisible,
      workerReviewsModalVisible,
      numberOfAwaitingWorkerReviews,
      triggeredBy: "auto-popup",
    },
  });
};
