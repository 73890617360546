const DEFAULT_ZOOM_LEVEL = 15;

enum ACTION {
  SHOW = "SHOW",
  HIDE = "HIDE",
}

const TIME_FORMAT = "hh:mm A";
const ALL_SHIFT_TYPES = "All Shifts";
const siteOrigin = window.location.origin;
const FACILITY_ICON = `${siteOrigin}/facility_icon.svg`;
const LOCATION_PIN_ICON = `${siteOrigin}/person_pin.svg`;
const LOCATION_PIN_IS_LATE_ICON = `${siteOrigin}/person_pin_late.svg`;

export {
  DEFAULT_ZOOM_LEVEL,
  ACTION,
  TIME_FORMAT,
  ALL_SHIFT_TYPES,
  FACILITY_ICON,
  LOCATION_PIN_ICON,
  LOCATION_PIN_IS_LATE_ICON,
};
