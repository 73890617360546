import { Route, useLocation } from "react-router-dom";
import { routes } from "./utils/routes";
import { Suspense, useEffect } from "react";
import { FallbackLoader } from "./components/lazyLoad";
import {
  initDatadogRUM,
  startDatadogRUMReplayRecording,
} from "./utils/datadogRUM";

import meta from "frontend-admin/src/utils/meta";
import { invokeSegment } from "./components/segmentSnippet";
import { loadLazily } from "./utils/loadLazily";
const SegmentDevKey = meta().segment.key;

const WorkplaceSignUp = loadLazily(() =>
  import("./containers/workplaceSignUp/WorkplaceSignUp").then((module) => ({
    default: module.WorkplaceSignUp,
  })),
);

interface GuestProps {
  path: string[];
}

export function Guest(_props: GuestProps) {
  const location = useLocation();

  useEffect(() => {
    initDatadogRUM();
    startDatadogRUMReplayRecording();
  }, []);

  useEffect(() => {
    if (!window.analytics) {
      invokeSegment(SegmentDevKey);
    }
    window.analytics?.page();
  }, [location]);

  return (
    <Suspense fallback={<FallbackLoader />}>
      <Route
        exact
        path={[routes.workplaceGetAccess, routes.workplaceReferrals]}
        component={WorkplaceSignUp}
      />
    </Suspense>
  );
}
