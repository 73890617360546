import WorkerType from "projects/frontend-admin/src/workers/interfaces/WorkerType";
import WorkPlaceType from "projects/frontend-admin/src/workers/interfaces/WorkPlaceType";

export enum WorkerAndWorkPlaceTypeActions {
  UPDATE_WORKER_TYPES = "UPDATE_WORKER_TYPES",
  UPDATE_WORK_PLACE_TYPES = "UPDATE_WORK_PLACE_TYPES",
  STARTED_FETCHING_TYPES = "STARTED_FETCHING_TYPES",
  FINISHED_FETCHING_TYPES = "FINISHED_FETCHING_TYPES",
}

export type WorkerTypesAction = {
  type: WorkerAndWorkPlaceTypeActions.UPDATE_WORKER_TYPES;
  payload: WorkerType[];
};

export type WorkPlaceTypesAction = {
  type: WorkerAndWorkPlaceTypeActions.UPDATE_WORK_PLACE_TYPES;
  payload: WorkPlaceType[];
};

export type StartedFetchingTypesAction = {
  type: WorkerAndWorkPlaceTypeActions.STARTED_FETCHING_TYPES;
};

export type FinishedFetchingTypesAction = {
  type: WorkerAndWorkPlaceTypeActions.FINISHED_FETCHING_TYPES;
};
