import { Input as AntdInput } from "antd";
import styled from "styled-components";
import { primitives } from "frontend-admin/src/designsystem/primitives";

export const Input = styled(AntdInput)`
  border-color: ${primitives.colors.grey[300]};
  border-radius: 4px;
  padding: 8px 14px;
  box-shadow: 0px 4px 4px 0px rgba(134, 134, 134, 0.2);

  &:hover {
    border-color: ${primitives.colors.blue[500]};
  }

  &.ant-input-status-error {
    border-color: ${primitives.colors.red[700]};

    &:hover {
      border-color: ${primitives.colors.red[700]};
    }
  }

  &.ant-input-status-warning {
    border-color: ${primitives.colors.yellow[700]};

    &:hover {
      border-color: ${primitives.colors.yellow[700]};
    }
  }

  &:focus:not(#priority) {
    box-shadow: none;
  }
`;
