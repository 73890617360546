import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Button } from "frontend-admin/src/designsystem/Button/Button";
import { capitalizeFirstLetter } from "frontend-admin/src/utils/string";
import { useState } from "react";
import {
  CheckboxCol,
  CheckboxRow,
  FilterFooter,
  FilterLabel,
  StyledCheckbox,
  StyledModal,
} from "../WorkersDirectory.styles";

export interface WorkerFilterModalProps {
  visible: boolean;
  initialWorkerTypes: string[];
  workerTypes: string[];
  onCancel: () => void;
  onFilter: (workerTypesToFilter: string[]) => void;
}

export const WorkerFilterModal = ({
  visible,
  initialWorkerTypes,
  workerTypes,
  onCancel,
  onFilter,
}: WorkerFilterModalProps) => {
  const [filterWorkerType, setFilterWorkerType] = useState(initialWorkerTypes);
  const handleWorkerTypeChange = (event: CheckboxChangeEvent) => {
    const workerType = event.target.value;
    const isSelected = event.target.checked;
    if (isSelected) {
      setFilterWorkerType((previousFilterWorkerType) => [
        ...previousFilterWorkerType,
        workerType,
      ]);
    } else {
      setFilterWorkerType((previousFilterWorkerType) =>
        previousFilterWorkerType.filter((type) => type !== workerType),
      );
    }
  };

  function getWorkerTypeLabel(workerType: string) {
    if (workerType === "LVN") {
      return "LVN/LPN";
    }
    if (workerType.length <= 3) {
      return workerType;
    }
    return workerType
      .toLowerCase()
      .split(" ")
      .map(capitalizeFirstLetter)
      .join(" ");
  }
  return (
    <StyledModal
      data-testid="worker-filter-modal"
      title="Filter"
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={
        <FilterFooter>
          <Button variant="secondary" onClick={() => onFilter([])}>
            Clear filter
          </Button>
          <Button
            variant="primary"
            disabled={!filterWorkerType.length}
            onClick={() => onFilter(filterWorkerType)}
          >
            Apply filter
          </Button>
        </FilterFooter>
      }
      width={500}
    >
      <>
        <FilterLabel>Worker Types</FilterLabel>
        <CheckboxRow>
          {workerTypes.map((workerType) => (
            <CheckboxCol key={workerType}>
              <div>
                <StyledCheckbox
                  key={workerType}
                  value={workerType}
                  checked={filterWorkerType.includes(workerType)}
                  onChange={handleWorkerTypeChange}
                />
                {getWorkerTypeLabel(workerType)}
              </div>
            </CheckboxCol>
          ))}
        </CheckboxRow>
      </>
    </StyledModal>
  );
};
