import "./WindowGlobalFix";
import "./App.css";
import "./index.css";

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { parse, stringify } from "query-string";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import "./App.css";
import { ErrorBoundary } from "./components/errorBoundary";
import App from "./containers/app";
import { GraphQlClient } from "./graphql/client";
import "./index.css";
import store from "./store";
import meta from "./utils/meta";

import { TestPanel } from "@clipboard/test-helpers/TestPanel";
import { ChatProvider } from "./containers/chat/chat";
import { QueryProvider } from "./providers/QueryProvider";

import { routes } from "frontend-admin/src/utils/routes";
import { Guest } from "./guest";

if ((process.env.REACT_APP_NODE_ENV || import.meta.env.MODE) !== "production") {
  if (
    localStorage.getItem("AUTH_TOKEN") &&
    localStorage.getItem("AUTH_TOKEN").length > 0
  ) {
    render(<TestPanel />, document.querySelector("#testPanel"));
  }
}

const target = document.querySelector("#root");

const { api, shiftsBffApi, app } = meta();

window.global.api = api;
window.global.shiftsBffApi = shiftsBffApi;
window.global.app = app;
(async () => {
  const LDProvider = await asyncWithLDProvider({
    user: { anonymous: true },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  });

  render(
    <ErrorBoundary>
      {/* we will replace apollo with react-query since react-query has better support for both REST and graphql */}
      <ApolloProvider client={GraphQlClient}>
        <QueryProvider>
          <Provider store={store}>
            <BrowserRouter>
              <QueryParamProvider
                adapter={ReactRouter5Adapter}
                options={{
                  searchStringToObject: parse,
                  objectToSearchString: stringify,
                }}
              >
                <LDProvider>
                  <ChatProvider>
                    <Switch>
                      {/* add Guest's path to satisfy Switch's requirement for Route direct children
                        https://github.com/remix-run/react-router/blob/v5/packages/react-router/modules/Switch.js#L27 */}
                      <Guest
                        path={[
                          routes.workplaceGetAccess,
                          routes.workplaceReferrals,
                        ]}
                      />
                      <App />
                    </Switch>
                  </ChatProvider>
                </LDProvider>
              </QueryParamProvider>
            </BrowserRouter>
          </Provider>
        </QueryProvider>
      </ApolloProvider>
    </ErrorBoundary>,
    target,
  );
})();
