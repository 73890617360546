import { Dispatch } from "react";
import { SuspendedAccountModal } from "./SuspendedAccountModal";

export type PermisionDeniedAction = {
  type: string | undefined;
  data: unknown | undefined;
  reducer: Dispatch<PermisionDeniedAction>;
};

export type PermissionDeniedState = {
  content: React.ReactNode;
};

export const permissionDeniedInitial: PermissionDeniedState = {
  content: undefined,
};

export function permissionDeniedReducer(
  _state: PermissionDeniedState,
  action: PermisionDeniedAction,
): PermissionDeniedState {
  switch (action.type) {
    case "suspended_account":
      return {
        content: (
          <SuspendedAccountModal
            key="suspended_account"
            onClose={() =>
              action.reducer({
                type: undefined,
                data: undefined,
                reducer: action.reducer,
              })
            }
          />
        ),
      };

    default:
      return { content: undefined };
  }
}
