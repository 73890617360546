export const EMPLOYEE_ROLES = {
  ADMINISTRATIVE: "ADMINISTRATIVE",
  BILLING: "BILLING",
  DOCUMENTS: "DOCUMENTS",
  DOCUMENT_CREATION: "DOCUMENT_CREATION",
  MULTI_STATE: "MULTI_STATE",
  SHIFT_PRICING: "SHIFT_PRICING",
  SHIFT_MANAGEMENT: "SHIFT_MANAGEMENT",
  SHIFT_ADJUSTMENT: "SHIFT_ADJUSTMENT",
  SHIFT_VERIFICATION: "SHIFT_VERIFICATION",
  VERIFICATION_PREFERENCES: "VERIFICATION_PREFERENCES",
  SHIFT_CHANGE_RATE: "SHIFT_CHANGE_RATE",
  WEEKLY_VERIFIED_SHIFTS: "WEEKLY_VERIFIED_SHIFTS",
  INVOICE_EDIT: "INVOICE_EDIT",
  NOTE_EDIT: "NOTE_EDIT",
  PAYROLL: "PAYROLL",
  INSTANT_BOOK: "INSTANT_BOOK",
  BULK_SHIFT_CREATION: "BULK_SHIFT_CREATION",
  VIEW_FULL_SSN: "VIEW_FULL_SSN",
  EXTRA_TIME_PAY: "EXTRA_TIME_PAY",
  DELETE_HCP_DATA: "DELETE_HCP_DATA",
  NOTIFICATION_PREFERENCES: "NOTIFICATION_PREFERENCES",
  TEST_ACCOUNTS: "TEST_ACCOUNTS",
  FILE_STORAGE_S3_MIGRATION: "FILE_STORAGE_S3_MIGRATION",
  WORKER_LICENSE_MANAGER: "WORKER_LICENSE_MANAGER",
  NO_CALL_NO_SHOW_TRIAGE: "NO_CALL_NO_SHOW_TRIAGE",
  WORKER_ACCOUNT_STATUS_MANAGER: "WORKER_ACCOUNT_STATUS_MANAGER",
  ATTENDANCE_SCORE_DISPUTES: "ATTENDANCE_SCORE_DISPUTES",
  MANAGE_WORKER_WORKPLACE_TYPES: "MANAGE_WORKER_WORKPLACE_TYPES",
};

// This roles are no longer used. And we use below data to only filter out this values,
// when we are saving roles to the backend or displaying on the frontend.
export const DEPRECATED_EMPLOYEE_ROLES = [
  "BROADCAST",
  "SHIFT_VERIFICATION_UPDATES",
];

export const EMPLOYEE_API_ROLES = {
  INVOICE_EDIT: "INVOICE_EDIT",
};

export enum REPORT_PERMISSIONS {
  EXPORT_AGENT_CONTACTS = "EXPORT_AGENT_CONTACTS",
  EXPORT_FACILITY_CONTACTS = "EXPORT_FACILITY_CONTACTS",
  SHIFT_LOGS = "SHIFT_LOGS",
  FACILITY_BREAKDOWN_METRICS = "FACILITY_BREAKDOWN_METRICS",
  FACILITY_CHURN_METRICS = "FACILITY_CHURN_METRICS",
  HCP_CHURN_METRICS = "HCP_CHURN_METRICS",
  INVOICE_BY_PERIOD = "INVOICE_BY_PERIOD",
  /**
   * @deprecated  via CH-11736
   */
  AGENT_PAYROLL_CSV = "AGENT_PAYROLL_CSV",
}
