import { Link } from "../Link/Link";
import { Action, Wrapper } from "./Banner.styles";
import { BannerProps } from "./Banner.type";

export const Banner = ({
  closable,
  action,
  title,
  description,
  type,
  onClose,
  visible = true,
  ...props
}: BannerProps): JSX.Element => {
  if (!visible) {
    return <></>;
  }

  return (
    <Wrapper type={type} {...props}>
      <div>
        <div>{title}</div>
        <div>{description}</div>
      </div>
      <Action>
        {action && <>{action}</>}
        {closable && (
          <Link href="#" onClick={onClose} underline={false} strong>
            Dismiss
          </Link>
        )}
      </Action>
    </Wrapper>
  );
};

Banner.defaultProps = {
  visible: true,
};
