import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";
import {
  createNewUser,
  fetchUserCount,
  fetchUsers,
  findUserWithEmail,
  linkNewUser,
  updateUserInfo,
} from "frontend-admin/src/containers/facilityUser/api";
import {
  FacilityFlags,
  fillDefaultAlertSettings,
} from "frontend-admin/src/containers/facilityUser/helpers";
import { createNotifyRoles } from "frontend-admin/src/containers/myAccount/Notification";
import {
  generateAddEditLogData,
  generateLogData,
} from "frontend-admin/src/containers/teamMembers/logs";
import {
  teamMemberInitialState,
  teamMemberReducer,
} from "frontend-admin/src/containers/teamMembers/teamMemberReducer";
import { getHighestAccessRole } from "frontend-admin/src/containers/teamMembers/utils";
import { SessionType } from "frontend-admin/src/modules/interface";
import {
  IFacility,
  TypeAlertSettings,
  TypeGranularControl,
  TypeUserInfo,
} from "frontend-admin/src/modules/myAccount/types";
import { errorMessage } from "frontend-admin/src/utils/errors";
import { logEvent } from "frontend-admin/src/utils/segment/logEvents";
import { useLDClient } from "launchdarkly-react-client-sdk";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_PROFILE } from "frontend-admin/src/modules/session";
import {
  FacilityOnboardingStep,
  updateFacilityOnboardingSteps,
} from "frontend-admin/src/api/facility";
import { getSignInLink } from "frontend-admin/src/utils/user";
import { routes } from "frontend-admin/src/utils/routes";

export const DEFAULT_PAGE_SIZE = 24;
export const QUERY_CATEGORY_KEY = "category";
export const QUERY_SEARCH_KEY = "search";

export interface ITeamMember {
  name: string;
  firstName: string;
  lastName: string;
  roles: string[];
  phone: string;
  email: string;
  workplacePhone: string;
  _id: string;
  firebaseId: string;
  designation: string;
  permissions?: string[];
  alertSettings?: TypeAlertSettings;
  userInfo?: Partial<TypeUserInfo>;
  facilities?: IFacility[];
}

export function useTeamMembersApiHook({
  setIsDebouncing,
}: {
  setIsDebouncing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    profile: facility,
    userId,
    admin,
    type,
    profile: { userId: workplaceId },
  } = useSelector((state: SessionType) => state.session);
  const profileDispatch = useDispatch();
  const [teamMembers, setTeamMembers] = useState<ITeamMember[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [teamMemberState, dispatch] = useReducer(
    teamMemberReducer,
    teamMemberInitialState,
  );

  const [nextPageToken, setNextPageToken] = useState<string | undefined>();
  const getUsersMutation = useMutation({
    mutationFn: fetchUsers,
  });
  const getUsersCountMutation = useMutation({ mutationFn: fetchUserCount });
  const getUserWithEmail = useMutation({ mutationFn: findUserWithEmail });
  const setLinkNewUser = useMutation({ mutationFn: linkNewUser });
  const setUpdateUser = useMutation({ mutationFn: updateUserInfo });
  const setCreateUser = useMutation({ mutationFn: createNewUser });

  const flags = useFetchFlags(
    {
      key: facility?.userId,
      name: facility?.name,
      email: facility?.email,
      custom: {
        msa: facility?.fullAddress?.metropolitanStatisticalArea,
        facilitymsa: facility?.fullAddress?.metropolitanStatisticalArea,
        facilitystate: facility?.fullAddress?.state,
      },
    },
    facility,
  );

  const generateUserInfo = async (
    formData: ITeamMember,
    member?: ITeamMember,
  ): Promise<[boolean, Partial<TypeUserInfo>]> => {
    let userInfo: Partial<ITeamMember> = {};
    if (formData && member) {
      userInfo = omitBy(formData, (value, key) => isEqual(member[key], value));
    } else {
      if (formData) {
        userInfo = formData;
      }
    }
    const granularControl = fillDefaultAlertSettings(
      member?.alertSettings ?? { EMAIL: {}, SMS: {} },
      formData.roles,
      flags,
      formData.permissions,
    ) as TypeGranularControl;

    const generatedUserInfo = createNotifyRoles({
      ...userInfo,
      granularControl,
    });
    const updateProperties = omit(
      generatedUserInfo ?? {},
      "granularControl",
      "alertSettings",
      "notify",
    );

    return [!!Object.keys(updateProperties), generatedUserInfo];
  };

  const isLoading =
    getUsersMutation.isLoading || getUsersCountMutation.isLoading;

  const cancel = async (
    formData: ITeamMember,
    member: ITeamMember,
    user: SessionType["session"]["user"],
    admin: SessionType["session"]["admin"],
    type: SessionType["session"]["type"],
    workplaceId: SessionType["session"]["profile"]["userId"],
  ) => {
    const role = formData.roles as unknown as string;
    const updatedData = { ...formData, roles: [role] };
    const [hasChanged, userInfo] = await generateUserInfo(updatedData, member);

    if (hasChanged) {
      const eventData = generateAddEditLogData(
        userInfo,
        user,
        admin,
        type,
        workplaceId,
        member,
      );
      logEvent(HCF_USER_EVENTS.ADD_EDIT_TEAM_MEMBER, {
        ...eventData,
        action: "close",
      });
    }
  };

  const fetchTeamMembers = async ({
    userId,
    isArchived,
    page,
    filter,
    resetData = false,
  }: {
    userId: string;
    isArchived: boolean;
    page?: number;
    filter?: string;
    resetData?: boolean;
  }) => {
    setIsDebouncing(false);

    try {
      const [count, members] = await Promise.all([
        getUsersCountMutation.mutateAsync({
          filter: {
            name: filter,
          },
          facilityId: userId,
          isArchived,
        }),
        getUsersMutation.mutateAsync({
          filter: {
            name: filter,
          },
          page,
          facilityId: userId,
          isArchived,
        }),
      ]);

      dispatch({ type: "SET_TEAM_MEMBERS_COUNT", count });

      if (members?.length) {
        dispatch({
          type: "SET_TEAM_MEMBERS",
          teamMembers: members,
          resetData,
        });
      } else {
        dispatch({ type: "CLEAR_TEAM_MEMBERS" });
      }
    } catch (e: unknown) {
      message.error("Something went wrong when fetching members");
    }
  };

  const fetchUserWithEmail = async (email: string, roles: string[]) => {
    try {
      const member = await getUserWithEmail.mutateAsync({
        facilityId: userId,
        email,
      });

      if (!member) {
        return;
      }

      if (getHighestAccessRole(member.roles) > getHighestAccessRole(roles)) {
        return;
      }

      dispatch({
        type: "SET_MODAL_MEMBER",
        member: {
          ...member,
          role: getHighestAccessRole(member?.roles, true),
        },
      });
    } catch (err) {
      message.error(errorMessage(err));
    }
  };

  const linkUser = async (memberId: string) => {
    const event = generateLogData(userId, admin, type, workplaceId, memberId);
    try {
      await setLinkNewUser.mutateAsync({
        userId: memberId,
        facilityId: userId ?? "",
        signInLink: teamMemberState.member
          ? getSignInLink(routes.root, teamMemberState.member.email, true)
          : undefined,
      });
      logEvent(HCF_USER_EVENTS.LINK_TEAM_MEMBER, {
        ...event,
        success: true,
      });
    } catch (err) {
      message.error(errorMessage(err));
      logEvent(HCF_USER_EVENTS.LINK_TEAM_MEMBER, {
        ...event,
        success: false,
      });
    }
  };

  const updateUser = async (
    formData: ITeamMember,
    member: ITeamMember,
    user: SessionType["session"]["user"],
    profile: SessionType["session"]["profile"],
    admin: SessionType["session"]["admin"],
    type: SessionType["session"]["type"],
  ) => {
    const [hasChanged, userInfo] = await generateUserInfo(formData, member);
    const eventData = generateAddEditLogData(
      userInfo,
      user,
      admin,
      type,
      profile.userId,
      member,
    );

    try {
      const form = {
        facilityId: profile.userId,
        firebaseId: member?.firebaseId,
        performedBy: admin ?? user?._id,
        userId: member?._id,
        userInfo,
      };
      await setUpdateUser.mutateAsync(form);
      if (hasChanged) {
        logEvent(HCF_USER_EVENTS.ADD_EDIT_TEAM_MEMBER, {
          ...eventData,
          action: "edit",
          success: true,
        });
      }
    } catch (err) {
      message.error(errorMessage(err));
      if (hasChanged) {
        logEvent(HCF_USER_EVENTS.ADD_EDIT_TEAM_MEMBER, {
          ...eventData,
          action: "edit",
          success: false,
        });
      }
    }
  };

  const createUser = async (
    formData: ITeamMember,
    user: SessionType["session"]["user"],
    profile: SessionType["session"]["profile"],
    admin: SessionType["session"]["admin"],
    type: SessionType["session"]["type"],
  ) => {
    const [hasChanged, userInfo] = await generateUserInfo(formData);
    const eventData = generateAddEditLogData(
      userInfo,
      user,
      admin,
      type,
      profile.userId,
    );
    try {
      const form = {
        facilityId: profile.userId,
        performedBy: admin ?? user?._id ?? "",
        user: userInfo,
        signInLink: userInfo.email
          ? getSignInLink(routes.root, userInfo.email, true)
          : undefined,
      };
      await setCreateUser.mutateAsync(form);

      if (profile.onboardingSteps?.addTeamMembers === false) {
        profileDispatch({
          type: UPDATE_PROFILE,
          data: {
            ...profile,
            onboardingSteps: {
              ...profile.onboardingSteps,
              addTeamMembers: true,
            },
          },
        });
      }
      if (hasChanged) {
        logEvent(HCF_USER_EVENTS.ADD_EDIT_TEAM_MEMBER, {
          ...eventData,
          action: "add",
          success: true,
        });
      }
    } catch (err) {
      message.error(errorMessage(err));
      if (hasChanged) {
        logEvent(HCF_USER_EVENTS.ADD_EDIT_TEAM_MEMBER, {
          ...eventData,
          action: "add",
          success: false,
        });
      }
    }
  };

  const { mutateAsync: updateOnboardingSteps } = useMutation({
    mutationFn: ({
      workplaceIdToUpdate,
      steps,
      workplaceUserId,
    }: {
      workplaceIdToUpdate: string;
      steps: Partial<FacilityOnboardingStep>;
      workplaceUserId: string;
    }) =>
      updateFacilityOnboardingSteps(
        workplaceIdToUpdate,
        steps,
        workplaceUserId,
      ),
  });

  const handleAddTeamMemberOnboardingStep = async (
    workplaceUserId: string,
    profile: SessionType["session"]["profile"],
  ) => {
    if (profile.onboardingSteps?.addTeamMembers === false) {
      const resp = await updateOnboardingSteps({
        workplaceIdToUpdate: profile.userId,
        steps: {
          addTeamMembers: true,
        },
        workplaceUserId,
      });
      profileDispatch({
        type: UPDATE_PROFILE,
        data: { ...profile, onboardingSteps: resp },
      });
    }
  };

  return {
    teamMembers,
    setTeamMembers,
    currentPage,
    setCurrentPage,
    fetchTeamMembers,
    nextPageToken,
    setNextPageToken,
    isLoading,
    getUsersMutation,
    fetchUserWithEmail,
    generateUserInfo,
    linkUser,
    updateUser,
    createUser,
    dispatch,
    teamMemberState,
    cancel,
    handleAddTeamMemberOnboardingStep,
  };
}

export const useFetchFlags = (identifyConfig, key) => {
  const ldClient = useLDClient();
  const [flags, setFlags] = useState<FacilityFlags | undefined>(undefined);

  useEffect(() => {
    if (!ldClient) {
      return;
    }
    const getFlags = async () => {
      await ldClient.identify(identifyConfig);
      await ldClient.waitUntilReady();
      setFlags(ldClient.allFlags() as FacilityFlags);
    };
    getFlags();
  }, [key]);

  return flags;
};
