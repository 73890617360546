import { matchPath } from "react-router-dom";
import { routes } from "frontend-admin/src/utils/routes";
import { useSearchParams } from "./useSearchParams";

const REQUESTED_URL = "requestedUrl";
const REDIRECT_URL = "redirectURL";

export const useRedirectToContractPage = () => {
  const searchParams = useSearchParams();
  /**
   * This function helps in getting through self sign up from where the user left previously because of issue like.
   * 1. Login link expire.
   */
  const evaluateAndSetRedirectUrlIntoLocalStorage = () => {
    const requestedUrl = searchParams.get(REQUESTED_URL) ?? "";
    if (
      searchParams.get("failed") &&
      requestedUrl && // This check is required to avoid matchPath considering "" as root path.
      matchPath(requestedUrl, {
        path: routes.workplaceSignContract,
        exact: true,
      })
    ) {
      localStorage.setItem(REDIRECT_URL, requestedUrl);
    }
  };

  return { evaluateAndSetRedirectUrlIntoLocalStorage };
};
