import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { ISession } from "frontend-admin/src/types";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const SHOULD_SHOW_LIVE_MAP_VIEW = "show-live-worker-eta-map";

const showLiveMapView = (): boolean => {
  const { profile } = useSelector<{ session: ISession }, ISession>(
    (state) => state.session,
  );

  const [shouldShowLiveMapView, setShouldShowLiveMapView] =
    useState<boolean>(true);

  const ldFlags = useFlags();
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient && profile) {
      ldClient.identify({
        key: profile?.userId ?? "",
        name: profile?.name ?? "",
        custom: {
          msa: profile?.fullAddress?.metropolitanStatisticalArea ?? "",
          city: profile?.fullAddress?.city ?? "",
          state: profile?.fullAddress?.state ?? "",
        },
      });
    }
  }, [profile, profile?.userId]);

  useEffect(() => {
    setShouldShowLiveMapView(ldFlags[SHOULD_SHOW_LIVE_MAP_VIEW]);
  }, [ldFlags?.[SHOULD_SHOW_LIVE_MAP_VIEW], setShouldShowLiveMapView]);

  return shouldShowLiveMapView;
};

export { showLiveMapView };
