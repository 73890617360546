import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useMemo, useReducer } from "react";
import { ResponseError } from "superagent";
import {
  permissionDeniedInitial,
  permissionDeniedReducer,
} from "./permissionDenied";

interface QueryProviderProps {
  children: React.ReactNode;
}

function isPermissionDeniedError(error: unknown): error is ResponseError {
  return (
    error instanceof Error &&
    "status" in error &&
    error["status"] === 403 &&
    "response" in error
  );
}

export const QueryProvider = ({ children }: QueryProviderProps) => {
  const [permissionDenied, dispatchPermissionDenied] = useReducer(
    permissionDeniedReducer,
    permissionDeniedInitial,
  );

  const handleApiError = (error: unknown) => {
    if (isPermissionDeniedError(error)) {
      dispatchPermissionDenied({
        type: error.response?.body?.type,
        data: error.response?.body,
        reducer: dispatchPermissionDenied,
      });
    }
  };

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          // do not retry if response status code is 403
          retry: (failureCount, error) => {
            const isPermissionDenied =
              error instanceof Error
                ? "status" in error && error["status"] === 403
                : false;
            return failureCount < 3 && !isPermissionDenied;
          },
        },
      },
      queryCache: new QueryCache({
        onError: handleApiError,
      }),
      mutationCache: new MutationCache({
        onError: handleApiError,
      }),
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {permissionDenied.content}
    </QueryClientProvider>
  );
};
