import request from "superagent";
import { getAuthHeader } from "frontend-admin/src/utils/superagent";
import qs from "qs";

const workerReviewServiceUrl = process.env.REACT_APP_SHIFT_REVIEW_API_URL;

export type ListOfWorkersQuery = {
  category: ShiftCategory;
  search?: string;
  facilityId: string;
  pageSize: number;
  pageToken?: string;
};

export interface WorkerReview {
  id: string;
  rating: number;
  qualities: string[];
  additionalFeedback: string;
  dnrWorker: false;
  workplaceUserId: string;
  workerId: string;
  shiftId: string;
  workplaceId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export enum ShiftCategory {
  UPCOMING = "upcoming",
  IN_PROGRESS = "in_progress",
  PAST = "past",
  FAVORITE = "favorite",
}
export type WorkerWithShifts = {
  shifts: {
    past: number;
    inprogress: number;
    upcoming: number;
    lastShiftId?: string;
    lastShiftStart?: string;
    lastShiftEnd?: string;
    cancelled?: number;
    hasRecentCancellation?: boolean;
    cancelledInLastMonth?: number;
  };
  id: string;
};

export type WorkerWithFullDetails = WorkerWithShifts & {
  userId: string;
  profileImageUrl?: string;
  name: string;
  qualification: string;
  matchingFacilityQualifications?: string[];
};

export type ListWorkersResponse = {
  workers: WorkerWithFullDetails[];
  nextPageToken?: string;
};

export const getListOfWorkers = async (
  params: ListOfWorkersQuery,
): Promise<ListWorkersResponse> => {
  const response = await request
    .get(`${global.api}/workplace/workers`)
    .set(await getAuthHeader())
    .query(params);
  return response.body;
};

export type PreferredWorker = {
  id: string;
  workplaceId: string;
  workerId: string;
  reason: "INTERNAL_CRITERIA" | "RATING" | "FAVORITE";
  createdAt: Date;
  updatedAt: Date;
};

export const getPreferredWorkers = async (params: {
  workplaceId: string;
  workerId?: string;
  reason?: PreferredWorker["reason"];
}): Promise<{ preferred: PreferredWorker[] }> => {
  const { body } = await request
    .get(`${workerReviewServiceUrl}/preferred-workers`)
    .set(await getAuthHeader())
    .query(params);
  return body;
};

export const addPreferredWorkers = async (params: {
  workplaceId: string;
  workerId: string;
  reason: PreferredWorker["reason"];
}): Promise<GenericResponse> => {
  const { body } = await request
    .put(`${workerReviewServiceUrl}/preferred-workers`)
    .set(await getAuthHeader())
    .send(params);
  return body;
};

type GenericResponse = {
  success: boolean;
  errors?: string[];
};
export const deletePreferredWorkers = async (params: {
  workplaceId: string;
  workerId: string;
  reason?: PreferredWorker["reason"];
}): Promise<GenericResponse> => {
  const { body } = await request
    .delete(`${workerReviewServiceUrl}/preferred-workers`)
    .set(await getAuthHeader())
    .query(params);
  return body;
};

export type WorkerWithMinimalProfile = {
  ratingRequestId: string;
  workerId: string;
  ratedAt?: string;
  skippedAt?: string;
  name?: string;
  profileImageUrl?: string;
};

export type WorkersAwaitingReview =
  | {
      id: string;
      workplaceId: string;
      workplaceName: string;
      periodStartDate: string;
      periodEndDate: string;
      workers: WorkerWithMinimalProfile[];
    }
  | undefined;

export const fetchWorkerAwaitingReviews = async (
  workplaceId: string,
): Promise<WorkersAwaitingReview> => {
  const { body } = await request
    .get(`${workerReviewServiceUrl}/reviews/awaiting`)
    .set(await getAuthHeader())
    .query({ workplaceId });
  return body;
};

export type WorkerWithLastShift = {
  id: string;
  userId: string;
  name: string;
  profileImageUrl: string;
  lastShift?: {
    id: string;
    start: string;
    end: string;
    agentReq: string;
  };
  rating?: WorkerReview & { lastEditedBy?: string };
};

export const fetchWorkersForRating = async (params: {
  workplaceId: string;
  workerIds: string[];
  includeRating?: boolean;
}): Promise<WorkerWithLastShift[]> => {
  const response = await request
    .get(`${global.api}/workplace/workers/for-rating`)
    .set(await getAuthHeader())
    .query(qs.stringify(params, { arrayFormat: "brackets" }));
  return response.body;
};

type HighRatingWorkersQuery = {
  searchTerm?: string;
  sortBy?: "distance" | "name";
  sortOrder?: "asc" | "desc";
  pageNumber: number;
  pageSize: number;
  workplaceId: string;
};
export type WorkerDirectory = {
  name: string;
  workerId: string;
  averageRating: number;
  distance: number;
  ratingsCount: number;
  completedShiftsCount: number;
  attendanceRate: number;
  matchingFacilityQualifications: string[];
  profileImageUrl?: string;
  profileVideoUrl?: string;
};

export type GetWorkersDirectoryResponse = {
  workers: WorkerDirectory[];
  pageNumber: number;
  pageSize: number;
};
export async function fetchHighRatingWorkers(
  query: HighRatingWorkersQuery,
): Promise<GetWorkersDirectoryResponse> {
  const response = await request
    .get(`${workerReviewServiceUrl}/workers-directory`)
    .set(await getAuthHeader())
    .query(query);
  return response.body;
}

export type GetWorkerDirectoryResponse = Omit<WorkerDirectory, "distance"> &
  Partial<Pick<WorkerDirectory, "distance">>;
export async function fetchHighRatingWorker(query: {
  workerId: string;
  workplaceId: string;
}): Promise<GetWorkerDirectoryResponse> {
  const response = await request
    .get(`${workerReviewServiceUrl}/workers-directory/${query.workerId}`)
    .set(await getAuthHeader())
    .query(query);
  return response.body;
}
