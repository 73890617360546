import React from "react";

import "./style.css";
import { logErrors } from "./api";
import meta from "../../utils/meta";

const { supportEmail } = meta();

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.oldErrorHandler = window.onerror;
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidMount() {
    window.onerror = this.logError;
  }

  componentDidCatch(...args) {
    this.logError(args);
  }

  logError(...args) {
    try {
      logErrors({
        app: "Admin",
        message: JSON.stringify(args),
      });
      if (this.oldErrorHandler) {
        this.oldErrorHandler(...args);
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return this.state.error ? (
      <h2 className="error-fallback">
        <span>
          We are sorry, but there seems to be an issue with that request. Please
          reload and try again. If the issue persists please contact Clipboard
          Health support at <b>{supportEmail} | 408-837-0116</b>
          <br />
        </span>
      </h2>
    ) : (
      this.props.children
    );
  }
}
