import React from "react";
import Viewer from "react-viewer";

const ImageViewer = ({ visible, images, zoomSpeed = 0.5, onClose }) => {
  return (
    <Viewer
      visible={visible}
      images={images}
      zoomSpeed={zoomSpeed}
      onClose={onClose}
    />
  );
};

export { ImageViewer };
