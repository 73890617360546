import { Link } from "frontend-admin/src/designsystem/Link/Link";
import { Spinner, Wrapper } from "./Button.styles";
import { ButtonProps } from "./Button.type";

export const Button = ({
  type = "button",
  variant,
  children,
  to,
  external,
  disabled,
  isLoading,
  onClick,
  className,
  "data-testid": testId,
  as,
  size,
  ...props
}: ButtonProps): JSX.Element => {
  const content = (
    <Wrapper
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={className}
      data-testid={testId}
      size={size}
      {...props}
    >
      {isLoading && <Spinner />}
      {children}
    </Wrapper>
  );

  return to ? (
    <Link underline={false} as={as} to={to}>
      {content}
    </Link>
  ) : external ? (
    <Link href={external} target="_blank" underline={false}>
      {content}
    </Link>
  ) : (
    content
  );
};
