enum FeatureFlag {
  ENABLE_NOTIFICATION_PREFERENCES = "hcp-notification-preferences-v2-active",
  // Note: This is a temporary key and will be remove once feature is release for all the users.
  BASE_RATE_PAY_FILTERS = "base-rate-pay-filters",
  BASE_RATE_MULTIPLE_PAY_FILTERS = "base-rate-multiple-pay-filters",
  READS_FILE_STORAGE_WEB = "reads-file-storage-web",
  FACILITY_USER_SUBMIT_SENT_HOME = "facility-user-submit-sent-home",
  NLC_STATES = "nlc-states",
  PERSONAL_ID_SUBTYPES = "personal-id-subtypes",
  HCP_MINIMUM_CHARGE_RATES = "hcp-minimum-charge-rates",
  WORKER_RATINGS_CAROUSEL = "worker-ratings-carousel",
  PRIORITY_ACCESS = "priority-access",
  SUPPORT_LINKS = "support-links",
  MANAGE_NFC_TAGS = "manage-nfc-tags",
  CALIFORNIA_TIMESHEET = "california-timesheet",
  WORKER_SERVICE_FOR_ADMIN_PANEL = "use-worker-service-for-admin-panel",
  WORKER_SERVICE_MIGRATED_API_MAP = "worker-service-migrated-api-map-for-admin-panel",
  RECOMMENDED_RATE_EXPERIMENT = "recommended-rate-experiment",
  VARIABLE_CHARGE_STANDARD = "variable-charge-standard",
  RECOMMENDED_RATE_FOR_CHARGE_BLOCKED_FACILITIES = "recommended-rates-for-charge-blocked-facilities",
  SHOW_HCP_PHONE_TO_FACILITIES = "facility-show-hcp-phone",
  TIME_NEGOTIATION_ACTIVATION = "shift-time-negotiation-activation",
  RATE_NEGOTIATION_ACTIVATION = "rate-negotiation-activation",
  RATE_NEGOTIATION_COMMS_EMAIL = "rate-negotiation-comms-email",
  RATE_NEGOTIATION_COMMS_SMS = "rate-negotiation-comms-sms",
  MY_ACCOUNT_PAGE = "myaccount-page",
  RATE_NEGOTIATION_LIMITS = "rate-negotiation-limits",
  RATE_NEGOTIATION_DISPLAY_WORKER_RATING = "rate-negotiation-display-worker-rating",
  MARKETING_CONVERSION_QUALIFICATIONS_WHITELIST = "marketing-conversion-qualifications-whitelist",
  NEW_USERS_PAGE = "new-users-page",
  BULK_UPDATE_EMPLOYEE_ROLES = "bulk-update-employee-roles",
  SHOW_WORKER_ETA_URGENT_SHIFTS_CARD = "show-worker-eta-urgent-shifts-card",
  NOTIFICATION_CENTER_ACTIVE = "notification-center-hcf-web",
  NOTIFICATION_CENTER_COMMS_ASSIGNED = "notification-center-comms-assigned",
  NOTIFICATION_CENTER_COMMS_UNASSIGNED = "notification-center-comms-unassigned",
  SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION = "send-bird-concurrent-connection-optimisation",
  MAKE_RATING_FEEDBACK_REQUIRED = "make-rating-feedback-required",
  AUTO_EXPAND_SHIFTS_PANEL_IN_CALENDAR_PAGE = "auto-expand-shifts-panel-in-calendar-page",
  WORKPLACE_ONBOARDING_STEPS_AB_TESTING = "workplace-onboarding-steps-a-b-testing",
  POST_SHIFT_OPTIONS_AB_TESTING = "post-shift-options-a-b-testing",
  HCF_ONBOARDING = "hcf-onboarding",
  WORKPLACE_BLOCK_SHIFTS_TYPE_FORMS = "workplace-assignment-typeforms",
  SHIFT_EXTENSION = "shift-extension",
  USP_CLIENT_SETTINGS = "usp-client-settings",
  WORKPLACE_SELF_ONBOARDING_CTA_COPY = "workplace-self-onboarding-cta-copy",
  WORKER_ETA_URGENT_SHIFT_PARITY = "worker_eta_urgent_shift_parity",
  CHANNEL_OPEN_30DAYS_AFTER_LAST_BOOKED_SHIFT = "channel-open-30-days-after-last-booked-shift",
  SHOW_RATE_NEGOTIATION_CONTROL_TOGGLE = "show-rate-negotiation-control-toggle",
  BULK_SHIFT_POSTING = "bulk-shift-posting",
  ATTENDANCE_RATE_CONFIG = "attendance-rate-config",
  VARIABLE_CHARGE_REVAMPED_ACCESS_CONTROL = "variable-charge-revamped-access-control",
  WORKPLACE_SELF_SIGNUP = "workplace-self-signup",
}

export { FeatureFlag };
