import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  PreferredWorker,
  getPreferredWorkers,
} from "frontend-admin/src/api/workers";
import { LONG_STALE_TIME } from "frontend-admin/src/constants/query";

export function usePreferredWorker(workplaceId: string, workerId: string) {
  const queryClient = useQueryClient();
  const queryKey = ["preferred-worker", workplaceId, workerId];

  const { isLoading: preferredWorkerLoading, data: preferredWorkers } =
    useQuery({
      queryKey,
      queryFn: () => {
        return getPreferredWorkers({
          workplaceId,
          workerId,
        });
      },
      staleTime: LONG_STALE_TIME,
    });

  const invalidateCache = () => {
    queryClient.removeQueries(queryKey);
  };

  return {
    data: preferredWorkers?.preferred[0],
    isLoading: preferredWorkerLoading,
    invalidateCache,
  };
}

export function isFavoritedWorker(
  preferredWorker: PreferredWorker | undefined,
): boolean {
  return (
    preferredWorker?.reason === "FAVORITE" ||
    preferredWorker?.reason === "RATING"
  );
}
