import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  Form,
  Row,
  Button,
  PageHeader,
  Divider,
  Typography,
  message,
} from "antd";
import { UPDATE_HCP_DOCUMENT, GET_DOCUMENT_LIST } from "../graphql/gql";
import ShouldRender from "./ShouldRender";
import axios from "axios";
import moment from "moment-timezone";

const { Title } = Typography;

const ApproveDocs = () => {
  const [isGetData, setIsGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hcpId, setHcpid] = useState("");
  const [form] = Form.useForm();
  const [updateHcpDocument] = useMutation(UPDATE_HCP_DOCUMENT);
  const [getList, result] = useLazyQuery(GET_DOCUMENT_LIST);
  const loadList = (hcpId) => {
    getList({ variables: { hcpId: hcpId } });
  };
  const docs = result?.data?.documentList;

  useEffect(() => {
    getAgentProfile();
  }, []);

  const getAgentProfile = async () => {
    setIsGetData(true);
    try {
      if (localStorage.getItem("authToken")) {
        const agent = await axios.get(
          `${process.env.REACT_APP_API_MAIN_URL || ""}/api/agentProfile`,
          {
            headers: {
              Authorization: localStorage.getItem("authToken"),
            },
          }
        );
        if (agent.data) {
          loadList(agent.data.userId);
          setHcpid(agent.data.userId);
        }
      } else if (JSON.parse(localStorage.getItem("state")).type === "AGENT") {
        let userID = JSON.parse(localStorage.getItem("state")).userId;
        loadList(userID);
        setHcpid(userID);
      } else {
        message.error(
          `Cannot access HCP docs, please make sure you are logged in as Admin`
        );
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      setIsGetData(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const results = await Promise.all(
        docs.map((doc) => {
          if (doc.status === "PENDING") {
            return updateHcpDocument({
              variables: {
                _id: doc._id,
                status: "APPROVED",
                expiry: `${moment().add(4, "months")}`,
              },
            });
          }
        })
      );
      results.forEach((resp) => {
        if (resp && resp.data) {
          message.success(
            `${resp.data.updateHcpDocument.name} approved successfully`
          );
        }
      });
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      loadList(hcpId);
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="pageHeader"
        title="Approve Documents"
        subtitle="Approve Documents"
      />
      <Divider className="ApproveDocsHeaderDivider" />
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          onFinish={onSubmit}
          style={{ width: "600px" }}
        >
          <Row>
            <Title level={4}>Documents</Title>
          </Row>
          <Row>
            {isGetData || !docs ? (
              <div>Getting required data...</div>
            ) : (
              <ShouldRender condition={hcpId}>
                <div>
                  <p>The following pending docs will be approved:</p>
                  {docs.map((d) => {
                    if (d.status === "PENDING") {
                      return <p key={d._id}>{d.name}</p>;
                    }
                  })}
                </div>
              </ShouldRender>
            )}
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default ApproveDocs;
