import palette from "google-palette";

const Colors = {
  primary: "#1890ff",
  success: "#4e9409",
  danger: "#f5222d",
  grey: "#A9A9A9",
  warning: "#da9711",
  darkGrey: "#424242",
  darkRed: "#ee2a00",
};

const generateColorPalette = function* colorPalette(colorCount = 30) {
  const paletteColors = palette("mpn65", colorCount);

  let index = 0;

  while (true) {
    yield `#${paletteColors[index]}`;
    index += 1;

    if (index >= paletteColors.length) {
      index = 0;
    }
  }
};

export { Colors, generateColorPalette };
