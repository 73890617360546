import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  Form,
  Row,
  Button,
  PageHeader,
  Divider,
  Typography,
  message,
} from "antd";
import { GET_REQUIREMENTS_STATUS, CREATE_HCP_DOCUMENT } from "../graphql/gql";
import ShouldRender from "./ShouldRender";
import axios from "axios";

const { Title } = Typography;

const UploadDocs = () => {
  const [isGetData, setIsGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hcpId, setHcpid] = useState("");
  const [form] = Form.useForm();
  const [createHcpDocument] = useMutation(CREATE_HCP_DOCUMENT);
  const [getReqs, result] = useLazyQuery(GET_REQUIREMENTS_STATUS);
  const loadReqs = (hcpId) => {
    getReqs({ variables: { hcpId: hcpId } });
  };
  const reqs = result?.data?.hcpRequirementStatus;

  useEffect(() => {
    getAgentProfile();
  }, []);

  const getAgentProfile = async () => {
    setIsGetData(true);
    try {
      if (localStorage.getItem("authToken")) {
        const agent = await axios.get(
          `${process.env.REACT_APP_API_MAIN_URL || ""}/api/agentProfile`,
          {
            headers: {
              Authorization: localStorage.getItem("authToken"),
            },
          }
        );
        if (agent.data) {
          loadReqs(agent.data.userId);
          setHcpid(agent.data.userId);
        }
      } else if (JSON.parse(localStorage.getItem("state")).type === "AGENT") {
        let userID = JSON.parse(localStorage.getItem("state")).userId;
        loadReqs(userID);
        setHcpid(userID);
      } else {
        message.error(
          `Cannot access HCP docs, please log in using the app or navigate to HCP profile on Admin`
        );
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      setIsGetData(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const results = await Promise.all([
        ...reqs.missing.map((doc) => {
          if (!reqs.pending.includes(doc)) {
            return createHcpDocument({
              variables: {
                name: reqs.requirements.find((e) => e.reqId === doc).name,
                hcpId,
                fileUrl: "https://picsum.photos/600",
                uploadedFor: doc,
                fileStorageFileKey: "testFileKey",
                fileStorageRootFolder: "testBucket",
              },
            });
          }
        }),
        ...reqs.expired.map((doc) => {
          if (!reqs.pending.includes(doc)) {
            return createHcpDocument({
              variables: {
                name: reqs.requirements.find((e) => e.reqId === doc).name,
                hcpId,
                fileUrl: "https://picsum.photos/600",
                uploadedFor: doc,
                fileStorageFileKey: "testFileKey",
                fileStorageRootFolder: "testBucket",
              },
            });
          }
        }),
      ]);
      results.forEach((resp) => {
        if (resp && resp.data) {
          message.success(
            `${resp.data.createHcpDocument.name} uploaded successfully`
          );
        } else if (resp && resp.error) {
          message.error(
            `Document already uploaded: expired or waiting for approval`
          );
        }
      });
    } catch (error) {
      console.error("Action Resp Error: ", error);
      message.error(`Test panel Error ${error.message}`);
    } finally {
      loadReqs(hcpId);
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="pageHeader"
        title="Upload Documents"
        subtitle="Upload Documents"
      />
      <Divider className="uploaddocsHeaderDivider" />
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", height: "100%" }}
      >
        <Form
          {...layout}
          name="basic"
          form={form}
          onFinish={onSubmit}
          style={{ width: "600px" }}
        >
          <Row>
            <Title level={4}>Documents</Title>
          </Row>
          <Row>
            {isGetData || !reqs ? (
              <div>Getting required data...</div>
            ) : (
              <ShouldRender condition={hcpId}>
                <div>
                  <p>Missing requirements:</p>
                  {reqs.missing.map((r) => {
                    return (
                      <p key={r}>
                        {!reqs.pending.includes(r) &&
                          reqs.requirements.find((e) => e.reqId === r).name}
                      </p>
                    );
                  })}
                  {reqs.expired.map((r) => {
                    return (
                      <p key={r}>
                        {!reqs.pending.includes(r) &&
                          reqs.requirements.find((e) => e.reqId === r).name}
                      </p>
                    );
                  })}
                </div>
              </ShouldRender>
            )}
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default UploadDocs;
