import {
  FinishedFetchingTypesAction,
  StartedFetchingTypesAction,
  WorkerAndWorkPlaceTypeActions,
  WorkerTypesAction,
  WorkPlaceTypesAction,
} from "./actions";
import IWorkerAndWorkPlaceTypeStore from "./store/Store";

const initialState: IWorkerAndWorkPlaceTypeStore = {
  workerTypes: [],
  workPlaceTypes: [],
  fetchingTypes: false,
};

type WorkerAndWorkPlaceTypeActionTypes =
  | WorkerTypesAction
  | WorkPlaceTypesAction
  | StartedFetchingTypesAction
  | FinishedFetchingTypesAction;

const workerAndWorkPlaceTypes = (
  state = initialState,
  action: WorkerAndWorkPlaceTypeActionTypes,
): IWorkerAndWorkPlaceTypeStore => {
  switch (action.type) {
    case WorkerAndWorkPlaceTypeActions.UPDATE_WORKER_TYPES:
      if (!action.payload) return state;
      return {
        ...state,
        workerTypes: action.payload,
      };

    case WorkerAndWorkPlaceTypeActions.UPDATE_WORK_PLACE_TYPES:
      if (!action.payload) return state;
      return {
        ...state,
        workPlaceTypes: action.payload,
      };
    case WorkerAndWorkPlaceTypeActions.STARTED_FETCHING_TYPES:
      return {
        ...state,
        fetchingTypes: true,
      };
    case WorkerAndWorkPlaceTypeActions.FINISHED_FETCHING_TYPES:
      return {
        ...state,
        fetchingTypes: false,
      };

    default:
      return state;
  }
};

export default workerAndWorkPlaceTypes;
