import { Col, List, Row } from "antd";
import { BatchModal } from "frontend-admin/src/containers/myAccount/BatchModal";
import { NotificationDetails } from "frontend-admin/src/containers/myAccount/constants";
import { NotificationBlockedMessage } from "frontend-admin/src/containers/myAccount/NotificationBlockedMessage";
import {
  Blank,
  Box,
  DownChevron,
  Dropdown,
  UneditableNotificationOption,
} from "frontend-admin/src/containers/myAccount/styles";
import { SessionType } from "frontend-admin/src/modules/interface";
import {
  IUser,
  NotificationSelectorType,
  TypeUserInfo,
} from "frontend-admin/src/modules/myAccount/types";
import {
  configureAlertSettings,
  fetchFlags,
  updateNotification,
} from "frontend-admin/src/modules/myAccount/utils";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeatureFlag } from "frontend-admin/src/constants/FEATURE_FLAGS";
import { RateNegotiationConfigEnum } from "frontend-admin/src/components/RateNegotiationModal/interfaces";
import { useRateNegotiationFlag } from "frontend-admin/src/hooks/useRateNegotiationFlag";
import { useRestrictedSignedProfile } from "frontend-admin/src/hooks/useRestrictedSignedProfile";
import { noop } from "frontend-admin/src/utils/noop";

const uneditableOption = (text: string) => (
  <UneditableNotificationOption data-testid="uneditable-notification-option">
    {text}
  </UneditableNotificationOption>
);

export const createNotifyRoles = (userInfo: Partial<TypeUserInfo>) => {
  let alertSettings;
  const notifySettings = { EMAIL: {}, SMS: {} };
  const { granularControl } = userInfo;

  if (granularControl) {
    const keys = Object.keys(granularControl);

    alertSettings = keys.reduce((accOuter, key) => {
      const values = granularControl[key].reduce((acc, item) => {
        if (item.action !== "All" && !notifySettings[key][item.role]) {
          notifySettings[key][item.role] = item.default.enabled;
        }

        return { ...acc, [item.action]: item.default };
      }, {});
      return { ...accOuter, [key]: { ...values } };
    }, {});
  }

  userInfo.alertSettings = alertSettings;
  userInfo.notify = { ...userInfo.notify, ...notifySettings };

  return userInfo;
};

export const NotificationSelector = ({
  alertType,
  alertSetting,
  notification,
  disabled,
  webPushAllNotifications,
  member,
}: NotificationSelectorType) => {
  const dispatch = useDispatch();
  const { wrapRestrictedAction } = useRestrictedSignedProfile();
  const { flags: featureFlags } = useSelector(
    (state: SessionType) => state.myAccount,
  );
  if (!featureFlags) {
    return null;
  }
  const flagKey = alertType === "email" ? "emailFlag" : "smsFlag";
  // should render or not
  const flagName = notification[flagKey];
  if (flagName && !featureFlags[flagName]) {
    return <Blank />;
  }

  if (disabled) {
    return <Blank />;
  }

  if (!alertSetting && alertType !== "web_push") {
    return <Blank />;
  }

  let defaultValue = "off";
  if (alertType === "web_push") {
    if (webPushAllNotifications) defaultValue = "on";
  } else {
    if (alertSetting?.enabled) {
      defaultValue = "on";
    }
    if (alertSetting?.batch) {
      defaultValue = "daily";
    }
  }

  if (alertType === "web_push" && notification.pushFlags?.length) {
    const allFeatureFlagsTrue = notification.pushFlags.every(
      (flag) => featureFlags[flag],
    );
    if (allFeatureFlagsTrue) {
      return uneditableOption(webPushAllNotifications ? "On" : "Off");
    } else {
      return <Blank />;
    }
  }

  if (!alertSetting?.userModifiableState && alertType !== "web_push") {
    return uneditableOption(alertSetting?.enabled ? "On" : "Off");
  }

  const handleSelectChange = (value: string) => {
    updateNotification(
      dispatch,
      value,
      alertType.toUpperCase(),
      notification[alertType],
      member,
    );
  };

  const options = [
    {
      value: "on",
      label: "On",
      disabled:
        !(alertType === "web_push" || alertSetting?.userModifiableState) ??
        false,
      "data-testid": "On",
    },
    {
      value: "off",
      label: "Off",
      "data-testid": "Off",
    },
  ];

  if (alertSetting?.userModifiableBatch && alertType !== "web_push") {
    options.push({
      value: "daily",
      label: "Daily",
      "data-testid": "Daily",
    });
  }

  return (
    <div data-testid="notification-container" key={defaultValue}>
      <Dropdown
        defaultValue={defaultValue}
        onClick={wrapRestrictedAction(noop, {
          triggeredBy: "edit notifications",
          triggeredFrom: "My Account",
        })}
        onSelect={handleSelectChange}
        suffixIcon={<DownChevron />}
        disabled={
          !(alertType === "web_push" || alertSetting?.userModifiableState) ??
          false
        }
        style={{
          minWidth: 72,
        }}
        options={options}
      />
    </div>
  );
};

export const Notifications = memo(({ member }: { member?: IUser }) => {
  const ldClient = useLDClient();
  const {
    user: loggedInUser,
    flags,
    alertSettings,
  } = useSelector((state: SessionType) => state.myAccount);
  const user = member || loggedInUser;
  const { profile } = useSelector((state: SessionType) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchFlags(dispatch, ldClient, profile);
  }, [dispatch, ldClient, profile]);

  useEffect(() => {
    if (!flags) {
      return;
    }
    configureAlertSettings(dispatch, member);
  }, [dispatch, flags, member]);

  const rateNegotiationDBStatus = useMemo(
    () => profile?.rateNegotiation,
    [profile],
  );
  const { featureFlagStatus: rateNegotiationFFStatus } = useRateNegotiationFlag(
    {
      isAdmin: false, // does not matter for FF status
      facilityRateNegotiationFlag: rateNegotiationDBStatus,
      facilityId: profile?.userId,
      facilityMsa: profile?.fullAddress?.metropolitanStatisticalArea ?? "",
      facilityState: profile?.fullAddress?.state ?? "",
    },
  );

  const enabledNotifications = useMemo(() => {
    const NotificationValues = Object.values(NotificationDetails).map(
      (preference) => {
        if (member) {
          preference.description = preference.description.replace(
            " me ",
            " user ",
          );
        }
        return preference;
      },
    );
    return NotificationValues.filter(({ flag }) => {
      if (!flag) return true;
      // Special handling for rate-negotiation
      // Activation is dependent on the FF as well as the DB
      if (flag === FeatureFlag.RATE_NEGOTIATION_ACTIVATION) {
        return (
          rateNegotiationFFStatus &&
          rateNegotiationDBStatus === RateNegotiationConfigEnum.ON
        );
      }
      return flags?.[flag];
    });
  }, [flags, member, rateNegotiationDBStatus, rateNegotiationFFStatus]);

  if (!alertSettings || !user) {
    return null;
  }

  return (
    <Box>
      <List
        grid={{ column: 1 }}
        dataSource={enabledNotifications}
        className="notification-list"
        renderItem={(notification, index) => {
          return (
            <List.Item
              style={{
                marginBottom: 0,
                color: "#868686",
                borderBottom: "1px solid",
                borderBottomColor:
                  index < enabledNotifications.length - 1
                    ? "#F0F0F0"
                    : "transparent",
              }}
            >
              <Row
                className="notification-row"
                style={{ height: 50 }}
                align={"middle"}
              >
                <Col span={15} className={"notification-description"}>
                  {notification.description}
                  {!member && (
                    <NotificationBlockedMessage notification={notification} />
                  )}
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <NotificationSelector
                    alertType="email"
                    alertSetting={
                      alertSettings.EMAIL[String(notification.email)]?.default
                    }
                    notification={notification}
                    member={member}
                  />
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <NotificationSelector
                    alertType="sms"
                    alertSetting={
                      alertSettings.SMS[String(notification.sms)]?.default
                    }
                    notification={notification}
                    member={member}
                  />
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <NotificationSelector
                    alertType="web_push"
                    notification={notification}
                    disabled={!notification.push}
                    webPushAllNotifications={user?.notify?.WEB_PUSH?.All}
                    member={member}
                  />
                </Col>
              </Row>
            </List.Item>
          );
        }}
      />
      <BatchModal />
    </Box>
  );
});
