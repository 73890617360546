import { isEmpty } from "lodash";

export const getFirstLetterOfName = (name: string, letterCount = 2) => {
  if (typeof name !== "string") return name;
  const separatedName = name.split(" ");
  return separatedName
    .reduce((acc, cur) => acc + (cur?.[0] ?? ""), "")
    .substring(0, letterCount);
};

export const getFirstName = (name: string) => {
  return name.split(" ")[0];
};

export const formatDialablePhone = (phone: string): string => {
  if (isEmpty(phone)) return "";

  // Number is already formatted in a dialable format
  if (phone.match(/^\+\d+/)) {
    return phone;
  }

  const numbersOnly = phone.replace(/\D+/g, "");

  // We assume this is number is in country code 1 (US and Canada),
  // if it has the wrong length, we don't know what to do, so we return
  // them as-is
  if (numbersOnly.length !== 10) {
    return phone;
  }

  // Assume this is number is in country code 1 (US and Canada)
  return `+1${numbersOnly}`;
};

export const formatPhone = (phone: string, mobileCode?: string): string => {
  if (isEmpty(phone)) return "";

  let formattedPhone = phone.replace(/\D+/g, "");

  if (formattedPhone.length !== 10) return phone;

  formattedPhone = formattedPhone.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3",
  );

  if (mobileCode) {
    formattedPhone = `${mobileCode} ${formattedPhone}`;
  }

  return formattedPhone;
};

export const isNumber = (number: string): boolean => {
  return !Number.isNaN(Number(number));
};
