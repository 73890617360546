import { ROLE_MAPPINGS } from "frontend-admin/src/containers/teamMembers/constants";
import { ITeamMember } from "frontend-admin/src/containers/teamMembers/useTeamMembersAPIHook";

export const getHighestAccessRole = (roles?: string[], getID?: boolean) => {
  if (!roles?.length) {
    return undefined;
  }

  const sortedRoles = roles.sort(
    (a, b) => ROLE_MAPPINGS[b].position - ROLE_MAPPINGS[a].position,
  );

  if (getID) {
    return ROLE_MAPPINGS[sortedRoles[0]].id;
  }

  return ROLE_MAPPINGS[sortedRoles[0]].position;
};

export const generateModalMessage = (
  member: ITeamMember,
  showUnlink: boolean,
  modalAction: string,
) => {
  const memberRoles = member.roles
    .map((role) => ROLE_MAPPINGS[role].label)
    .join(", ");

  const deactivationInfo = ` Once this is done, the user can no longer access the${
    showUnlink ? " corresponding" : ""
  } facility's profile${showUnlink ? "s" : ""}.`;

  if (showUnlink) {
    return `Do you want to unlink ${member.firstName} ${
      member.lastName
    } (${memberRoles}) from your facility only or deactivate them from all accounts?${
      modalAction === "deactivate" ? deactivationInfo : ""
    }`;
  }

  return `Are you sure you want to ${modalAction} ${member.firstName} ${
    member.lastName
  } (${memberRoles})?${modalAction === "deactivate" ? deactivationInfo : ""}`;
};
