import { fetchWorkerAndWorkplaceTypes } from "frontend-admin/src/workers/api/workerAndWorkplaceTypes";
import { useEffect, useState } from "react";
import { createWorkPlaceToWorkerMapping } from "frontend-admin/src/workers/utils/createWorkPlaceToWorkerMapping";
import { ALL_SHIFT_TYPES } from "../constants";

interface IUseWorkerTypes {
  workerTypes: string[];
  allWorkerTabTypes: string[];
  isLoading: boolean;
}

const useWorkerTypes = (type: string): IUseWorkerTypes => {
  const [workerTypes, setWorkerTypes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allWorkerTabTypes, setAllWorkerTabTypes] = useState<string[]>([]);

  useEffect(() => {
    setIsLoading(true);
    fetchWorkerAndWorkplaceTypes()
      .then((responses) => {
        const workPlaceTypes = createWorkPlaceToWorkerMapping(
          responses?.workPlaceTypes ?? [],
        ) ?? { [type]: [] };
        setWorkerTypes(() => workPlaceTypes[type]);
        setAllWorkerTabTypes(() => [
          ALL_SHIFT_TYPES,
          ...(workPlaceTypes?.[type] ?? []),
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [type]);

  return { workerTypes, allWorkerTabTypes, isLoading };
};

export { useWorkerTypes };
