import { FirebaseAuth } from "../utils/firebase";
import moment from "moment-timezone";
const getAuthHeader = async () => {
  const tokenResult = await FirebaseAuth.currentUser?.getIdTokenResult();
  let token = tokenResult?.token;

  if (moment(tokenResult?.expirationTime) <= moment()) {
    // token invalid
    const refreshedToken = await FirebaseAuth.currentUser?.getIdTokenResult(
      true,
    );
    token = refreshedToken?.token;
  }
  if (!token) {
    console.error("NO TOKEN FOUND");
    token = localStorage.getItem("AUTH_TOKEN");
  }
  return { Authorization: token };
};

export { getAuthHeader };
