import {
  PROCESS_MODAL_STATE,
  TAB_STATE,
} from "frontend-admin/src/containers/teamMembers/constants";
import { IUser } from "frontend-admin/src/modules/myAccount/types";

type SetTeamMembersAction = {
  type: "SET_TEAM_MEMBERS";
  teamMembers: IUser[];
  resetData: boolean;
};

type SetTeamMembersCountAction = {
  type: "SET_TEAM_MEMBERS_COUNT";
  count: number;
};

type SetPageNumberAction = {
  type: "SET_PAGE_NUMBER";
  page: number;
};

type SetFilterAction = {
  type: "SET_FILTER";
  filter: string;
};

type ClearTeamMembersAction = {
  type: "CLEAR_TEAM_MEMBERS";
};

type SetActiveTabAction = {
  type: "SET_ACTIVE_TAB";
  activeTab: TAB_STATE;
};

type SetModalMemberAction = {
  type: "SET_MODAL_MEMBER";
  member?: IUser;
};

type SetStatusModalVisibilityAction = {
  type: "SET_STATUS_MODAL_VISIBILITY";
  statusModal: boolean;
};

type SetProcessModalVisibilityAction = {
  type: "SET_PROCESS_MODAL_VISIBILITY";
  processModal: PROCESS_MODAL_STATE;
};

type SetNotificationsModalVisibilityAction = {
  type: "SET_NOTIFICATIONS_MODAL_VISIBILITY";
  notificationModal: boolean;
};

export type TeamMemberActions =
  | ClearTeamMembersAction
  | SetTeamMembersAction
  | SetTeamMembersCountAction
  | SetPageNumberAction
  | SetFilterAction
  | SetActiveTabAction
  | SetModalMemberAction
  | SetStatusModalVisibilityAction
  | SetProcessModalVisibilityAction
  | SetNotificationsModalVisibilityAction;

export type TeamMemberState = {
  teamMembers: IUser[];
  count: number;
  page: number;
  filter: string;
  activeTab: TAB_STATE;
  member?: IUser;
  statusModal: boolean;
  processModal: PROCESS_MODAL_STATE;
  notificationModal: boolean;
};

export const teamMemberInitialState: TeamMemberState = {
  teamMembers: [],
  count: 0,
  page: 1,
  filter: "",
  activeTab: TAB_STATE.ACTIVE,
  member: undefined,
  statusModal: false,
  processModal: PROCESS_MODAL_STATE.off,
  notificationModal: false,
};

export const teamMemberReducer = (
  state: TeamMemberState,
  action: TeamMemberActions,
): TeamMemberState => {
  switch (action.type) {
    case "CLEAR_TEAM_MEMBERS":
      return {
        ...state,
        teamMembers: [],
      };

    case "SET_TEAM_MEMBERS": {
      const teamMembers = [
        ...(action.resetData ? [] : state.teamMembers),
        ...action.teamMembers,
      ];

      return {
        ...state,
        teamMembers,
      };
    }

    case "SET_TEAM_MEMBERS_COUNT":
      return {
        ...state,
        count: action.count,
      };

    case "SET_PAGE_NUMBER":
      return {
        ...state,
        page: action.page,
      };

    case "SET_FILTER":
      return {
        ...state,
        filter: action.filter,
      };

    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.activeTab,
      };

    case "SET_MODAL_MEMBER":
      return {
        ...state,
        member: action.member,
      };

    case "SET_STATUS_MODAL_VISIBILITY":
      return {
        ...state,
        statusModal: action.statusModal,
      };

    case "SET_PROCESS_MODAL_VISIBILITY":
      return {
        ...state,
        processModal: action.processModal,
      };
    case "SET_NOTIFICATIONS_MODAL_VISIBILITY":
      return {
        ...state,
        notificationModal: action.notificationModal,
      };
  }
};
