import { LoadingOutlined } from "@ant-design/icons";
import styled, { StyledComponent } from "styled-components";
import { ButtonProps } from "./Button.type";

export const Wrapper = styled.button<ButtonProps>`
  border-radius: 8px;
  padding: ${({ size }) => (size === "lg" ? "8px 24px" : "6px 24px")};
  height: auto;
  width: auto;
  box-shadow: none;
  cursor: pointer;

  ${({ variant, disabled }) =>
    disabled
      ? `
  border: 1px solid #e0e3e6;
  background-color: #e0e3e6;
  color: #adb5bd;
  cursor: not-allowed;
  background-image: none;
  pointer-events: none;
      `
      : variant === "primary"
      ? `
  color: #fafafa;
  background-color: #032e68;
  border: 1px solid #032e68;

	&:hover {
		background-color: #032e68d0;
	}
	`
      : variant === "secondary"
      ? `
  color: #424242;
  background-color: #fff;
  border: 1px solid #bdbdbd;

	&:hover {
		background-color: #f8f9fa;
	}
      `
      : variant === "tertiary"
      ? `
  color: #CA4031;
  border: 1px solid currentColor;
  background: transparent;

	&:hover {
		background-color: #CA4031;
    color: white
	}
  &:focus {
    outline: none;
  }
  `
      : variant === "ghost"
      ? `
  color: #424242;
  background-color: transparent;
  border: 1px solid #424242;

	&:hover {
		background-color: rgb(0, 0, 0, 0.05);
	}
      `
      : variant === "primary-ghost"
      ? `
  color: #032e68;
  background-color: transparent;
  border: 1px solid #032e68;

	&:hover {
		background-color: rgb(0, 0, 0, 0.05);
	}
      `
      : variant === "danger"
      ? `
  color: white;
  border: 1px solid currentColor;
  background: #CA4031;

	&:hover {
		background-color: #CA4031;
    color: white
	}
  &:focus {
    outline: none;
  }
  `
      : undefined};
` as unknown as StyledComponent<"div", any, ButtonProps, any>;
// why button element doesn't accept children? using div type as a workaround

export const Spinner = styled(LoadingOutlined)`
  margin-right: 8px;
`;
