import { FacilityRole } from "frontend-admin/src/api/facilityRoles";
import { FACILITY_ROLES } from "frontend-admin/src/constants/facility";
import { SessionType } from "frontend-admin/src/modules/interface";
import type { useLDClient } from "launchdarkly-react-client-sdk";
import { FacilityFlags } from "../facilityUser/helpers";

export const convertMilitaryTime = (time: number) => {
  const hour = time > 12 ? time - 12 : time;
  const meridiem = time > 12 ? "pm" : "am";

  return { hour, meridiem };
};

export const isAdmin = (
  admin: boolean,
  sessionUser: SessionType["session"]["user"],
) => {
  return (
    (admin || sessionUser?.access?.includes(FACILITY_ROLES.ADMIN)) ?? false
  );
};

export const getSortedRoles = (roles: FacilityRole[]) =>
  roles.sort((a, b) => a.position - b.position);

export const getRoleObjectByRoleID = (
  ids: string[],
  allRoles: FacilityRole[],
) => {
  return ids
    .map((id) => allRoles.find((role) => role._id === id))
    .filter((role): role is FacilityRole => typeof role !== "undefined");
};

export const fetchLDFlagValues = async (
  ldClient: ReturnType<typeof useLDClient>,
  facility: SessionType["session"]["profile"],
) => {
  if (!ldClient) {
    return;
  }
  await ldClient.identify({
    key: facility?.userId,
    name: facility?.name,
    email: facility?.email,
    custom: {
      msa: facility?.fullAddress?.metropolitanStatisticalArea ?? "",
      facilitymsa: facility?.fullAddress?.metropolitanStatisticalArea ?? "",
      facilitystate: facility?.fullAddress?.state ?? "",
    },
  });
  await ldClient.waitUntilReady();
  return ldClient.allFlags() as FacilityFlags;
};
