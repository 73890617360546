import { useMutation } from "@tanstack/react-query";
import {
  WorkerDirectory,
  getPreferredWorkers,
} from "frontend-admin/src/api/workers";
import { useEffect, useState } from "react";
import { WorkerDetails } from "./WorkerDetails";
import { isFavoritedWorker } from "frontend-admin/src/containers/workplace/WorkerDetails/usePreferredWorker";
import { FacilityOnboardingStep } from "frontend-admin/src/api/facility";

interface ListWorkersProps {
  workers: WorkerDirectory[];
  workplaceId: string;
  workplaceUserId?: string;
  workplaceOnboardingSteps: Array<"favorites" | "shifts">;
  postShiftOptionSteps: Array<"block-shifts" | "per-diem">;
  onboardingState?: FacilityOnboardingStep;
  whoCalledMe: "FacilityOnboardingPage" | "DirectoryWorkerPage";
}
export const ListWorkers = ({
  workers,
  workplaceId,
  workplaceUserId,
  onboardingState,
  postShiftOptionSteps,
  workplaceOnboardingSteps,
  whoCalledMe,
}: ListWorkersProps) => {
  const [favoritedWorkers, setFavoritedWorkers] = useState<
    Record<string, boolean>
  >({});

  const { mutateAsync: getPreferredWorkersApi } = useMutation({
    mutationFn: getPreferredWorkers,
  });

  const handledFavoritedChanged = (workerId: string, isAdded: boolean) => {
    setFavoritedWorkers((prevState) => ({ ...prevState, [workerId]: isAdded }));
  };

  useEffect(() => {
    async function fetchPreferredWorkers() {
      const workerIds = workers
        .filter(
          (highRatingWorker) =>
            favoritedWorkers[highRatingWorker.workerId] === undefined,
        )
        .map((highRatingWorker) => highRatingWorker.workerId);
      if (workerIds.length === 0) {
        return;
      }
      const resp = await getPreferredWorkersApi({
        workplaceId,
        workerId: workerIds.join(","),
      });
      setFavoritedWorkers((prevState) => {
        return {
          ...prevState,
          ...resp.preferred.reduce((acc, worker) => {
            acc[worker.workerId] = isFavoritedWorker(worker);
            return acc;
          }, {}),
        };
      });
    }
    fetchPreferredWorkers();
    // to sync favorited workers based on the list of workers
    // since "workers" array is constructed for each render, don't put as deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workers.length, workplaceId]);

  return (
    <>
      {workers.map((highRatingWorker, index) => (
        <WorkerDetails
          position={index + 1}
          total={workers.length}
          key={highRatingWorker.workerId}
          worker={highRatingWorker}
          workplaceId={workplaceId}
          isFavorited={favoritedWorkers[highRatingWorker.workerId]}
          onFavoritedChanged={(isAdded: boolean) =>
            handledFavoritedChanged(highRatingWorker.workerId, isAdded)
          }
          workplaceUserId={workplaceUserId}
          onboardingState={onboardingState}
          postShiftOptionSteps={postShiftOptionSteps}
          workplaceOnboardingSteps={workplaceOnboardingSteps}
          whoCalledMe={whoCalledMe}
        />
      ))}
    </>
  );
};
