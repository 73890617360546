import React, { useEffect, useState } from "react";
import request from "superagent";
import "./style.css";

import { getAuthHeader } from "../../utils/superagent";

const createApplicant = async ({ externalId, experimentId }) => {
  try {
    await request
      .post(`${global.api}/signup/applicant`)
      .set(await getAuthHeader())
      .send({ externalId, experimentId });
    window.location.replace(
      `https://www.clipboardhealth.com/signup-success/${experimentId}-a`,
    );
  } catch (err) {
    window.location.replace("https://www.clipboardhealth.com/signup-error");
  }
};

const AnimatedLoading = (props) => {
  const [loading, setLoading] = useState(false);
  const { experimentId = "", externalId = "" } = props.match.params;

  useEffect(() => {
    if (experimentId && externalId) {
      setLoading(true);
      createApplicant({ externalId, experimentId }).then(() => {
        setLoading(false);
      });
    }
  }, [experimentId, externalId]);

  const text = loading ? "Loading, please wait..." : "Successfully signed up.";

  return (
    <div id="loader-wraper">
      {/* Dotted below the Image */}
      <div className="spinner-wrap">
        <div className="imgWrap">
          <img src="/bunnyB.png" alt="image" />
        </div>
        <div className="loader loader2">Loading...</div>
        <p className="loading-text">{text}</p>
      </div>
    </div>
  );
};

export { AnimatedLoading };
