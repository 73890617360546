import { Modal } from "antd";
import { errorMessage } from "../../utils/errors";
import { StyledDivider } from "./styles";

export const handleWorkerRatingErrorMessageDisplay = (error: any) => {
  const isForbidden = error?.status === 403;
  return Modal.error({
    title: (
      <b>
        {isForbidden ? "This worker can not be rated" : "Error rating worker"}
      </b>
    ),
    content: (
      <div data-testid="worker-reviews-modal-error-content">
        <div>
          {errorMessage(
            error,
            "There is an error when creating rating performance.",
          )}
        </div>
        <StyledDivider />
        <div>
          To add this to your facility favorite please contact us at
          support@clipboardhealth.com
        </div>
      </div>
    ),
  });
};
