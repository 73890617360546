import { ShiftCategory } from "frontend-admin/src/api/workers";
import { WhoCalledRatingPerformanceModal } from "frontend-admin/src/components/WorkerRatingPerformanceModal/workerReview.types";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";
import { logEvent } from "frontend-admin/src/utils/segment/logEvents";
import { ShiftDetailsType } from "./WorkerDetails/workplaceWorker.types";

export type ActiveTab = "upcoming" | "inProgress" | "past" | "favorite";

export function getActiveTabName(
  activeTab: ShiftDetailsType | ShiftCategory,
): ActiveTab {
  switch (activeTab) {
    case ShiftDetailsType.INPROGRESS:
    case ShiftCategory.IN_PROGRESS:
      return "inProgress";

    case ShiftDetailsType.UPCOMING:
    case ShiftCategory.UPCOMING:
      return "upcoming";

    case ShiftDetailsType.PAST:
    case ShiftCategory.PAST:
      return "past";

    case ShiftCategory.FAVORITE:
      return "favorite";
  }
}

type CommonLogData = {
  workerId: string;
  workplaceId: string;
  workplaceUserId: string;
  activeTab?: ActiveTab;
  // we should standardize the location
  location: WhoCalledRatingPerformanceModal;
};

export function logChatOpen({
  workerId,
  workplaceId,
  workplaceUserId,
  activeTab,
  location,
}: CommonLogData) {
  logEvent(HCF_USER_EVENTS.CHAT_OPENED, {
    workerId,
    workplaceId,
    workplaceUserId,
    activeTab,
    location,
  });
}

export function logViewDocument({
  workerId,
  workplaceId,
  workplaceUserId,
  activeTab,
  location,
}: CommonLogData) {
  logEvent(HCF_USER_EVENTS.VIEW_DOCUMENT, {
    workerId,
    workplaceId,
    workplaceUserId,
    activeTab,
    location,
  });
}

export function logOpenWorkerReview({
  workerId,
  workplaceId,
  workplaceUserId,
  activeTab,
  location,
  shiftId,
}: CommonLogData & { shiftId?: string }) {
  logEvent(HCF_USER_EVENTS.OPEN_SHIFT_REVIEW, {
    workerId,
    workplaceId,
    workplaceUserId,
    activeTab,
    location,
    shiftId,
  });
}

export function logSearchWorker({
  actionBy,
  workplaceId,
  workplaceUserId,
  activeTab,
  location,
  searchTerm,
}) {
  logEvent(HCF_USER_EVENTS.SEARCH_WORKER, {
    actionBy,
    workplaceId,
    workplaceUserId,
    activeTab,
    location,
    searchTerm,
  });
}

export function logProfileNavigated({
  workerId,
  workplaceId,
  workplaceUserId,
  location,
  activeTab,
  shiftId,
}: CommonLogData & { shiftId?: string }) {
  logEvent(HCF_USER_EVENTS.WORKER_PROFILE_NAVIGATED, {
    workerId,
    workplaceId,
    workplaceUserId,
    location,
    activeTab,
    shiftId,
  });
}

export function logUnfavoriteWorker({
  workerId,
  workplaceId,
  location,
  activeTab,
}: Partial<CommonLogData>) {
  logEvent(HCF_USER_EVENTS.UNFAVORITE_WORKER, {
    workerId,
    workplaceId,
    location,
    activeTab,
  });
}
