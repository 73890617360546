export interface ChatMessage {
  data: string;
  customType: string;
  message: string;
}
export interface ChatChannel {
  url: string;
}

export enum MessageCustomTypes {
  SHIFT_REVIEW = "SHIFT_REVIEW",
  FCM_REQUEST = "FCM_REQUEST",
  FCM_APPROVAL = "FCM_APPROVAL",
  FCM_REJECT = "FCM_REJECT",
  INPUT_ETA = "INPUT_ETA",
  INPUT_ETA_SUBMITTED = "INPUT_ETA_SUBMITTED",
}
