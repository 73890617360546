import { FACILITY_ROLES } from "frontend-admin/src/constants/facility";

export const ROLE_DETAILS_LINK =
  "https://support.clipboardhealth.com/hc/en-us/articles/4809084632599";

export const ROLE_MAPPINGS = {
  ADM: {
    label: "Administrator",
    position: 4,
    id: FACILITY_ROLES.ADMIN,
  },
  SFT: {
    label: "Staffing Coordinator",
    position: 3,
    id: FACILITY_ROLES.SHIFT_MANAGEMENT,
  },
  INV: {
    label: "Billing Manager",
    position: 2,
    id: FACILITY_ROLES.INVOICES,
  },
  DMT: {
    label: "Supervisor",
    position: 1,
    id: FACILITY_ROLES.DOCUMENTS,
  },
};

export enum TAB_STATE {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum LOADING_STATE {
  ACTIVATE_OR_DEACTIVATE = "activate_or_deactivate",
  ADD_OR_EDIT = "add_or_edit",
}

export const NO_RESULTS_MESSAGE = "No users match your search.";

export const MODAL_BUTTON_TEXT = (activeTab: TAB_STATE) => {
  const predicate = activeTab === TAB_STATE.INACTIVE;
  const ok = predicate ? "Activate" : "Deactivate";

  return {
    ok: `${ok} account`,
    cancel: `Cancel`,
  };
};

export enum PROCESS_MODAL_STATE {
  off = "off",
  edit = "edit",
  add = "add",
}

export const EXISTING_USER_WARNING =
  "A team member with this email address is already linked to another facility. Do you want to link them to this facility as well?";

export enum FACILITY_PERMISSION_LABELS {
  MANAGE_INVOICES = "Can view and manage invoices",
  ACCESS_CONTRACT_TERMS = "Can view contract terms",
  POST_SHIFT_PERMISSION = "Can post shifts",
  CANCEL_SHIFT_PERMISSION = "Can cancel shifts",
  EDIT_SHIFT_RATE = "Can edit shift rate",
}
