import request from "superagent";
import { logErrors } from "../../components/errorBoundary/api";
import Apps from "../../constants/Apps";
import { getAuthHeader } from "../../utils/superagent";
import { WORKER_RETRY_COUNT, WORKER_TIMEOUT_DURATION } from "../constants";
import WorkerType from "../interfaces/WorkerType";
import WorkPlaceType from "../interfaces/WorkPlaceType";

const fetchWorkerAndWorkplaceTypes = async (): Promise<
  | {
      workerTypes: WorkerType[];
      workPlaceTypes: WorkPlaceType[];
    }
  | undefined
> => {
  try {
    const { body } = await request
      .get(`${process.env.REACT_APP_BASE_API_URL}/workerAndWorkPlaceTypes`)
      .set(await getAuthHeader())
      .send()
      .timeout(WORKER_TIMEOUT_DURATION)
      .retry(WORKER_RETRY_COUNT);
    return body.data;
  } catch (error) {
    const { message = "", stack = "" } = error as Error;
    logErrors({
      app: Apps.Admin,
      message: `Unable to fetch worker and workplace types - ${message}`,
      stack,
    });
  }
};

export { fetchWorkerAndWorkplaceTypes };
