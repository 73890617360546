import { Button } from "frontend-admin/src/designsystem/Button/Button";
import { VerticalSpacing } from "frontend-admin/src/designsystem/VerticalSpacing/VerticalSpacing";
import { SuccessFooter, SuccessImage, SuccessContent } from "./styles";
import successImage from "./success.png";
import tipImage from "./tip.png";

interface SuccessScreenProps {
  onLoadMore: () => void;
  onStartAgain: () => void;
  isReviewedAll: boolean;
}

export const SuccessScreen: React.FC<SuccessScreenProps> = ({
  onLoadMore,
  onStartAgain,
  isReviewedAll,
}) => {
  return (
    <SuccessContent>
      {isReviewedAll ? (
        <>
          <SuccessImage src={successImage} data-testid="success-image" />
          <div>
            Thank you for submitting worker reviews! The more ratings you give,
            <br />
            the more likely you are to be connected with highly-rated
            professionals
          </div>
        </>
      ) : (
        <>
          <SuccessImage src={tipImage} data-testid="reminder-image" />
          <div>
            Sorry to see that you have not reviewed all the workers in this
            list.
            <br />
            The more ratings you give, the more likely you are to be connected
            with highly-rated professionals.
          </div>
          <VerticalSpacing size="lg" />
        </>
      )}

      <SuccessFooter>
        {!isReviewedAll && (
          <Button
            variant="secondary"
            onClick={onStartAgain}
            data-testid="worker-carousel-start-again"
          >
            Review skipped workers
          </Button>
        )}
        <Button
          variant="primary"
          onClick={onLoadMore}
          data-testid="worker-carousel-review-more"
        >
          Review other workers
        </Button>
      </SuccessFooter>
    </SuccessContent>
  );
};
