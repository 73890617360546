import { message } from "antd";

export const errorMessage = (error, msg = "request failed") => {
  if (error.response?.body?.message) {
    return error.response.body.message;
  } else if (error.response?.text) {
    return error.response.text;
  } else if (error.message) {
    return error.message;
  }
  return msg;
};

export const handleApiError = (error) => {
  message.error(errorMessage(error));
};

export const isTruthy = (param) =>
  param && ["true", true, "1", 1].includes(param);

export const parseObjectErrorResponse = (error) => {
  if (typeof error === "object") return error;

  try {
    return JSON.parse(error);
  } catch (e) {
    return {};
  }
};
