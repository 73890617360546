import { FormInstance, message } from "antd";
import {
  FacilityRole,
  fetchFacilityRoles,
} from "frontend-admin/src/api/facilityRoles";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";
import {
  fetchUsers,
  findUserWithEmail,
  removeUser,
  unLinkUser,
  updateUserInfo,
} from "frontend-admin/src/containers/facilityUser/api";
import {
  FacilityFlags,
  FacilityNotification,
  fillDefaultAlertSettings,
} from "frontend-admin/src/containers/facilityUser/helpers";
import { createNotifyRoles } from "frontend-admin/src/containers/myAccount/Notification";
import {
  fetchLDFlagValues,
  getSortedRoles,
} from "frontend-admin/src/containers/myAccount/utilities";
import {
  generateAddEditLogData,
  generateLogData,
  generateNotificationLogData,
} from "frontend-admin/src/containers/teamMembers/logs";
import { SessionType } from "frontend-admin/src/modules/interface";
import { initialState } from "frontend-admin/src/modules/myAccount";
import { MYACCOUNT_ACTIONS } from "frontend-admin/src/modules/myAccount/actions";
import {
  IAlertSetting,
  IEmailModalData,
  IFormData,
  IFormValue,
  IUser,
  TypeGranularControl,
} from "frontend-admin/src/modules/myAccount/types";
import { logout } from "frontend-admin/src/modules/session";
import store from "frontend-admin/src/store";
import { errorMessage } from "frontend-admin/src/utils/errors";
import { useLDClient } from "launchdarkly-react-client-sdk";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import { Dispatch } from "redux";
import { ITeamMember } from "../../containers/teamMembers/useTeamMembersAPIHook";
import { logEvent } from "../../utils/segment/logEvents";

const waitForAnalyticEventsToComplete = (delay = 500) =>
  new Promise((res) => setTimeout(res, delay));

export const toggleLoading = (payload: boolean) => ({
  type: MYACCOUNT_ACTIONS.LOADING,
  payload,
});

export const setUser = (userData: IUser) => ({
  type: MYACCOUNT_ACTIONS.SET_USER,
  payload: userData,
});

export const fetchUser = async (
  dispatch: Dispatch,
  userId: string,
  email: string,
) => {
  const [currentUser] = await fetchUsers({
    facilityId: userId,
    isArchived: false,
    filter: {
      email,
    },
  });

  dispatch(setUser(currentUser as IUser));
};

export const fetchFlags = async (
  dispatch: Dispatch,
  ldClient: ReturnType<typeof useLDClient>,
  profile: SessionType["session"]["profile"],
) => {
  const flags = await fetchLDFlagValues(ldClient, profile);
  if (flags) {
    dispatch(setFlags(flags));
  }
};

export const handleFormToggle = (payload: boolean) => ({
  type: MYACCOUNT_ACTIONS.TOGGLE_FORM,
  payload,
});

export const setShouldLogout = (payload: boolean) => ({
  type: MYACCOUNT_ACTIONS.SET_LOGOUT,
  payload,
});

export const setFormData = (dispatch: Dispatch, data?: IFormValue) => {
  const { myAccount, session } = store.getState() as SessionType;
  const { user: sessionUser, profile } = session;
  const { user, existingUser, flags } = myAccount;

  if (!(sessionUser && session)) {
    return;
  }

  const access = existingUser?.roles || user?.roles || sessionUser.access;

  let userInfo: Partial<IFormValue> = {};
  if (data && user) {
    userInfo = omitBy(data, (value, key) => isEqual(user[key], value));
  } else {
    if (data) {
      userInfo = data;
    }
  }

  const alertSettings = existingUser?.alertSettings ||
    user?.alertSettings || { EMAIL: [], SMS: [] };
  const granularControl = fillDefaultAlertSettings(
    alertSettings,
    access,
    flags,
    user?.permissions,
  ) as TypeGranularControl;

  let formData: IFormData = {
    facilityId: profile.userId,
    firebaseId: user?.firebaseId || existingUser?.firebaseId || "",
    performedBy: session.user?._id ?? "",
    userId: (user?._id || session.user?._id) ?? "",
    userInfo: { ...userInfo, granularControl },
  };

  const userInfoNotifyData = createNotifyRoles({
    ...formData.userInfo,
    ...{ notify: user?.notify },
  });
  formData = {
    ...formData,
    userInfo: { ...userInfoNotifyData },
  };

  dispatch({
    type: MYACCOUNT_ACTIONS.SET_FORM_DATA,
    payload: formData,
  });
};

export const submitForm = async (dispatch: Dispatch, data?: IFormValue) => {
  const {
    session: { user: sessionUser, profile, admin, type },
  } = store.getState() as SessionType;
  if (data) {
    dispatch(toggleLoading(true));
  }
  setFormData(dispatch, data);

  const { myAccount } = store.getState() as SessionType;
  const { formData, shouldLogout } = myAccount;

  const updateProperties = omit(
    formData?.userInfo ?? {},
    "granularControl",
    "alertSettings",
    "notify",
  );
  const eventData = generateAddEditLogData(
    formData?.userInfo as ITeamMember,
    sessionUser,
    admin,
    type,
    profile.userId,
    myAccount.user,
  );

  try {
    if (!sessionUser) {
      return;
    }
    await updateUserInfo(formData);
    if (Object.keys(updateProperties).length) {
      logEvent(HCF_USER_EVENTS.EDIT_MY_ACCOUNT_INFORMATION, {
        ...eventData,
        action: "edit",
        success: true,
      });
    }
    fetchUser(dispatch, profile.userId, sessionUser?.email);

    if (shouldLogout) {
      logout();
      window.location.reload();
    }
  } catch (err) {
    message.error(errorMessage(err));
    if (Object.keys(updateProperties).length) {
      logEvent(HCF_USER_EVENTS.EDIT_MY_ACCOUNT_INFORMATION, {
        ...eventData,
        action: "edit",
        success: false,
      });
    }
  } finally {
    if (!shouldLogout) {
      dispatch(toggleLoading(false));
      dispatch(handleFormToggle(false));
    }
  }
};

export const unlinkUser = async (dispatch: Dispatch) => {
  const { myAccount, session } = store.getState() as SessionType;
  const { admin, userId } = session;
  const { user, deactivateModal } = myAccount;
  const {
    type,
    profile: { userId: workplaceId },
  } = session;

  const event = generateLogData(
    userId,
    admin,
    type,
    workplaceId,
    user?._id || "",
  );
  try {
    await unLinkUser({
      userId: user?._id,
      facilityId: session.profile.userId,
    });
    logEvent(HCF_USER_EVENTS.UNLINK_TEAM_MEMBER, {
      ...event,
      success: true,
    });
    dispatch(toggleDeactivateModal(!deactivateModal));
    await waitForAnalyticEventsToComplete();

    logout();
    window.location.reload();
  } catch (err) {
    message.error(err);
    logEvent(HCF_USER_EVENTS.UNLINK_TEAM_MEMBER, {
      ...event,
      success: false,
    });
  }
};

export const deactivateUser = async (dispatch: Dispatch) => {
  const { myAccount, session } = store.getState() as SessionType;
  const { admin, userId } = session;
  const { user, deactivateModal } = myAccount;
  const {
    type,
    profile: { userId: workplaceId },
  } = session;

  const event = generateLogData(
    userId,
    admin,
    type,
    workplaceId,
    user?._id || "",
  );
  try {
    await removeUser({
      userId: user?._id,
      firebaseId: user?.firebaseId,
      performedBy: admin || user,
      facilityId: userId,
    });
    logEvent(HCF_USER_EVENTS.DEACTIVATE_TEAM_MEMBER, {
      ...event,
      success: true,
    });
    dispatch(toggleDeactivateModal(!deactivateModal));

    await waitForAnalyticEventsToComplete();
    logout();
    window.location.reload();
  } catch (err) {
    message.error(err);
    logEvent(HCF_USER_EVENTS.DEACTIVATE_TEAM_MEMBER, {
      ...event,
      success: true,
    });
  }
};

export const toggleModal = (payload: boolean) => ({
  type: MYACCOUNT_ACTIONS.TOGGLE_MODAL,
  payload,
});

export const setModalData = (
  type: string,
  alert: IAlertSetting,
  property: string,
  originalValue: string,
) => ({
  type: MYACCOUNT_ACTIONS.SET_MODAL_DATA,
  payload: {
    type,
    alert,
    property,
    originalValue,
  },
});

export const pushNotification = async (pushEnabled: boolean) => {
  const { braze } = window as any;
  if (!braze.isPushBlocked() || !pushEnabled) {
    if (pushEnabled) {
      if (!braze.isPushPermissionGranted() && !braze.isPushBlocked()) {
        braze.requestPushPermission();
      }
      braze
        .getUser()
        .setPushNotificationSubscriptionType(
          braze.User.NotificationSubscriptionTypes.OPTED_IN,
        );
    } else if (!pushEnabled) {
      braze
        .getUser()
        .setPushNotificationSubscriptionType(
          braze.User.NotificationSubscriptionTypes.UNSUBSCRIBED,
        );
    }
  }
  return braze.isPushBlocked() as boolean;
};

export const updateNotification = async (
  dispatch: Dispatch,
  value: string,
  alertType: string,
  property: string,
  member?: IUser,
) => {
  const { myAccount, session } = store.getState() as SessionType;
  const { user: sessionUser, profile, admin, type } = session;
  const { user: loggedInUser, showModal, flags } = myAccount;
  const user = member || loggedInUser;

  if (!user) {
    return;
  }
  let alertSettingsClone = { ...user.alertSettings } || { EMAIL: [], SMS: [] };
  const notifyClone = { ...user.notify };
  const existingData = { ...alertSettingsClone[alertType]?.[property] };

  // Generate alertSettings for preference that aren't part of the user payload.
  if (
    !(alertSettingsClone[alertType]?.[property] || alertType === "WEB_PUSH")
  ) {
    const defaultAlertSettings = fillDefaultAlertSettings(
      user.alertSettings,
      user.roles,
      flags,
    );
    const alertSettingToUpdate = defaultAlertSettings[alertType].find(
      ({ action }) => action === property,
    );
    alertSettingsClone = {
      ...user.alertSettings,
      [alertType]: {
        ...user.alertSettings?.[alertType],
        [property]: alertSettingToUpdate.default,
      },
    };
  }

  if (alertType === "WEB_PUSH") {
    const pushValue = value === "on";
    notifyClone[alertType] = { All: pushValue };
    const isPushBlocked = await pushNotification(pushValue);
    if (isPushBlocked) {
      return;
    }
  } else {
    const propertyValue = alertSettingsClone[alertType][property];

    propertyValue.enabled = value !== "off";
    propertyValue.batch = value === "daily";

    if (value === "daily") {
      dispatch(toggleModal(!showModal));
      dispatch(
        setModalData(
          alertType,
          propertyValue,
          property,
          existingData.batch ? "Daily" : existingData.enabled ? "On" : "Off",
        ),
      );
    }
  }

  let existingValue = existingData.batch
    ? "Daily"
    : existingData.enabled
    ? "On"
    : "Off";
  if (property === "web_push")
    existingValue = user.notify?.WEB_PUSH?.All ? "On" : "Off";
  const eventData = generateNotificationLogData(
    property,
    existingValue,
    value,
    sessionUser,
    admin,
    type,
    profile.userId,
    user,
  );

  dispatch(
    setUser({
      ...user,
      alertSettings: alertSettingsClone,
      notify: notifyClone,
    }),
  );

  if (value !== "daily") {
    await submitForm(dispatch);
    logEvent(HCF_USER_EVENTS.EDIT_MY_NOTIFICATION_PREFERENCES, {
      ...eventData,
    });
    if (sessionUser) {
      fetchUser(dispatch, profile.userId, sessionUser.email);
    }
  }
};

export const updateBatchNotification = async (
  dispatch: Dispatch,
  batchTime: { hour: number; meridiem: string },
) => {
  const { myAccount, session } = store.getState() as SessionType;
  const { hour, meridiem } = batchTime;
  const { user, modalData } = myAccount;
  const { user: sessionUser, profile, admin, type } = session;
  if (!(user && modalData && sessionUser)) {
    return;
  }
  const alertSettingsClone = { ...user.alertSettings };

  alertSettingsClone[modalData.type][modalData.property].time =
    meridiem === "pm" ? hour + 12 : hour;

  dispatch(setUser({ ...user, alertSettings: alertSettingsClone }));
  await submitForm(dispatch);
  const eventData = generateNotificationLogData(
    modalData.property,
    modalData.originalValue,
    "daily",
    sessionUser,
    admin,
    type,
    profile.userId,
    user,
  );
  logEvent(HCF_USER_EVENTS.EDIT_MY_NOTIFICATION_PREFERENCES, {
    ...eventData,
  });
  dispatch(toggleModal(false));

  fetchUser(dispatch, profile.userId, sessionUser.email);
};

export const setFormReference = (formReference: FormInstance<IFormValue>) => ({
  type: MYACCOUNT_ACTIONS.SET_FORM_REFERENCE,
  payload: formReference,
});

export const toggleEmailModal = (payload: boolean) => ({
  type: MYACCOUNT_ACTIONS.TOGGLE_EMAIL_MODAL,
  payload,
});

export const setEmailModalData = (payload: IEmailModalData) => ({
  type: MYACCOUNT_ACTIONS.SET_EMAIL_MODAL_DATA,
  payload,
});

export const setExistingUserData = (payload: IUser | undefined) => ({
  type: MYACCOUNT_ACTIONS.SET_EXISTING_USER,
  payload,
});

export const fetchExistingUser = async (
  dispatch: Dispatch,
  email: string,
  userId: string,
) => {
  const existingUser: IUser = await findUserWithEmail({
    facilityId: userId,
    email,
  });
  dispatch(setExistingUserData(existingUser));
  if (existingUser) {
    dispatch(setUserRoles(existingUser.roles));
  }
};

export const setRoles = (payload: FacilityRole[]) => ({
  type: MYACCOUNT_ACTIONS.SET_ROLES,
  payload,
});

export const fetchAllRoles = async (dispatch: Dispatch) => {
  const roles = await fetchFacilityRoles();
  dispatch(setRoles(roles));
  const defaultUserRole = getSortedRoles(roles);
  dispatch(setUserRoles(defaultUserRole.map(({ _id }) => _id)));
};

export const setUserRoles = (payload: string[]) => ({
  type: MYACCOUNT_ACTIONS.SET_USER_ROLES,
  payload,
});

export const setRedirectUrl = (payload: string) => ({
  type: MYACCOUNT_ACTIONS.SET_REDIRECT_URL,
  payload,
});

export const setFlags = (flags: FacilityFlags) => ({
  type: MYACCOUNT_ACTIONS.SET_FLAGS,
  payload: flags,
});

export const toggleDeactivateModal = (payload: boolean) => ({
  type: MYACCOUNT_ACTIONS.TOGGLE_DEACTIVATE_MODAL,
  payload,
});

export const configureAlertSettings = (dispatch: Dispatch, member?: IUser) => {
  const { user: loggedInUser, flags } = store.getState().myAccount;
  const user = member || loggedInUser;
  if (!(user?.alertSettings && flags)) {
    return;
  }

  const reduce = (
    acc: FacilityNotification[],
    facilityNotification: FacilityNotification,
  ) => {
    acc[facilityNotification.action] = facilityNotification;
    return acc;
  };

  const defaultAlertSettings = fillDefaultAlertSettings(
    user?.alertSettings,
    user?.roles,
    flags,
    user.permissions,
  );

  const alertSetting = {
    EMAIL: defaultAlertSettings["EMAIL"].reduce(
      reduce,
      {} as FacilityNotification[],
    ),
    SMS: defaultAlertSettings["SMS"].reduce(
      reduce,
      {} as FacilityNotification[],
    ),
  };
  dispatch(setAlertSettings(alertSetting));
};

export const setAlertSettings = (
  payload: Record<string, FacilityNotification[]>,
) => ({
  type: MYACCOUNT_ACTIONS.SET_ALERT_SETTINGS,
  payload,
});

export const resetState = () => ({
  type: MYACCOUNT_ACTIONS.RESET_STATE,
  payload: initialState,
});
