import { useState, useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "../constants/FEATURE_FLAGS";

interface IUseNotificationCenterFlag {
  isAdmin: boolean;
  facilityId: string;
  facilityMsa: string;
}

/**
 * Checks if the "notification center" feature is enabled.
 */
export const useNotificationCenterFlag = ({
  isAdmin,
  facilityId,
  facilityMsa,
}: IUseNotificationCenterFlag): {
  ffStatus: boolean;
  showNotificationCenter: boolean;
} => {
  const ldClient = useLDClient();
  const [ffStatus, setFFStatus] = useState<boolean>(false);
  const [showNotificationCenter, setShowNotificationCenter] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!ldClient) return;
      await ldClient.identify({
        key: facilityId,
        custom: { facilitymsa: facilityMsa, msa: facilityMsa },
      });
      const featureFlag =
        !!ldClient.allFlags()?.[FeatureFlag.NOTIFICATION_CENTER_ACTIVE];

      setFFStatus(featureFlag);
      setShowNotificationCenter(featureFlag && !isAdmin);
    })();
  }, [facilityId, facilityMsa, isAdmin, ldClient]);

  return { ffStatus, showNotificationCenter };
};
