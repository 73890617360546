export const INCREMENT_VC = "INCREMENT_VC";
export const INCREMENT_TC = "INCREMENT_TC";
export const RESET_TC = "RESET_TC";
export const RESET_VC = "RESET_VC";
export const SHOW_WORKER_REVIEW_MODAL = "SHOW_WORKER_REVIEW_MODAL";
export const HIDE_WORKER_REVIEW_MODAL = "HIDE_WORKER_REVIEW_MODAL";
export const SHOW_SHIFT_TIMECARD_MODAL = "SHOW_SHIFT_TIMECARD_MODAL";
export const HIDE_SHIFT_TIMECARD_MODAL = "HIDE_SHIFT_TIMECARD_MODAL";

const initialState = {
  workerReview: {
    workerId: null,
    workplaceUserId: null,
    workplaceId: null,
    workerName: null,
    successCallback: null,
    showModal: false,
    whoCalledMe: null,
  },
  timecardsListPreview: [],
  verifiedCounter: 0,
  timecardCounter: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_VC:
      return {
        ...state,
        verifiedCounter: state.verifiedCounter + 1,
      };

    case INCREMENT_TC:
      return {
        ...state,
        timecardCounter: state.timecardCounter + 1,
      };

    case RESET_TC:
      return {
        ...state,
        timecardCounter: 0,
      };
    case RESET_VC:
      return {
        ...state,
        verifiedCounter: 0,
      };
    case SHOW_WORKER_REVIEW_MODAL:
      return {
        ...state,
        workerReview: {
          ...state.workerReview,
          workerId: action.workerId,
          workplaceUserId: action.workplaceUserId,
          workplaceId: action.workplaceId,
          workerName: action.workerName,
          successCallback: action.successCallback,
          showModal: true,
          trackData: action.trackData,
          whoCalledMe: action.whoCalledMe,
        },
      };
    case HIDE_WORKER_REVIEW_MODAL:
      return {
        ...state,
        workerReview: {
          ...initialState.workerReview,
          showModal: false,
        },
      };
    case SHOW_SHIFT_TIMECARD_MODAL: {
      return {
        ...state,
        timecardsListPreview: action.timecards,
      };
    }
    case HIDE_SHIFT_TIMECARD_MODAL: {
      return {
        ...state,
        timecardsListPreview: [],
      };
    }
    default:
      return state;
  }
};

export const incrementVC = () => {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_VC,
    });
  };
};

export const incrementTC = () => {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_TC,
    });
  };
};

export const resetTC = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_TC,
    });
  };
};

export const resetVC = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_VC,
    });
  };
};
