import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ExclusionForList,
  fetchExclusion,
} from "frontend-admin/src/api/workerReview";
import { LONG_STALE_TIME } from "frontend-admin/src/constants/query";

export function useWorkerExclusions(workplaceId: string, workerIds: string[]) {
  const queryClient = useQueryClient();
  const queryKey = [
    "worker-exclusions",
    workplaceId.toString(),
    workerIds.join(","),
  ];

  const { isLoading: exclusionsLoading, data: exclusionsData } = useQuery({
    queryKey,
    queryFn: async () => {
      const resp = await fetchExclusion({
        facilityId: workplaceId,
        agentIds: workerIds,
      });
      return resp.reduce((acc, exclusion) => {
        if (!exclusion.agent.userId) {
          return acc;
        }

        acc[exclusion.agent.userId] = exclusion;
        return acc;
      }, {} as Record<string, ExclusionForList>);
    },
    staleTime: LONG_STALE_TIME,
    enabled: workerIds.length > 0,
  });

  const invalidateCache = () => {
    queryClient.removeQueries(queryKey);
  };

  return {
    data: exclusionsData ?? {},
    isLoading: exclusionsLoading,
    invalidateCache,
  };
}
