import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Avatar, Col, Dropdown, Menu, Modal, Row, notification } from "antd";
import { FacilityOnboardingStep } from "frontend-admin/src/api/facility";
import {
  WorkerDirectory,
  addPreferredWorkers,
  deletePreferredWorkers,
} from "frontend-admin/src/api/workers";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";
import { LinkButtonWithIcon } from "frontend-admin/src/containers/facilityOnboarding/FacilityOnboarding.styles";
import { ListWorkerReviews } from "frontend-admin/src/containers/workplace/WorkerDetails/components/ListWorkerReviews";
import { ReviewListTitle } from "frontend-admin/src/containers/workplace/WorkerDetails/components/ListWorkerReviews.styles";
import { Button } from "frontend-admin/src/designsystem/Button/Button";
import { Popover } from "frontend-admin/src/designsystem/Popover/Popover";
import { VerticalSpacing } from "frontend-admin/src/designsystem/VerticalSpacing/VerticalSpacing";
import { SessionType } from "frontend-admin/src/modules/interface";
import { UPDATE_PROFILE } from "frontend-admin/src/modules/session";
import { logEvent } from "frontend-admin/src/utils/segment/logEvents";
import { MouseEventHandler, useState } from "react";
import { FaHeart, FaRegHeart, FaStar } from "react-icons/fa";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "react-use";
import {
  ButtonIcon,
  List,
  QualificationText,
  Worker,
  WorkerActions,
  WorkerAvatar,
  WorkerName,
  WorkerRate,
  WorkerRatingSection,
} from "./WorkerDetails.styles";
import { AdminActionDisabledTooltip } from "frontend-admin/src/components/AdminActionDisabledTooltip/AdminActionDisabledTooltip";
import { getLocation } from "frontend-admin/src/utils/routes";
import { useRestrictedSignedProfile } from "frontend-admin/src/hooks/useRestrictedSignedProfile";
import { RestrictedSignedProfile } from "frontend-admin/src/components/RestrictedSignedProfile/RestrictedSignedProfile";
import { WorkplaceContractState } from "frontend-admin/src/modules/workplaceContract";

const WORKER_DIRECTORY_ITEM = {
  FAVORITED: "favorited",
  UNFAVORITED: "unfavorited",
  MORE_OPTIONS: "more options",
  PLAY_VIDEO: "play video",
  ITEM_CLICK: "worker tile click",
  INFO_POPOVER_CLOSED: "info popover closed",
  VIEW_REVIEWS_OPENED: "view reviews opened",
  VIEW_REVIEWS_CLOSED: "view reviews closed",
  VIEW_REVIEWS_READ_MORE: "view reviews read more",
} as const;

type WorkerDirectoryActionKeys = keyof typeof WORKER_DIRECTORY_ITEM;
type WorkerDirectoryActionValues =
  typeof WORKER_DIRECTORY_ITEM[WorkerDirectoryActionKeys];

interface WorkerDetailsProps {
  position: number;
  total: number;
  worker: WorkerDirectory;
  workplaceId: string;
  isFavorited: boolean;
  onFavoritedChanged: (isAdded: boolean) => void;
  workplaceUserId?: string;
  workplaceOnboardingSteps: Array<"favorites" | "shifts">;
  postShiftOptionSteps: Array<"block-shifts" | "per-diem">;
  onboardingState?: FacilityOnboardingStep;
  whoCalledMe: "FacilityOnboardingPage" | "DirectoryWorkerPage";
}

const PAGE_TO_NAME = {
  FacilityOnboardingPage: "Get Started",
  DirectoryWorkerPage: "Worker Directory",
} as const;

const INFO_POPOVER_WORKER_DIRECTORY_ITEM = "infoPopoverWorkerDirectoryItem";

export const WorkerDetails = ({
  position,
  total,
  worker,
  workplaceId,
  isFavorited,
  onFavoritedChanged,
  workplaceUserId,
  workplaceOnboardingSteps,
  postShiftOptionSteps,
  onboardingState,
  whoCalledMe,
}: WorkerDetailsProps): JSX.Element => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showInfoPopover, setShowInfoPopover] = useState(true);
  const [infoPopoverWorkerDirectoryItem, setInfoPopoverWorkerDirectoryItem] =
    useLocalStorage(
      `${INFO_POPOVER_WORKER_DIRECTORY_ITEM}_${workplaceId}_${workplaceUserId}`,
      false,
    );
  const dispatch = useDispatch();
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const { isSignedProfile, openContractModal } = useRestrictedSignedProfile();
  const history = useHistory();
  const { profile, admin } = useSelector((state: SessionType) => state.session);

  const {
    mutateAsync: deletePreferredWorkersApi,
    isLoading: deletePreferredWorkersLoading,
  } = useMutation({
    mutationFn: deletePreferredWorkers,
  });
  const {
    mutateAsync: addPreferredWorkersApi,
    isLoading: addPreferredWorkersLoading,
  } = useMutation({
    mutationFn: addPreferredWorkers,
  });

  const logWorkerDirectoryItemEvents = (
    action: WorkerDirectoryActionValues,
    subAction?: string,
    reviewId?: string,
  ) => {
    if (admin) {
      return;
    }

    const commonAttributes = {
      workplaceOnboardingSteps,
      postShiftOptionSteps,
      workplaceId,
      workplaceUserId,
      onboardingState,
      workerId: worker.workerId,
      itemPosition: `${position}/${total}`,
      videoUrl: worker?.profileVideoUrl,
      previousLocation: whoCalledMe,
    };

    logEvent(HCF_USER_EVENTS.WORKER_DIRECTORY_ITEM, {
      ...commonAttributes,
      action,
      subAction,
      reviewId,
    });
  };

  const navigateToFavoritedPage: MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.preventDefault();
    notification.destroy();
    history.push(
      getLocation("workplaceWorkers", {
        queryParams: { category: "favorite" },
      }),
    );
  };

  const navigateToWorkerBlockShifts = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    notification.destroy();
    history.push(getLocation("workplaceBlockShifts"), {
      workerId: worker.workerId,
      previousLocation: "DirectoryWorkerItem",
    });
  };

  const toggleFavorited: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (isFavorited) {
      removeFavoritedWorker();
    } else {
      addFavoritedWorker();
    }
  };

  const addFavoritedWorker = async () => {
    await addPreferredWorkersApi({
      workerId: worker.workerId,
      workplaceId,
      reason: "FAVORITE",
    });
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.FAVORITED);
    onFavoritedChanged(true);
    if (profile.onboardingSteps?.favoriteWorkers === false) {
      dispatch({
        type: UPDATE_PROFILE,
        data: {
          ...profile,
          onboardingSteps: {
            ...profile.onboardingSteps,
            favoriteWorkers: true,
          },
        },
      });
    }

    notification.success({
      key: "added-to-favorites-team",
      message: (
        <span>
          Added to your Favorites Team.
          <br />
          This worker now gets early access to your shifts.
        </span>
      ),

      btn: (
        <Button variant="primary" onClick={navigateToFavoritedPage}>
          View all Favorites
        </Button>
      ),
      getContainer: () =>
        document.querySelector("#content-box") as HTMLDivElement,
    });
  };

  const removeFavoritedWorker = async () => {
    await deletePreferredWorkersApi({ workerId: worker.workerId, workplaceId });
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.UNFAVORITED);
    onFavoritedChanged(false);

    notification.success({
      key: "removed-favorites-team",
      message: <>Removed from your Favorites Team.</>,
    });
  };

  const handleWorkerClicked = () => {
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.ITEM_CLICK);
    history.push(
      getLocation("workersDirectoryWorkerDetails", {
        pathParams: { workerId: worker.workerId },
      }),
      { previousLocation: whoCalledMe },
    );
  };

  const handleMenuItemClick = ({
    key,
    domEvent,
  }: {
    key: string;
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
  }) => {
    domEvent.stopPropagation();
    if (key === "block-shifts") {
      if (isSignedProfile) {
        navigateToWorkerBlockShifts(domEvent);
        logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.MORE_OPTIONS, key);
      } else {
        openContractModal({
          triggeredBy: "offer a block of shifts",
          triggeredFrom: PAGE_TO_NAME[whoCalledMe],
        });
      }
    } else {
      history.push(
        getLocation("workersDirectoryWorkerDetails", {
          pathParams: {
            workerId: worker.workerId,
          },
        }),
        {
          previousLocation: whoCalledMe,
        },
      );
      logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.MORE_OPTIONS, key);
    }
  };

  const handleViewWorkerVideo: MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.stopPropagation();
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.PLAY_VIDEO);
    setShowVideoModal(true);
  };

  const handleCloseInfoPopover = () => {
    setShowInfoPopover(false);
    setInfoPopoverWorkerDirectoryItem(true);
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.INFO_POPOVER_CLOSED);
  };

  const handleViewRatingsClick: MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    // since the rating component is inside the clickable worker item, we need to stop the event from propagating to the worker item
    event.stopPropagation();
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.VIEW_REVIEWS_OPENED);
    setShowReviewsModal(true);
  };

  const handleViewRatingsClosed = () => {
    logWorkerDirectoryItemEvents(WORKER_DIRECTORY_ITEM.VIEW_REVIEWS_CLOSED);
    setShowReviewsModal(false);
  };

  const handleReadMore = (reviewId: string) => {
    logWorkerDirectoryItemEvents(
      WORKER_DIRECTORY_ITEM.VIEW_REVIEWS_READ_MORE,
      undefined,
      reviewId,
    );
  };

  const renderRatingsModalHeader = (averageRating, totalReviews) => {
    return (
      <ReviewListTitle>
        <FaStar /> <span>{averageRating.toFixed(1)} Average</span>
        <span>
          ({totalReviews !== 1 ? `${totalReviews ?? 0} Reviews` : "1 Review"})
        </span>
      </ReviewListTitle>
    );
  };

  return (
    <>
      <Worker onClick={handleWorkerClicked}>
        <WorkerAvatar blur={!isSignedProfile}>
          <Avatar
            size={96}
            icon={<UserOutlined />}
            src={worker.profileImageUrl}
          />
          {worker.profileVideoUrl && (
            <RestrictedSignedProfile
              action="play video"
              page="Worker Directory"
            >
              <LinkButtonWithIcon
                underline={false}
                color="primary"
                onClick={handleViewWorkerVideo}
              >
                <MdOutlineSlowMotionVideo />
                Play video
              </LinkButtonWithIcon>
            </RestrictedSignedProfile>
          )}
        </WorkerAvatar>
        <Row align="top" wrap={false}>
          <Col flex="1">
            <WorkerName>
              {isSignedProfile ? worker.name : "Anonymous"}
            </WorkerName>
            <QualificationText>
              {worker.matchingFacilityQualifications.join(", ")}
            </QualificationText>
            <RestrictedSignedProfile
              action="view worker's reviews"
              page="Worker Directory"
            >
              <WorkerRatingSection onClick={handleViewRatingsClick}>
                {worker.ratingsCount !== 0 && (
                  <WorkerRate allowHalf value={worker.averageRating} disabled />
                )}
                {worker.ratingsCount !== 1
                  ? `${
                      worker.ratingsCount
                        ? `${worker.ratingsCount} reviews`
                        : "No reviews yet"
                    }`
                  : "1 review"}
              </WorkerRatingSection>
            </RestrictedSignedProfile>
            <VerticalSpacing size="sm" />
            <List>
              <li>
                {worker.completedShiftsCount !== 1
                  ? `${worker.completedShiftsCount ?? 0} shifts `
                  : "1 shift "}
                completed
              </li>
              <li>
                {worker.attendanceRate > 0
                  ? `${worker.attendanceRate}% `
                  : "N/A "}
                attendance rate
              </li>
            </List>
          </Col>

          <WorkerActions>
            <Popover
              variant="primary"
              content="Mark your preferred workers with a heart to give them first priority to book your shifts"
              open={showInfoPopover}
              placement="right"
              showPopover={
                history.location.pathname === "/workplace/workers-directory" &&
                position === 1 &&
                infoPopoverWorkerDirectoryItem === false &&
                profile.onboardingSteps?.favoriteWorkers === false &&
                !admin
              }
              onHandleClose={handleCloseInfoPopover}
            >
              <AdminActionDisabledTooltip disabled={admin}>
                <RestrictedSignedProfile
                  action={
                    isFavorited ? "unfavorite a worker" : "favorite a worker"
                  }
                  page={PAGE_TO_NAME[whoCalledMe]}
                >
                  <ButtonIcon
                    variant={isFavorited ? "primary" : "secondary"}
                    onClick={toggleFavorited}
                    disabled={
                      admin ||
                      deletePreferredWorkersLoading ||
                      addPreferredWorkersLoading
                    }
                  >
                    {isFavorited ? <FaHeart /> : <FaRegHeart />}
                  </ButtonIcon>
                </RestrictedSignedProfile>
              </AdminActionDisabledTooltip>
            </Popover>
            <Dropdown
              trigger={["click"]}
              onVisibleChange={(visible) => {
                if (visible) {
                  logWorkerDirectoryItemEvents(
                    WORKER_DIRECTORY_ITEM.MORE_OPTIONS,
                  );
                }
              }}
              overlay={
                <Menu onClick={handleMenuItemClick}>
                  {!admin && (
                    <Menu.Item key="block-shifts">
                      Offer block booking
                    </Menu.Item>
                  )}
                  <Menu.Item key="view-profile">View profile</Menu.Item>
                </Menu>
              }
            >
              <ButtonIcon
                variant="secondary"
                onClick={(event) => event.stopPropagation()}
              >
                <EllipsisOutlined />
              </ButtonIcon>
            </Dropdown>
          </WorkerActions>
        </Row>
      </Worker>
      {showVideoModal && (
        <Modal
          visible={showVideoModal}
          width={780}
          onCancel={() => setShowVideoModal(false)}
          footer={null}
          modalRender={() => (
            <iframe
              width="98%"
              height="420px"
              src={worker.profileVideoUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              style={{ pointerEvents: "auto" }}
            />
          )}
        />
      )}

      {showReviewsModal && (
        <Modal
          visible={showReviewsModal}
          width={680}
          onCancel={handleViewRatingsClosed}
          footer={null}
          title={renderRatingsModalHeader(
            worker.averageRating,
            worker.ratingsCount,
          )}
        >
          <ListWorkerReviews
            workerId={worker.workerId}
            handleReadMore={handleReadMore}
          />
        </Modal>
      )}
    </>
  );
};
