import styled from "styled-components";
import { BannerProps } from "./Banner.type";

export const Wrapper = styled.div<Pick<BannerProps, "type">>`
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
  border-radius: 8px;
  color: #424242;
  gap: 16px;
  flex-direction: row;
  align-items: center;

  ${({ type }) =>
    type === "warning"
      ? `
  background-color: #f7d7944d;
  border: 1px solid #f7d794;
  `
      : type === "info"
      ? `
  background-color: #0C98AC4d;
  border: 1px solid #0C98AC;
      `
      : undefined}
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: max-content;
`;
