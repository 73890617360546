import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchWorkerReviews } from "frontend-admin/src/api/workerReview";

export function useWorkerReviews(workerId?: string) {
  const queryKey = ["worker-reviews", workerId];
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey,
    queryFn: () => {
      if (!workerId) {
        return undefined;
      }
      return fetchWorkerReviews({ workerId });
    },
    enabled: !!workerId,
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(queryKey);
  };

  return { data, isLoading, error, invalidateCache };
}
