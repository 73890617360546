import { Avatar, Checkbox, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CheckableTag from "antd/lib/tag/CheckableTag";
import styled from "styled-components";
import { Rate } from "frontend-admin/src/designsystem/Rate/Rate";

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const WorkerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WorkerAvatar = styled(Avatar)`
  border-radius: 100%;
	width: 96px;
	height 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
`;

export const FormTitle = styled.div<{ bold?: boolean }>`
  margin-top: 8px;
  font-size: 18px;
  text-align: center;
  ${({ bold }) => (bold ? `font-weight: 500;` : undefined)}
`;

export const LastWorkedLabel = styled.div`
  font-size: 14px;
  color: #424242;
  margin-bottom: 8px;
`;

export const FormLabel = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const FormItem = styled(Form.Item)`
  margin: 0;
  display: flex;
  flex-direction: column;
  .ant-form-item-explain .ant-form-item-explain-error {
    margin: 0 0 8px 0;
    color: #ca4031;
  }
`;

export const WorkerRating = styled(Rate)`
  font-size: 32px;
  color: #fbb641;
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 4px;
`;

export const RatingTag = styled(CheckableTag)<{ checked: boolean }>`
  cursor: pointer;
  background: ${({ checked }) => (checked ? "#032e68" : "#d9d9d9")};
  border-radius: 16px;
  color: ${({ checked }) => (checked ? "white" : "#424242")};
  padding: 4px 16px;
  margin: 0;
  font-size: 13px;

  &:not(.ant-tag-checkable-checked):hover {
    color: #032e68;
  }
`;

export const StyledTextArea = styled(TextArea)`
  margin-bottom: 8px;
  &.ant-input {
    font-size: 14px;
    height: 80px;
    padding: 8px 12px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: #c7130b;
  margin-top: 16px;

  .ant-checkbox-inner.ant-checkbox-inner {
    border-color: #c7130b;
  }

  .ant-checkbox-checked {
    &:after {
      border: 1px solid #c7130b;
    }

    .ant-checkbox-inner {
      background-color: #c7130b;
    }
  }
`;

export const ReviewVisibilityInfoText = styled.p`
  color: #424242;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
`;
