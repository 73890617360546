const setColor = (user, key) => {
  return user.highlights.includes(key)
    ? "rgba(0, 0, 0, 0.85)"
    : "rgb(197, 199, 199)";
};

export const SearchAttribute = (user, key) => {
  return user[key] ? (
    <div key={`${user._id}-${key}`} style={{ color: setColor(user, key) }}>
      {user[key]}
    </div>
  ) : (
    <></>
  );
};
