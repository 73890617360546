export function getSignInLink(
  requestedURL: string,
  email: string,
  keepMeLogin: boolean,
): string {
  const isDev = window.location.hostname === "localhost";
  const port = window.location.port !== "" ? `:${window.location.port}` : "";
  const appDomain = `http${isDev ? "" : "s"}://${
    window.location.hostname
  }${port}`;
  return `${appDomain}/login/verify?requestedUrl=${requestedURL}&&email=${email}&&keepMeLogin=${keepMeLogin}`;
}
