import Icon from "@ant-design/icons";
import React from "react";

const MapSvg = () => (
  <svg
    width={14}
    height={14}
    viewBox="3 3 14 14"
    preserveAspectRatio="xMinYMin"
  >
    <path
      d="m12.562 16.771-5.124-1.542-3.021 1q-.459.146-.844-.104-.385-.25-.385-.792V5.312q0-.312.177-.572.177-.261.427-.344l3.646-1.208 5.124 1.541 3.021-1q.459-.187.844.073.385.26.385.802v10.063q0 .312-.177.583-.177.271-.447.333Zm-.624-1.438V5.792L8.062 4.583v9.584Zm1.208-.041 2.458-.792V4.958l-2.458.834ZM4.396 15l2.458-.833V4.625l-2.458.833Zm8.75-9.208v9.5ZM6.854 4.625v9.542Z"
      fill="currentColor"
    />
  </svg>
);

export const MapIcon = (props) => <Icon component={MapSvg} {...props} />;
