const latestVersions = {
  Chrome: 78,
  Firefox: 70,
  Edge: 44,
};

export function get_browser() {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem !== null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/\Edge\/(\d+)/);
    if (tem !== null) {
      return { name: "Edge", version: tem[1] };
    }
  }
  if (window.navigator.userAgent.indexOf("Edg") > -1) {
    tem = ua.match(/\Edg\/(\d+)/);
    if (tem !== null) {
      return { name: "Edge", version: tem[1] };
    }
  }
  if (M[1] === "Safari") {
    tem = ua.match(/\Safari\/(\d+)/);
    if (tem !== null) {
      return { name: "Safari", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
    return {
      name: "MOBILE_VERSION",
    };
  }
  return {
    name: M[0],
    version: +M[1],
  };
}

function checkSupport(browser) {
  let supported = true;
  if (
    browser.name === "Chrome" &&
    browser.version < latestVersions[browser.name] - 5
  ) {
    supported = false;
  } else if (
    browser.name === "Firefox" &&
    browser.version < latestVersions[browser.name] - 5
  ) {
    supported = false;
  } else if (
    (browser.name === "MSIE" || browser.name === "IE") &&
    browser.version < 11
  ) {
    supported = false;
  } else if (browser.name === "Opera") {
    supported = false;
  }
  return supported;
}

const browser = get_browser();

const supportError = document.getElementById("support_error");
const rootElement = document.getElementById("root");

if (browser.name === "IE" || (browser.name === "MSIE" && supportError)) {
  supportError.style.visibility = "visible";
}

if (
  browser.name !== "IE" &&
  browser.name !== "MSIE" &&
  rootElement &&
  supportError
) {
  rootElement.style.display = "block";
  supportError.style.display = "none";
}

const isSupported = checkSupport(browser);

if (!isSupported && rootElement && supportError) {
  rootElement.style.display = "none";
  supportError.style.display = "block";
  supportError.style.visibility = "visible";
}
