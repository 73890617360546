import { useCallback, useEffect, useState } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { NotificationsButton } from "frontend-admin/src/containers/app/styles";
import { logEvent } from "frontend-admin/src/utils/segment/logEvents";
import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";
import { NotificationCenter, filterContentCards } from "./NotificationCenter";
import { INotificationCenterButtonProps } from "./interfaces";

import "./styles.scss";

const intervalTime = 60 * 1000; // 1 minute

const NotificationCenterButton = ({
  brazeInitialized,
}: INotificationCenterButtonProps) => {
  const { braze } = window as any;
  const [unreadNotificationCount, setUnreadNotificationCount] =
    useState<number>(0);
  const [notificationCenterVisible, setNotificationCenterVisible] =
    useState(false);

  const getUnreadNotificationCount = useCallback(
    () =>
      filterContentCards(braze.getCachedContentCards().cards).filter(
        (card) => !card.viewed,
      ).length,
    [braze],
  );

  const toggleNotificationCenter = useCallback(
    (open) => {
      if (open) {
        logEvent(HCF_USER_EVENTS.NOTIFICATION_CENTER_VIEWED, {
          context: location.pathname,
          n_unread_messages: unreadNotificationCount,
          has_unread_badge: unreadNotificationCount > 0,
        });
      }
      setNotificationCenterVisible(open);
    },
    [setNotificationCenterVisible, unreadNotificationCount],
  );

  const timerLogic = useCallback(
    (firstTime = false) => {
      if (braze && brazeInitialized) {
        braze.requestContentCardsRefresh(() => {
          const unreadCount = getUnreadNotificationCount();
          setUnreadNotificationCount(unreadCount);
          if (firstTime) {
            logEvent(HCF_USER_EVENTS.NOTIFICATION_CENTER_ICON_VIEWED, {
              context: location.pathname,
              n_unread_messages: unreadCount,
              has_unread_badge: unreadCount > 0,
            });
          }
        });
      }
    },
    [braze, brazeInitialized, getUnreadNotificationCount],
  );

  useEffect(() => {
    timerLogic(true);
    const timer = setInterval(timerLogic, intervalTime);
    return () => clearInterval(timer);
  }, [timerLogic]);

  return (
    <>
      <NotificationsButton
        data-testid="notification-center-button"
        onClick={() => {
          toggleNotificationCenter(true);
        }}
      >
        <BellOutlined />
        Notifications
        <Badge
          count={unreadNotificationCount}
          offset={[10, 0]}
          className="notification-center"
          data-testid="notification-center-unread-count-badge"
        />
      </NotificationsButton>
      <NotificationCenter
        onClose={() => {
          toggleNotificationCenter(false);
          // Mark all as read
          braze.logCardImpressions(braze.getCachedContentCards().cards, true);
          // The above command does not mark the cached cards as viewed
          // until the NC is opened again or until braze does a `/sync`
          // (in the background).
          // So marking the count 0 manually.
          setUnreadNotificationCount(0);
        }}
        isVisible={notificationCenterVisible}
      />
    </>
  );
};

export { NotificationCenterButton };
