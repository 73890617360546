import { firebaseAppAnalytics } from "../firebaseAnalytics";
import { snakeCase } from "lodash";

const logEvent = (eventName, options) => {
  const eventNameSnakeCase = snakeCase(eventName);
  window.analytics.track(eventName, options);
  firebaseAppAnalytics.logEvent(eventNameSnakeCase, options);
};

const logEventConditionally = (
  eventName,
  options,
  shouldFireFirebaseEvent = true,
) => {
  const eventNameSnakeCase = snakeCase(eventName);
  window.analytics.track(eventName, options);
  if (shouldFireFirebaseEvent) {
    firebaseAppAnalytics.logEvent(eventNameSnakeCase, options);
  }
};

export { logEvent, logEventConditionally };
