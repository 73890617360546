import request from "superagent";
import _ from "lodash";
import { getAuthHeader } from "../utils/superagent";

export const RETRIEVE = "locations/RETRIEVE";

const initialState = {
  locations: [],
  groups: [],
  locationMap: {},
};

const getGroups = (locations) => {
  const mapGroups = (location) =>
    _.isEmpty(location.groups) ? location.name : location.groups;

  const groups = _.chain(locations).flatMap(mapGroups).uniq().value();

  return groups;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        locations: action.data,
        groups: getGroups(action.data),
        locationMap: _.keyBy(action.data, "_id"),
      };

    default:
      return state;
  }
};

export const getLocations = (data) => {
  return async (dispatch) => {
    return request
      .get(`${global.api}/location`)
      .set(await getAuthHeader())
      .send(data)
      .then((res) => {
        dispatch({
          type: RETRIEVE,
          data: res.body,
        });
      });
  };
};

export const getLocationFromRegions = (locationList, inputRegions) => {
  const locations = [];
  const regions = inputRegions || [];

  _.forEach(locationList, (location) => {
    const groups = location.groups || [];

    if (_.isEmpty(groups) && regions.includes(location.name)) {
      locations.push(location._id);
      return;
    }

    if (groups.find((group) => regions.includes(group))) {
      locations.push(location._id);
    }
  });

  return locations;
};
