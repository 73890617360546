import { Tooltip } from "antd";
import { Wrapper } from "./AdminActionDisabledTooltip.styles";

interface AdminActionDisabledTooltipProps {
  children: JSX.Element;
  disabled: boolean;
}

export const AdminActionDisabledTooltip = ({
  children,
  disabled,
}: AdminActionDisabledTooltipProps): JSX.Element => {
  return disabled ? (
    <Tooltip
      title="This action is disabled for CBH employees. Only workplace users can perform this action."
      trigger="hover"
    >
      <Wrapper onClick={(evt) => evt.stopPropagation()}>{children}</Wrapper>
    </Tooltip>
  ) : (
    children
  );
};
