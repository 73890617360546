import { useHighRatingWorkers } from "frontend-admin/src/containers/workplace/WorkersDirectory/useHighRatingWorkers";
import { SessionType } from "frontend-admin/src/modules/interface";
import { FacilityFlags } from "frontend-admin/src/containers/facilityUser/helpers";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { FeatureFlag } from "frontend-admin/src/constants/FEATURE_FLAGS";

const useFacilityOnboardingFlag = (
  profile: SessionType["session"]["profile"] | undefined,
  userFlags: FacilityFlags | undefined,
  hasPermission: boolean,
  isAdmin: boolean,
  isFacilityType: boolean,
): boolean => {
  const [loadWorkersDirectory, setLoadWorkersDirectory] = useState(false);

  useEffect(() => {
    const { minimumWorkplaceCreationDate } = (userFlags?.[
      FeatureFlag.HCF_ONBOARDING
    ] ?? {}) as { minimumWorkplaceCreationDate: string | undefined };

    if (
      !profile?.createdAt ||
      !minimumWorkplaceCreationDate ||
      !profile.onboardingSteps ||
      !hasPermission
    ) {
      return;
    }

    const facilityCreatedAt = moment.tz(profile.createdAt, profile.tmz);
    const minimumCreationDate = moment.tz(
      minimumWorkplaceCreationDate,
      profile.tmz,
    );

    const facilityWithinTimeRange =
      facilityCreatedAt.isAfter(minimumCreationDate);

    if (facilityWithinTimeRange) {
      setLoadWorkersDirectory(true);
    }
  }, [
    profile?.createdAt,
    profile?.tmz,
    userFlags,
    profile?.onboardingSteps,
    hasPermission,
    setLoadWorkersDirectory,
  ]);

  // This below query is made to get the cached data that internally uses useQuery.
  const { isLoading, data, isError } = useHighRatingWorkers(
    isFacilityType && hasPermission && loadWorkersDirectory
      ? profile?.userId ?? ""
      : "",
    {
      pageSize: 2,
    },
    isAdmin,
  );

  return !isLoading && !!data.length && !isError;
};

export { useFacilityOnboardingFlag };
