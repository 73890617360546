import { ITeamMember } from "frontend-admin/src/containers/teamMembers/useTeamMembersAPIHook";
import { SessionType } from "frontend-admin/src/modules/interface";
import omit from "lodash/omit";

export const generateLogData = (
  sessionUserId: string,
  admin: SessionType["session"]["admin"],
  type: SessionType["session"]["type"],
  workplaceId: SessionType["session"]["profile"]["userId"],
  memberID?: string,
) => {
  return {
    performedBy: admin || sessionUserId,
    performedByType: type,
    workplaceId,
    ...(memberID && { userId: memberID }),
  };
};

export const generateAddEditLogData = (
  userInfo: Partial<ITeamMember>,
  user: SessionType["session"]["user"],
  admin: SessionType["session"]["admin"],
  type: SessionType["session"]["type"],
  workplaceId: SessionType["session"]["profile"]["userId"],
  member?: ITeamMember,
) => {
  const updateProperties = omit(
    userInfo,
    "granularControl",
    "alertSettings",
    "notify",
  );
  const updatePropertiesKeys = Object.keys(updateProperties);
  const propertiesUpdated = updatePropertiesKeys.reduce((acc, property) => {
    acc[property] = {
      new: updateProperties[property],
    };
    if (member) {
      acc[property]["previous"] = member[property] ?? "";
    }
    return acc;
  }, {});

  if (propertiesUpdated["phone"]) {
    propertiesUpdated["mobilePhone"] = propertiesUpdated["phone"];
    delete propertiesUpdated["phone"];
  }

  return {
    performedBy: admin || user?._id,
    performedByType: type,
    workplaceId,
    userId: member?._id,
    propertiesUpdated,
  };
};

export const generateNotificationLogData = (
  property: string,
  existingData: string,
  updatedData: string,
  user: SessionType["session"]["user"],
  admin: SessionType["session"]["admin"],
  type: SessionType["session"]["type"],
  workplaceId: SessionType["session"]["profile"]["userId"],
  member?: ITeamMember,
) => {
  return {
    performedBy: admin || user?._id,
    performedByType: type,
    userId: member?._id,
    workplaceId,
    [property]: {
      previous: existingData,
      new: updatedData.charAt(0).toUpperCase() + updatedData.slice(1),
    },
  };
};
