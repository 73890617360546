import {
  ApiOutlined,
  CalendarOutlined,
  FileDoneOutlined,
  LoginOutlined,
  LogoutOutlined,
  MessageOutlined,
  ScheduleOutlined,
  TeamOutlined,
  UserOutlined,
  ControlOutlined,
  IdcardOutlined,
  ClockCircleOutlined,
  QuestionCircleOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import { Badge, Menu, Modal } from "antd";
import { includes } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { EMPLOYEE_ROLES, EMPLOYEE_API_ROLES } from "../../constants/employee";
import {
  FACILITY_ROLES,
  FACILITY_HELP_SUPPORT_LINK,
  FACILITY_USER_PERMISSIONS,
} from "../../constants/facility";
import { CurrencyIcon } from "../../icons/currency";
import { logEvent } from "../../utils/segment/logEvents";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "frontend-admin/src/constants/FEATURE_FLAGS";
import { MapIcon } from "frontend-admin/src/icons/map";
import { showContractTerms } from "./hooks/showContractTerms";
import { showLiveMapView } from "frontend-admin/src/containers/app/hooks/showLiveMapView";

import { HCF_USER_EVENTS } from "frontend-admin/src/constants/firebaseEvents";
import { useFetchFlags } from "frontend-admin/src/containers/teamMembers/useTeamMembersAPIHook";
import { useFacilityOnboardingFlag } from "frontend-admin/src/hooks/useFacilityOnboardingFlag";
import { getLocation } from "frontend-admin/src/utils/routes";
const { SubMenu } = Menu;

const ResponsiveMenu = styled(Menu)`
  line-height: 64px !important;
  @media (max-width: 992px) {
    float: none;
    line-height: 0;
  }
`;

const adminMenus = (roles, configuration) => [
  <SubMenu
    key="accounts"
    data-cbh-id="menu-item-accounts"
    title={
      <span>
        <TeamOutlined />
        <span>Accounts</span>
      </span>
    }
  >
    <Menu.Item key="agents" data-cbh-id="menu-item-agents">
      <Link to={getLocation("adminAccountsAgents")}>HCPs</Link>
    </Menu.Item>
    <Menu.Item key="facilities" data-cbh-id="menu-item-facilities">
      <Link to={getLocation("adminAccountsFacilities")}>Facilities</Link>
    </Menu.Item>
    {roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE) && (
      <Menu.Item key="employees" data-cbh-id="menu-item-employees">
        <Link to={getLocation("adminAccountsEmployees")}>Employees</Link>
      </Menu.Item>
    )}
    {(roles.includes(EMPLOYEE_ROLES.DOCUMENTS) ||
      roles.includes(EMPLOYEE_ROLES.DOCUMENT_CREATION)) && (
      <Menu.Item key="requirements" data-cbh-id="menu-item-requirements">
        <Link to={getLocation("adminAccountsRequirement")}>Requirements</Link>
      </Menu.Item>
    )}
    <Menu.Item key="locations" data-cbh-id="menu-item-locations">
      <Link to={getLocation("adminAccountsLocations")}>Locations</Link>
    </Menu.Item>
    <Menu.Item key="changelog" data-cbh-id="menu-item-changelog">
      <Link to={getLocation("adminAccountsChangeLog")}>Change Log</Link>
    </Menu.Item>
    <Menu.Item key="exclusions" data-cbh-id="menu-item-exclusions">
      <Link to={getLocation("adminAccountsExclusions")}>DNRs</Link>
    </Menu.Item>
  </SubMenu>,
  <Menu.Item key="shifts" data-cbh-id="menu-item-shifts">
    <Link to={getLocation("adminDashboard")}>
      <span>
        <ScheduleOutlined />
        <span>Shifts</span>
      </span>
    </Link>
  </Menu.Item>,
  ...(roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE) ||
  roles.includes(EMPLOYEE_API_ROLES.INVOICE_EDIT)
    ? [
        <SubMenu
          key="Invoice"
          data-cbh-id="menu-item-invoice"
          title={
            <span>
              <FileDoneOutlined />
              <span>Invoice</span>
            </span>
          }
        >
          <Menu.Item key="byperiod" data-cbh-id="menu-item-invoice-byperiod">
            <Link to={getLocation("adminAccountsInvocingByPeriod")}>
              By Period
            </Link>
          </Menu.Item>
          <Menu.Item key="bynumber" data-cbh-id="menu-item-invoice-bynumber">
            <Link to={getLocation("adminAccountsInvocingByNumber")}>
              By Number
            </Link>
          </Menu.Item>
        </SubMenu>,
      ]
    : roles.includes(EMPLOYEE_ROLES.BILLING)
    ? [
        <SubMenu
          key="Invoice"
          data-cbh-id="menu-item-invoice"
          title={
            <span>
              <FileDoneOutlined />
              <span>Invoice</span>
            </span>
          }
        >
          <Menu.Item key="bynumber" data-cbh-id="menu-item-invoice-bynumber">
            <Link to={getLocation("adminAccountsInvocingByNumber")}>
              By Number
            </Link>
          </Menu.Item>
        </SubMenu>,
      ]
    : []),

  <SubMenu
    key="payroll-menu"
    data-cbh-id="menu-item-payroll"
    title={
      <span>
        <CurrencyIcon />
        <span>Payroll</span>
      </span>
    }
  >
    {configuration.isPayrollViewEnabled && (
      <Menu.Item
        key="payroll"
        data-cbh-id="menu-item-payroll-agent"
        data-testid="menu-item-payroll"
      >
        <Link to={getLocation("agentPayroll")}>Payroll</Link>
      </Menu.Item>
    )}
    <Menu.Item key="proof" data-cbh-id="menu-item-proof-of-earnings-agent">
      <Link to={getLocation("agentProof")}>Proof of Earnings</Link>
    </Menu.Item>
  </SubMenu>,
  <SubMenu
    key="logs"
    data-cbh-id="menu-item-logs"
    title={
      <span>
        <ApiOutlined />
        <span>Logs</span>
      </span>
    }
  >
    <Menu.Item key="shift" data-cbh-id="menu-item-shift-logs">
      <Link to={getLocation("adminLogsShift")}>Shifts</Link>
    </Menu.Item>
  </SubMenu>,
  ...(roles.includes(EMPLOYEE_ROLES.ADMINISTRATIVE)
    ? [
        <Menu.Item
          key="timeclockrequests"
          data-cbh-id="menu-item-timeclockrequests"
        >
          <Link to={getLocation("adminTimeclock")}>
            <span>
              <ClockCircleOutlined />
              <span>Timeclock Requests</span>
            </span>
          </Link>
        </Menu.Item>,
      ]
    : []),
  ...(roles.includes(EMPLOYEE_ROLES.SHIFT_PRICING)
    ? [
        <SubMenu
          key="shiftPayments"
          data-cbh-id="menu-item-shiftpayments"
          title={
            <span>
              <CurrencyIcon />
              <span>Shift Payments</span>
            </span>
          }
        >
          <Menu.Item
            key="pay-rates"
            data-cbh-id="menu-item-shiftpayments-pay-rates"
          >
            <Link to={getLocation("adminShiftPaymentsPayRates")}>
              Pay Rates
            </Link>
          </Menu.Item>

          <Menu.Item
            key="change-logs"
            data-cbh-id="menu-item-shiftpayments-change-logs"
          >
            <Link to={getLocation("adminShiftPaymentsChangeLogs")}>
              Change Logs
            </Link>
          </Menu.Item>
        </SubMenu>,
      ]
    : []),
  <Menu.Item key="urgentShiftsNcns" data-cbh-id="menu-item-urgentShiftsNcns">
    <Link to={getLocation("adminUrgentShiftNcns")}>
      <span>
        <ScheduleOutlined />
        <span>No shows</span>
      </span>
    </Link>
  </Menu.Item>,
];

const agentMenus = (configuration) => [
  <Menu.Item key="dashboard" data-cbh-id="menu-item-dashboard">
    <Link to={getLocation("agentDashboard")}>
      <CalendarOutlined />
      <span>Calendar</span>
    </Link>
  </Menu.Item>,

  <SubMenu
    key="list-payroll"
    data-cbh-id="menu-item-agent-list-payroll"
    title={
      <span>
        <CurrencyIcon />
        <span>Payroll</span>
      </span>
    }
  >
    {configuration.isPayrollViewEnabled && (
      <Menu.Item key="payroll" data-cbh-id="menu-item-agent-payroll">
        <Link to={getLocation("agentPayroll")}>Payroll</Link>
      </Menu.Item>
    )}
    <Menu.Item
      key="stripe-payouts"
      data-cbh-id="menu-item-agent-stripe-payouts"
    >
      <Link to={getLocation("agentStripePayouts")}>Stripe Payouts</Link>
    </Menu.Item>
  </SubMenu>,

  <Menu.Item key="attendance" data-cbh-id="menu-item-agent-attendance">
    <Link to={getLocation("agentAttendance")}>
      <CalendarOutlined />
      <span>Attendance Score</span>
    </Link>
  </Menu.Item>,
];

const logHelpArticlesClick = () => {
  logEvent(HCF_USER_EVENTS.HELP_CENTER_OPENED);
};

const facilityMenus = (
  roles,
  permissions,
  admin,
  userProfileRenderer,
  ldFlags,
  unreadChannelCount,
  _emailOfLoggedInUser,
  profile,
  _chargeRateVisible,
  shouldShowContractTerms,
  shouldShowLiveMapView,
  shouldShowWorkerMenu,
  location,
  userFlags,
  shouldShowFacilityOnboarding,
) => {
  const { minimumWorkplaceCreationDate } =
    userFlags?.[FeatureFlag.HCF_ONBOARDING] ?? {};
  const workplaceInExperiment =
    minimumWorkplaceCreationDate && profile?.createdAt
      ? new Date(profile.createdAt) > new Date(minimumWorkplaceCreationDate)
      : false;
  const isFacilityOnboardingActive =
    workplaceInExperiment && shouldShowFacilityOnboarding;
  const getFacilityOnboardingSteps = profile?.onboardingSteps ?? {};
  const remainingOnboardingSteps = Object.keys(
    getFacilityOnboardingSteps,
  ).filter((key) => getFacilityOnboardingSteps[key] === false).length;

  const MENU_ITEM_TEST_IDS = {
    LIVE_WORKER_ETA_MENU_ITEM: "menu-item-live-worker-eta",
    HELP_CENTER_MENU_ITEM: "menu-item-help-center",
    HELP_CENTER_MENU_ITEM_LINK: "menu-item-help-center-link",
    FACILITY_ONBOARDING: "menu-item-facility-onboarding",
  };

  let menus = [
    isFacilityOnboardingActive && (
      <Menu.Item
        key="getting-started"
        data-cbh-id={MENU_ITEM_TEST_IDS.FACILITY_ONBOARDING}
        data-testid={MENU_ITEM_TEST_IDS.FACILITY_ONBOARDING}
        className={
          location?.pathname === getLocation("facilityOnboarding").pathname
            ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
            : ""
        }
      >
        <Link to={getLocation("facilityOnboarding")}>
          <OrderedListOutlined />
          <span>
            Getting Started
            {!!remainingOnboardingSteps && (
              <Badge
                style={{
                  marginLeft: 16,
                  boxShadow: "none",
                  backgroundColor: "#e94a4d",
                }}
                count={remainingOnboardingSteps}
              />
            )}
          </span>
        </Link>
      </Menu.Item>
    ),
    <Menu.Item
      key="dashboard"
      data-cbh-id="menu-item-dashboard"
      data-testid="menu-item-dashboard"
    >
      <Link to={getLocation("facilityDashboard")}>
        <CalendarOutlined />
        <span>Calendar</span>
      </Link>
    </Menu.Item>,
    shouldShowLiveMapView && (
      <Menu.Item
        key="live-worker-eta"
        data-cbh-id={MENU_ITEM_TEST_IDS.LIVE_WORKER_ETA_MENU_ITEM}
        data-testid={MENU_ITEM_TEST_IDS.LIVE_WORKER_ETA_MENU_ITEM}
      >
        <Link to={getLocation("facilityLiveWorkerETA")}>
          <MapIcon />
          <span>Live Worker ETA</span>
        </Link>
      </Menu.Item>
    ),

    shouldShowWorkerMenu && (
      <SubMenu
        key="workers"
        data-cbh-id="menu-item-workers"
        data-testid="menu-item-workers"
        title={
          <span>
            <IdcardOutlined />
            <span>Workers</span>
          </span>
        }
      >
        <Menu.Item
          key="favorite-workers"
          data-cbh-id="menu-item-favorite-workers"
          data-testid="menu-item-favorite-workers"
          className={
            location?.pathname === getLocation("workplaceWorkers").pathname
              ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
              : ""
          }
        >
          <Link to={getLocation("workplaceWorkers")}>
            <span>Favorites + Booked</span>
          </Link>
        </Menu.Item>

        {isFacilityOnboardingActive && (
          <Menu.Item
            key="browse-workers"
            data-cbh-id="menu-item-browse-workers"
            data-testid="menu-item-browse-workers"
            className={
              location?.pathname === getLocation("workersDirectory").pathname
                ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
                : ""
            }
          >
            <Link to={getLocation("workersDirectory")}>
              <span>Browse Workers</span>
            </Link>
          </Menu.Item>
        )}
      </SubMenu>
    ),
    <SubMenu
      key="management"
      data-cbh-id="menu-item-management"
      title={
        <span>
          <ControlOutlined />
          <span>Management</span>
        </span>
      }
    >
      {userFlags?.[FeatureFlag.MY_ACCOUNT_PAGE] && !admin && (
        <Menu.Item
          key="profile"
          data-cbh-id="menu-item-profile"
          className={
            location?.pathname.includes(getLocation("myAccount").pathname)
              ? "ant-menu-item-selected" // This class is the active class used by antd to activate the route.
              : ""
          }
        >
          <Link className="ellipsis" to={getLocation("myAccount")}>
            <span>My Account</span>
          </Link>
        </Menu.Item>
      )}
      {userFlags?.[FeatureFlag.NEW_USERS_PAGE] ? (
        !(roles?.includes("DMT") && roles.length === 1) ? (
          <Menu.Item key="users" data-cbh-id="menu-item-users">
            <Link to={getLocation("workplaceUsers")}>Team Members</Link>
          </Menu.Item>
        ) : null
      ) : (
        <Menu.Item key="users" data-cbh-id="menu-item-users">
          <Link to={getLocation("facilityUsers")}>Users</Link>
        </Menu.Item>
      )}
      <Menu.Item
        key="worker-instructions"
        data-cbh-id="menu-item-worker-instructions"
      >
        <Link to={getLocation("facilityWorkerInstructions")}>
          Facility Information
        </Link>
      </Menu.Item>
      <Menu.Item key="covid-data" data-cbh-id="menu-item-covid-data">
        <Link to={getLocation("facilityCovidData")}>COVID Status</Link>
      </Menu.Item>
      {includes(roles, FACILITY_ROLES.ADMIN) && (
        <Menu.Item key="timeclock-settings" data-cbh-id="timeclock-settings">
          <Link to={getLocation("facilityTimeclockSettings")}>Settings</Link>
        </Menu.Item>
      )}
    </SubMenu>,
  ];

  if (
    ldFlags["chat"] &&
    (includes(roles, FACILITY_ROLES.ADMIN) ||
      includes(roles, FACILITY_ROLES.DOCUMENTS) ||
      includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT))
  ) {
    menus.push(
      <Menu.Item
        key="chat"
        data-cbh-id="menu-item-chat"
        data-testid="menu-item-chat"
      >
        <Link to={getLocation("facilityChat")}>
          <MessageOutlined />
          <span>
            Chat{" "}
            <Badge
              style={{
                marginLeft: 6,
                boxShadow: "none",
                backgroundColor: "#e94a4d",
              }}
              count={unreadChannelCount}
            />
          </span>
        </Link>
      </Menu.Item>,
    );
  }
  const hasBillingRights =
    admin ||
    includes(roles, FACILITY_ROLES.ADMIN) ||
    includes(roles, FACILITY_ROLES.INVOICES);

  const hasManageInvoiceRights =
    hasBillingRights ||
    includes(permissions, FACILITY_USER_PERMISSIONS.MANAGE_INVOICES);

  const hasViewUpcomingChargesRights =
    hasBillingRights ||
    includes(permissions, FACILITY_USER_PERMISSIONS.ACCESS_UPCOMING_CHARGES) ||
    includes(roles, FACILITY_ROLES.SHIFT_MANAGEMENT);

  const hasViewContractTermsRights =
    hasBillingRights ||
    includes(permissions, FACILITY_USER_PERMISSIONS.ACCESS_CONTRACT_TERMS);

  if (
    hasViewUpcomingChargesRights ||
    hasManageInvoiceRights ||
    (shouldShowContractTerms && hasViewContractTermsRights)
  ) {
    menus = [
      ...menus,
      <SubMenu
        key="Invoice"
        data-cbh-id="menu-item-invoice"
        data-testid="menu-item-invoice"
        title={
          <span>
            <FileDoneOutlined />
            <span>Billing</span>
          </span>
        }
      >
        {hasViewUpcomingChargesRights && (
          <Menu.Item
            key="upcoming-charges"
            data-cbh-id="menu-item-invoice-upcoming-charges"
          >
            <Link to={getLocation("facilityInvoiceUpcomingCharges")}>
              Upcoming Charges
            </Link>
          </Menu.Item>
        )}
        {hasManageInvoiceRights && (
          <Menu.Item key="history" data-cbh-id="menu-item-invoice-history">
            <Link to={getLocation("facilityInvoiceHistory")}>
              Invoice History
            </Link>
          </Menu.Item>
        )}
        {shouldShowContractTerms && hasViewContractTermsRights && (
          <Menu.Item
            key="contract-terms"
            data-cbh-id="menu-item-contract-terms"
          >
            <Link to={getLocation("facilityInvoiceContractTerms")}>
              Contract Terms
            </Link>
          </Menu.Item>
        )}
      </SubMenu>,
    ];
  }
  const helpMenu = (
    <Menu.Item
      key="help-center"
      data-cbh-id={MENU_ITEM_TEST_IDS.HELP_CENTER_MENU_ITEM}
      data-testid={MENU_ITEM_TEST_IDS.HELP_CENTER_MENU_ITEM}
    >
      <a
        href={FACILITY_HELP_SUPPORT_LINK}
        target="_blank"
        onClick={logHelpArticlesClick}
        data-cbh-id={MENU_ITEM_TEST_IDS.HELP_CENTER_MENU_ITEM_LINK}
        data-testid={MENU_ITEM_TEST_IDS.HELP_CENTER_MENU_ITEM_LINK}
      >
        <QuestionCircleOutlined />
        <span>Help Center</span>
      </a>
    </Menu.Item>
  );

  return [...menus, userProfileRenderer, helpMenu];
};

const AppMenu = ({
  admin,
  isAgent,
  isAdmin,
  isFacility,
  hasSession,
  className,
  mode,
  selectable,
  profile,
  logout,
  closeMenu,
  enableDefaultSelectedKeys,
  unreadChannelCount,
  emailOfLoggedInUser,
  configuration,
  ...rest
}) => {
  const [selectedKey, setSelectedKey] = useState();

  const { name, access, permissions } = useSelector(
    (state) => state.session.user || {},
  );
  const { user } = useSelector((state) => state.session);
  const shouldShowContractTerms = showContractTerms(isAgent);
  const shouldShowLiveMapView = showLiveMapView();
  const userName = profile ? profile.name : "Home";

  const { pathname } = useLocation();
  const routeKey = pathname.split("/").pop();
  const ldFlags = useFlags();
  const ldClient = useLDClient();
  const { location } = useHistory();

  // User with supervisor role (FACILITY_ROLES.DOCUMENT)
  // can not see the worker menu.
  const shouldShowWorkerMenu = useMemo(() => {
    return (
      admin ||
      includes(user.access, FACILITY_ROLES.ADMIN) ||
      includes(user.access, FACILITY_ROLES.INVOICES) ||
      includes(user.access, FACILITY_ROLES.SHIFT_MANAGEMENT)
    );
  }, [user, admin]);

  useEffect(() => {
    setSelectedKey(routeKey);
  }, [routeKey]);

  useEffect(() => {
    if (ldClient && profile) {
      ldClient.identify({
        key: profile.userId,
        name: profile.name,
        email: profile.email,
        custom: {
          msa: profile.fullAddress?.metropolitanStatisticalArea,
          city: profile.fullAddress?.city,
          state: profile.fullAddress?.state,
        },
      });
    }
  }, [ldClient, profile]);

  const userFlags = useFetchFlags(
    {
      key: profile?.userId,
      name: profile?.name,
      email: profile?.email,
      custom: {
        msa: profile?.fullAddress?.metropolitanStatisticalArea,
        facilitymsa: profile?.fullAddress?.metropolitanStatisticalArea,
        city: profile?.fullAddress?.city,
        state: profile?.fullAddress?.state,
        facilityType: profile?.type,
      },
    },
    profile,
  );

  const shouldShowFacilityOnboarding = useFacilityOnboardingFlag(
    profile,
    userFlags,
    includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
      admin,
    admin,
    isFacility,
  );

  const logoutConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      onOk: logout,
    });
  };

  const handleOnSelect = ({ key }) => {
    setSelectedKey([key]);
  };

  const path =
    (isAdmin && getLocation("adminProfile")) ||
    (isAgent && getLocation("agentProfile")) ||
    (isFacility && name && getLocation("facilityProfile")) ||
    getLocation("adminProfile");

  const userProfileRenderer = !(
    ldFlags[FeatureFlag.MY_ACCOUNT_PAGE] &&
    isFacility &&
    name
  ) ? (
    <Menu.Item key="profile" data-cbh-id="menu-item-profile">
      <Link className="ellipsis" to={path}>
        {hasSession ? <UserOutlined /> : <LoginOutlined />}
        <span>{name || userName}</span>
      </Link>
    </Menu.Item>
  ) : null;

  return (
    <ResponsiveMenu
      theme="dark"
      mode={mode}
      selectable={selectable || false}
      className={className}
      onClick={closeMenu}
      selectedKeys={enableDefaultSelectedKeys ? selectedKey : false}
      onSelect={handleOnSelect}
      defaultOpenKeys={isFacility ? ["management", "Invoice", "workers"] : []}
      {...rest}
    >
      {isAdmin && adminMenus(profile.roles, configuration).map((menu) => menu)}
      {isAgent && agentMenus(configuration).map((menu) => menu)}
      {isFacility
        ? facilityMenus(
            access,
            permissions,
            admin,
            userProfileRenderer,
            ldFlags,
            unreadChannelCount,
            emailOfLoggedInUser,
            profile,
            user?.notify?.EMAIL?.CHARGE_RATE_VISIBLE_EMAIL,
            shouldShowContractTerms,
            shouldShowLiveMapView,
            shouldShowWorkerMenu,
            location,
            userFlags,
            shouldShowFacilityOnboarding,
          ).map((menu) => menu)
        : // user profile should appear after the help section. so it is rendered different for facility login.
          userProfileRenderer}
      {hasSession && (
        <Menu.Item key="logout" data-cbh-id="menu-item-logout">
          {
            // eslint-disable-next-line
            <a onClick={logoutConfirm}>
              <LogoutOutlined />
              <span>Log out</span>
            </a>
          }
        </Menu.Item>
      )}
    </ResponsiveMenu>
  );
};

export { AppMenu };
