import request from "superagent";
import { getAuthHeader } from "../utils/superagent";
import { message } from "antd";

export const getMetropolitanStatisticalAreas = async () => {
  try {
    const response = await request
      .get(`${global.api}/facilities/metropolitanStatisticalAreas`)
      .set(await getAuthHeader());
    return response.body.metropolitanStatisticalAreas;
  } catch (err) {
    message.error(err);
  }
};

export const fetchAllNotes = async (query) => {
  try {
    const res = await request
      .get(`${global.api}/facilityNoteCategory/get-notes/${query.id}`)
      .set(await getAuthHeader());
    return res.body;
  } catch (err) {
    message.error(err);
  }
};

export const updateNotes = async (query, data) => {
  try {
    const res = await request
      .patch(`${global.api}/facilityNoteCategory/update-note/${query.id}`)
      .set(await getAuthHeader())
      .send(data);
    return res.body;
  } catch (err) {
    message.error(err);
  }
};

export const getFacilityDetails = async (hcfIds, projection) => {
  try {
    const res = await request
      .get(`${global.api}/facilityprofile/details`)
      .set(await getAuthHeader())
      .query({ facilityIds: hcfIds, projection });
    return res.body;
  } catch (err) {
    message.error(err);
    return [];
  }
};

export const getFacilityRecentSuspensionLog = async (facilityId) => {
  try {
    const res = await request
      .get(`${global.api}/facilityprofile/recent-suspension-log`)
      .set(await getAuthHeader())
      .query({ facilityId });
    return res.body;
  } catch (error) {
    return {};
  }
};

export const updateFacilityProfile = async (data) => {
  return request
    .put(`${global.api}/facilityprofile/put`)
    .set(await getAuthHeader())
    .send(data);
};

export const fetchAllHolidays = async () => {
  try {
    const { body } = await request
      .get(`${global.api}/holidays`)
      .set(await getAuthHeader());
    return body.map((h) => ({ id: h._id, name: h.name }));
  } catch (error) {
    return [];
  }
};

export type FacilityOnboardingStep = {
  favoriteWorkers: boolean;
  postShifts: boolean;
  getMobileApp: boolean;
  checkInstructions: boolean;
  addTeamMembers: boolean;
  takeProductTour: boolean;
  stage: "pending" | "started" | "completed" | "overdue";
  startedAt?: Date;
};

export const getFacilityOnboardingSteps = async (
  workplaceId: string,
  workplaceUserId: string,
): Promise<FacilityOnboardingStep> => {
  const response = await request
    .get(
      `${global.api}/facility-onboarding/facilities/${workplaceId}/users/${workplaceUserId}`,
    )
    .set(await getAuthHeader())
    .send();
  return response.body;
};

export const updateFacilityOnboardingSteps = async (
  workplaceId: string,
  onboardingSteps: Partial<FacilityOnboardingStep>,
  workplaceUserId: string,
): Promise<FacilityOnboardingStep> => {
  const response = await request
    .put(
      `${global.api}/facility-onboarding/facilities/${workplaceId}/users/${workplaceUserId}`,
    )
    .set(await getAuthHeader())
    .send(onboardingSteps);
  return response.body;
};

export const updateEarlyClockInEnabledInsideFacility = async (
  facilityId: string,
  isEarlyClockInEnabled: boolean,
): Promise<string> => {
  return request
    .put(
      `${global.api}/facilityprofile/${facilityId}/update-early-clock-in-enabled`,
    )
    .set(await getAuthHeader())
    .send({ isEarlyClockInEnabled })
    .then((res) => res.body);
};
