import { Layout } from "antd";
import React from "react";
import meta from "../../utils/meta";

const { Footer } = Layout;
const { supportEmail } = meta();

const AppFooter = ({ style = {}, ...rest }) => (
  <Footer style={{ ...style, textAlign: "center" }} {...rest}>
    Clipboard Health ©{new Date().getFullYear()} | {supportEmail} | 408-837-0116
  </Footer>
);

export { AppFooter };
