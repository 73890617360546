import { cloneElement, ComponentType, ReactElement } from "react";
import {
  UnknownPropsContainer,
  UnknownPropsList,
} from "./PermissionGate.styles";

interface PermissionGatePropsWithError<T> {
  children: JSX.Element;
  hasPermission: boolean;
  NoPermissionComponent?: never;
  noPermissionProps: Partial<T> | null;
  /**
   * there are two modes when using no permission props
   * - all: all of noPermissionProps has to be in input's props
   * - oneof: one of noPermissionProps is in input's props
   */
  noPermissionMode?: "all" | "oneof";
}

interface PermissionGatePropsWithNoPermissionComponent {
  children: JSX.Element;
  hasPermission: boolean;
  NoPermissionComponent: ComponentType;
  noPermissionProps?: never;
  noPermissionMode?: never;
}

export type PermissionGateProps<T> =
  | PermissionGatePropsWithError<T>
  | PermissionGatePropsWithNoPermissionComponent;

/**
 * Creates a gate that will
 * either render the children with modified props from `noPermissionProps` if the user has no permission
 * make sure that the props being passes are present on the children component to be overridden
 * or render the `NoPermissionComponent` if the user has no permission
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function PermissionsGate<T>({
  children,
  hasPermission,
  NoPermissionComponent,
  noPermissionProps,
  noPermissionMode = "all",
}: PermissionGateProps<T>): ReactElement {
  if (!hasPermission && NoPermissionComponent) {
    return <NoPermissionComponent />;
  }

  if (!hasPermission && noPermissionProps) {
    // we need this check only during development as we are not passing any dynamic props to this component
    if (process.env.REACT_APP_NODE_ENV !== "production") {
      const childProps = children.props;

      if (noPermissionMode === "oneof") {
        const isOneOfPropExisting = Object.keys(noPermissionProps).some(
          (prop) => prop in childProps,
        );
        if (!isOneOfPropExisting) {
          return (
            <UnknownPropsContainer>
              <p>
                All of no permission props don't exist in provided child
                properties
              </p>
              <UnknownPropsList>
                {Object.keys(noPermissionProps).map((prop) => (
                  <li key={prop}>{prop}</li>
                ))}
              </UnknownPropsList>
            </UnknownPropsContainer>
          );
        }
      } else {
        const unknownProps = Object.keys(noPermissionProps).filter(
          (prop) => !(prop in childProps),
        );
        if (unknownProps.length > 0) {
          return (
            <UnknownPropsContainer>
              <p>Unknown child properties provided</p>
              <UnknownPropsList>
                {unknownProps.map((prop) => (
                  <li key={prop}>{prop}</li>
                ))}
              </UnknownPropsList>
            </UnknownPropsContainer>
          );
        }
      }
    }
    return cloneElement(children as ReactElement, {
      ...(noPermissionProps as object),
    });
  }

  return children;
}
