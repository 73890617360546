import { Skeleton } from "antd";
import { useWorkerReviews } from "../useWorkerReviews";
import {
  ReviewList,
  ReviewListItemContent,
  ReviewListItemTitle,
  WorkerRating,
  Wrapper,
} from "./ListWorkerReviews.styles";
import moment from "moment";

export const ListWorkerReviews = ({
  workerId,
  handleReadMore,
}: {
  workerId: string;
  handleReadMore: (reviewId: string) => void;
}) => {
  const {
    data: workerReviews,
    isLoading: workerReviewsLoading,
    error: workerReviewsError,
  } = useWorkerReviews(workerId);

  if (workerReviewsLoading) {
    return <Skeleton loading active />;
  }
  if (workerReviewsError) {
    return <Wrapper>Error while loading worker reviews</Wrapper>;
  }

  return (
    <Wrapper>
      <ReviewList>
        {workerReviews
          ?.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
          ?.map((review) => {
            return (
              <li key={review.id}>
                <ReviewListItemTitle>
                  <WorkerRating allowHalf value={review.rating} disabled />{" "}
                  <span>{review.rating.toFixed(1)}</span>
                  <span>&#x2022;</span>
                  <span>
                    Reviewed on{" "}
                    {moment(review.createdAt).format("MMM DD, yyyy")}
                  </span>
                </ReviewListItemTitle>
                {review.additionalFeedback && (
                  <ReviewListItemContent
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                      symbol: "Read more",
                      onExpand: () => {
                        handleReadMore(review.id);
                      },
                    }}
                  >
                    {review.additionalFeedback}
                  </ReviewListItemContent>
                )}
              </li>
            );
          })}
      </ReviewList>
    </Wrapper>
  );
};
