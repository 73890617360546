import { BunnyLogo, ClipboardLogo } from "../../components/logos";
import { Button, Drawer, Layout, Modal, Spin, Badge } from "antd";
import { FallbackLoader, LazyLoad } from "../../components/lazyLoad";
import { Link, Redirect, Route, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import React, {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { get as _get, includes } from "lodash";
import { AccountSwitcher } from "./accountSwitcher/accountSwitcher";
import { AgentExperience } from "./agentExperience";
import { AnimatedLoading } from "../../components/animatedLoading";
import { AppFooter } from "./footer";
import { AppMenu } from "./menu";
import Clock from "react-live-clock";
import { EMPLOYEE_ROLES } from "../../constants/employee";
import {
  FACILITY_ROLES,
  FACILITY_USER_PERMISSIONS,
} from "frontend-admin/src/constants/facility";
import { FacilityAccountSwitcher } from "./FacilityAccountSwitcher";
import { FacilitySuspensionAlert } from "./facilitySuspensionAlert";
import { FacilityPendingAlert } from "./facilityPendingAlert";
import { FacilityUser } from "../facilityUser";
import { HamburgerIcon } from "../../icons/hamburger";
import { PrivateRoute } from "../../components/routing";
import { invokeSegment } from "../../components/segmentSnippet";
import { isTruthy } from "../../utils/errors";
import { FacilityStatusObj } from "../../utils/facility";
import meta from "../../utils/meta";
import { signInWithCustomToken } from "../enter/firebase";
import qs from "query-string";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ChatPage } from "../chat/chat";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  initDatadogRUM,
  startDatadogRUMReplayRecording,
} from "../../utils/datadogRUM";
import { initBraze } from "frontend-admin/src/utils/braze";
import useZendeskMessaging from "../../hooks/useZendeskMessaging";
import { WorkerRatingPerformanceModal } from "frontend-admin/src/components/WorkerRatingPerformanceModal/WorkerRatingPerformanceModal";
import {
  HIDE_WORKER_REVIEW_MODAL,
  HIDE_SHIFT_TIMECARD_MODAL,
} from "../../modules/shifts";
import { blankPage } from "../../components/blankSignin";
import { WorkerReviewsBanner } from "frontend-admin/src/components/WorkerReviewsBanner/WorkerReviewsBanner";
import { fetchWorkerAndWorkplaceTypes } from "../../workers/api/workerAndWorkplaceTypes";
import {
  getFinishedFetchingTypesAction,
  getStartedFetchingTypesAction,
  getWorkerTypeUpdateAction,
  getWorkPlaceTypeUpdateAction,
} from "../../modules/workerAndWorkplaceTypes/actions/actionCreators";
import { FilePreviewList } from "frontend-admin/src/components/fileUploader/filePreviewList";
import { FeatureFlag } from "frontend-admin/src/constants/FEATURE_FLAGS";
import { useFetchFlags } from "frontend-admin/src/containers/teamMembers/useTeamMembersAPIHook";
import { useNotificationCenterFlag } from "frontend-admin/src/hooks/useNotificationCenterFlag";
import { NotificationCenterButton } from "./NotificationCenter/NotificationCenterButton";
import { HeaderContainer, RightContainerOfHeader } from "./styles";
import { useFacilityOnboarding } from "frontend-admin/src/hooks/useFacilityOnboarding";
import { getLocation, routes } from "frontend-admin/src/utils/routes";
import { createPath } from "history";
import { loadLazily } from "frontend-admin/src/utils/loadLazily";
import { WorkplaceSignContractPromptModal } from "frontend-admin/src/containers/workplaceSignContract/components/PromptModal";
import { EnvironmentRibbon } from "./EnvironmentRibbon";
import { useRedirectToContractPage } from "frontend-admin/src/hooks/useRedirectToContractPage";

const SegmentDevKey = meta().segment.key;

const { Content, Sider } = Layout;

const LOGIN_PATHS = [routes.loginVerify, routes.enter];
const SHOW_CONTENTS_PATHS = [routes.facilityChat];
const PATHS_WITH_NEW_LAYOUT = [
  routes.facilityOnboarding,
  routes.workersDirectoryWorkerDetails,
  routes.workersDirectory,
  routes.workplaceBlockShifts,
  routes.workplaceSignContract,
];

const AuthorizedSignPage = loadLazily(() =>
  import("../accountManagement/authorizedSignPage").then((module) => ({
    default: module.AuthorizedSignPage,
  })),
);

const AdminAppLogin = loadLazily(() =>
  import("../enter/commonLogin").then((module) => ({
    default: module.AdminAppLogin,
  })),
);

const FirebaseLoginVerify = loadLazily(() =>
  import("../enter/commonLogin").then((module) => ({
    default: module.FirebaseLoginVerify,
  })),
);

const AgentDashboard = loadLazily(() =>
  import("../agentDashboard").then((module) => ({
    default: module.AgentDashboard,
  })),
);

const AdminDashboard = loadLazily(() =>
  import("../adminDashboard").then((module) => ({
    default: module.AdminDashboard,
  })),
);

const WorkersDirectoryWorker = loadLazily(() =>
  import("../workplace/WorkerDetails").then((module) => ({
    default: module.WorkerDetailsPage,
  })),
);

const WorkersDirectory = loadLazily(() =>
  import("../workplace/WorkersDirectory/WorkersDirectory").then((module) => ({
    default: module.WorkersDirectoryPage,
  })),
);

const ListWorkersWorker = loadLazily(() =>
  import("../workers/WorkerDetails").then((module) => ({
    default: module.WorkerDetailsPage,
  })),
);

const ListWorkers = loadLazily(() =>
  import("../workers/ListWorkers/ListWorkers").then((module) => ({
    default: module.ListWorkersPage,
  })),
);

const WorkplaceSignContract = loadLazily(() =>
  import("../workplaceSignContract/WorkplaceSignContract").then((module) => ({
    default: module.WorkplaceSignContractPage,
  })),
);

const TimeclockRequests = loadLazily(() =>
  import("../timeclockrequests").then((module) => ({
    default: module.TimeclockRequests,
  })),
);

const ReferenceForm = loadLazily(() =>
  import("../referenceForm").then((module) => ({
    default: module.ReferenceForm,
  })),
);

const Profile = LazyLoad(loadLazily(() => import("../completeProfile")));

const DownloadICAgreement = LazyLoad(
  loadLazily(() => import("../icAgreement/downloadIcAgreement")),
);

const EditProfile = loadLazily(() => import("../editProfile"));
const FacilityUserProfile = loadLazily(() =>
  import("../userProfile/facility").then((module) => ({
    default: module.FacilityUserProfile,
  })),
);

const Payroll = loadLazily(() => import("../payroll/payrollView"));
const ProofOfEarnings = loadLazily(() => import("../proofOfEarnings"));

const StripePayouts = loadLazily(() =>
  import("../payroll/stripePayouts/stripePayouts"),
);

const attendanceScore = loadLazily(() => import("../attendanceScore"));

const FacilityOnboarding = loadLazily(() =>
  import("../facilityOnboarding/FacilityOnboarding").then((module) => ({
    default: module.FacilityOnboarding,
  })),
);

const FacilityDashboard = loadLazily(() =>
  import("../facilityDashboard").then((module) => ({
    default: module.FacilityDashboard,
  })),
);

const FacilityInvoiceHistory = loadLazily(() =>
  import("../facilityInvoiceHistory").then((module) => ({
    default: module.FacilityInvoiceHistory,
  })),
);

const FacilityWorkerInstructions = loadLazily(() =>
  import("../facilityWorkerInstructions").then((module) => ({
    default: module.FacilityWorkerInstructions,
  })),
);

const FacilityCovidData = loadLazily(() =>
  import("../facilityCovid").then((module) => ({
    default: module.FacilityCovidData,
  })),
);

const FacilityTimeClockSettings = loadLazily(() =>
  import("../facilityTimeClockSettings").then((module) => ({
    default: module.FacilityTimeClockSettings,
  })),
);

const FacilityUpcomingCharges = loadLazily(() =>
  import("../facilityUpcomingCharges").then((module) => ({
    default: module.FacilityUpcomingCharges,
  })),
);

const ContractTerms = loadLazily(() =>
  import("../contractTerms").then((module) => ({
    default: module.ContractTerms,
  })),
);

const LiveMapView = loadLazily(() =>
  import("../liveMapView").then((module) => ({
    default: module.LiveMapView,
  })),
);

const Logs = loadLazily(() =>
  import("../logs").then((module) => ({ default: module.Logs })),
);

const ShiftPayments = loadLazily(() =>
  import("../shiftPayments").then((module) => ({
    default: module.ShiftPayments,
  })),
);

const AgentOnboarding = loadLazily(() => import("../onboarding/agent"));

const Accounts = loadLazily(() => import("../accountManagement"));

const PDFGenerator = loadLazily(() =>
  import("../accountManagement/invoice/pdfGenerator").then((module) => ({
    default: module.PDFGenerator,
  })),
);

const MyAccount = loadLazily(() =>
  import("../myAccount").then((module) => ({
    default: module.MyAccount,
  })),
);

const TeamMembers = loadLazily(() =>
  import("../teamMembers").then((module) => ({
    default: module.TeamMembers,
  })),
);

const InvoiceView = loadLazily(() =>
  import("../../components/invoiceView").then((module) => ({
    default: module.InvoiceView,
  })),
);

const WorkerLanding = loadLazily(() => import("../workerLanding"));

const DocumentView = loadLazily(() =>
  import("../documents").then((module) => ({ default: module.DocumentView })),
);

const DocumentEdit = loadLazily(() =>
  import("../documents").then((module) => ({ default: module.DocumentEdit })),
);

const TimesheetView = loadLazily(() =>
  import("../accountManagement/invoice/timesheetView").then((module) => ({
    default: module.TimesheetView,
  })),
);

const TimesheetViewRedirect = loadLazily(() =>
  import("../accountManagement/invoice/timesheetViewRedirect").then(
    (module) => ({
      default: module.TimesheetViewRedirect,
    }),
  ),
);

const AdminCalendar = loadLazily(() =>
  import("../calendar").then((module) => ({
    default: module.AdminCalendar,
  })),
);

const UrgentShiftsNcns = loadLazily(() => import("../urgentShiftsNcns"));

const WorkplaceBlockShifts = loadLazily(() =>
  import("../workplaceBlockShifts/WorkplaceBlockShifts").then((module) => ({
    default: module.WorkplaceBlockShifts,
  })),
);

const SideMenuDrawer = styled(Drawer)`
  .ant-drawer-content,
  .ant-drawer-header {
    background: #001529;
  }

  .logo {
    display: inline-block;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-select {
    margin: 20px;
  }
`;

const Trigger = styled(HamburgerIcon)`
  color: white !important;
  line-height: 64px !important;
  margin: 0 10px;
  font-size: 20px;
`;
const ResponsiveDiv = styled.div`
  color: white !important;
  line-height: 64px !important;
  margin: 0 10px;
  font-size: 20px;
  display: none !important;
  @media (max-width: 992px) {
    display: inline-block !important;
  }
`;

const LogoContainer = styled(Link)`
  margin: 0 20px;
  line-height: 0;
  float: left;
  min-width: fit-content;
  .logo {
    display: inline-block;
  }

  @media (max-width: 992px) {
    display: block;
    text-align: center;
    margin: 0;
    float: none;
  }
`;

const LocalTime = ({ tz }) => (
  <span className="white-text" style={{ padding: "0 20px" }}>
    Local time: &nbsp;
    <Clock format={"h:mma"} ticking={true} timezone={tz} />
    {tz ? ` (${tz}) ` : ""}
  </span>
);

const AppLayout = ({
  admin,
  isAdmin,
  isAgent,
  isFacility,
  isSuspended,
  hasSession,
  location,
  session,
  get,
  getAdminProfile,
  loadingAdminProfile,
  initFirebaseSession,
  isFirebaseSession,
  getLocations,
  getEmployees,
  logout,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState({
    drawer: true,
    sideMenu: isTruthy(localStorage.isSideMenuCollapsed) || false,
  });
  const [scrollState, setScrollState] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    /*
      0 - Mobile (Phone Portrait)
      1 - Small (Phone Landscape)
      2 - Medium (Tablet)
      3 - Large (Standard Desktop)
      4 - XLarge
    */
    size: 3,
  });
  const [requestedURL, setRequestedURL] = useState(null);

  const { access, permissions } = useSelector(
    (state) => state.session.user || {},
  );
  const { workerReview, timecardsListPreview } = useSelector(
    (state) => state.shifts,
  );

  const { showModal: showWorkplaceContractModal } = useSelector(
    (state) => state.workplaceContract,
  );

  const dispatch = useDispatch();

  const Location = useLocation();
  const pathName = Location.pathname;
  const qString = qs.parse(location.search);
  const [isToken, setToken] = useState(qString.token);
  const [loading, setLoading] = useState(true);
  const { ready, userId, user, profile, type } = useSelector(
    (state) => state.session || {},
  );
  const isAuthorized = isTruthy(isAdmin || isAgent || isFacility);
  const ldFlags = useFlags();
  const { showNotificationCenter } = useNotificationCenterFlag({
    isAdmin: !!admin,
    facilityId: profile?.userId,
    facilityMsa: profile?.fullAddress?.metropolitanStatisticalArea,
  });
  const [brazeInitialized, setBrazeInitialized] = useState(false);
  const { evaluateAndSetRedirectUrlIntoLocalStorage } =
    useRedirectToContractPage();

  const checkForAccessToken = useCallback(async (token) => {
    const result = await signInWithCustomToken(token);
    if (result.error) {
      setToken(false);
    } else {
      setToken(true);
    }
  }, []);

  const closeShiftTimecardModal = useCallback(() => {
    dispatch({ type: HIDE_SHIFT_TIMECARD_MODAL });
  }, [dispatch]);

  useEffect(() => {
    initDatadogRUM();
    startDatadogRUMReplayRecording();
  }, []);

  const getWorkerAndWorkplaceTypes = async () => {
    dispatch(getStartedFetchingTypesAction());
    const { workerTypes, workPlaceTypes } =
      await fetchWorkerAndWorkplaceTypes();
    if (workerTypes) {
      dispatch(getWorkerTypeUpdateAction(workerTypes));
    }
    if (workPlaceTypes) {
      dispatch(getWorkPlaceTypeUpdateAction(workPlaceTypes));
    }
    dispatch(getFinishedFetchingTypesAction());
  };

  useZendeskMessaging(isFacility, admin);

  useEffect(() => {
    if (!window.analytics) {
      invokeSegment(SegmentDevKey);
    }
    window.analytics.page();
  }, [Location]);

  const closeWorkerReviewModal = useCallback(() => {
    dispatch({ type: HIDE_WORKER_REVIEW_MODAL });
  }, [dispatch]);

  useEffect(() => {
    setRequestedURL(pathName);
    if (isAuthorized && ready) {
      getWorkerAndWorkplaceTypes();
    }
  }, [isAuthorized, ready]);

  useEffect(() => {
    const width = window.innerWidth;
    let size = 4;

    if (width < 576) {
      size = 0;
    } else if (width < 768) {
      size = 1;
    } else if (width < 992) {
      size = 2;
    } else if (width < 1200) {
      size = 3;
    }

    setScrollState((state) => ({ ...state, size }));
  }, []);

  useEffect(() => {
    // Workaround to avoid issues with new roles DB field. If user was
    // loggedIn and is an admin, we fetch the new admin profile to have
    // the roles field and prevent errors
    if (loadingAdminProfile) {
      getAdminProfile();
    }
  }, [loadingAdminProfile, getAdminProfile]);

  useEffect(() => {
    async function load() {
      setLoading(true);
      await initFirebaseSession();
      setLoading(false);
    }
    load();
  }, [initFirebaseSession]);

  useEffect(() => {
    if (session && session?.user?._id && !session.admin) {
      async function load() {
        setLoading(true);
        await initBraze(session?.user?._id);
        setLoading(false);
        setBrazeInitialized(true);
      }
      load();
    }
    return () => setBrazeInitialized(false);
  }, [initBraze, session?.user]);

  useEffect(() => {
    if (loading || !ready) return;
    if (!isAuthorized) return;
    get();
    getLocations();
    if (session?.admin) getEmployees();
  }, [
    isAuthorized,
    get,
    getLocations,
    getEmployees,
    loading,
    ready,
    session?.admin,
  ]);

  const userFlags = useFetchFlags(
    {
      key: profile?.userId,
      name: profile?.name,
      email: profile?.email,
      custom: {
        msa: profile?.fullAddress?.metropolitanStatisticalArea,
        facilitymsa: profile?.fullAddress?.metropolitanStatisticalArea,
        city: profile?.fullAddress?.city,
        state: profile?.fullAddress?.state,
        facilityType: profile?.type,
      },
    },
    profile,
  );

  useEffect(() => {
    if (loading || !ready) return;
    if (isToken) {
      checkForAccessToken(isToken);
    }
  }, [checkForAccessToken, loading, ready]);

  const { channels } = useSelector((state) => state.chat);
  useFacilityOnboarding(
    profile,
    userFlags,
    (includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT)) &&
      !admin,
    admin,
    isFacility,
  );

  let unreadChannelCount = 0;
  for (const channel of channels) {
    if (channel.unreadMessageCount)
      unreadChannelCount += channel.unreadMessageCount;
  }

  const toggleCollapsible = (field) => () => {
    setCollapsed((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));

    if (field === "sideMenu") {
      localStorage.setItem("isSideMenuCollapsed", !collapsed.sideMenu);
    }
  };

  const getUrlParams = () => {
    if (!location || !location.search) {
      return {};
    }
    return qs.parse(location.search);
  };

  const renderAgentDashboardRoute = () => {
    if (isAgent && !admin) {
      return <AgentExperience />;
    }

    if (isAgent) {
      return <AgentDashboard {...scrollState} />;
    }
    // pass url params through to /enter
    return <Redirect to={`/enter${location.search}`} />;
  };

  // TODO: this should be folded into the router below
  if (location.pathname.indexOf("/apply") >= 0) {
    if (hasSession) {
      window.location.href = createPath(getLocation("enter"));
      return null;
    }
    return <AgentOnboarding location={location} />;
  }

  const urlParams = getUrlParams();
  const noScroll = "noScroll" in urlParams;

  const renderEnter = () => {
    const { date } = qs.parse(location.search);
    if (isAgent) {
      if (urlParams.url) {
        window.location.href = urlParams.url;
      } else {
        return (
          <Redirect
            to={getLocation("agentDashboard", {
              queryParams: date ? { date } : undefined,
            })}
          />
        );
      }
    } else if (isFacility) {
      return (
        <Redirect
          to={getLocation("facilityDashboard", {
            queryParams: date ? { date } : undefined,
          })}
        />
      );
    } else if (isAdmin) {
      return <Redirect to={getLocation("adminProfile")} />;
    }

    evaluateAndSetRedirectUrlIntoLocalStorage();
    if (urlParams["adminId"]) {
      // no session...we were re-directed here from /admin/dashboard
      get({ userId: urlParams["adminId"], admin: true });
    }
    return <AdminAppLogin requestedURL={requestedURL} />;
  };

  if (loadingAdminProfile) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  const isDocumentAuthorized = () => {
    if (
      _get(session, "adminProfile.roles", []).includes(EMPLOYEE_ROLES.DOCUMENTS)
    ) {
      return true;
    } else if (isFacility) {
      if (!isFirebaseSession) {
        return true;
      }
      if (
        includes(access, FACILITY_ROLES.ADMIN) ||
        includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
        includes(access, FACILITY_ROLES.DOCUMENTS)
      ) {
        return true;
      }
    }
    return false;
  };

  const isLoginPath = LOGIN_PATHS.includes(location.pathname);

  if (isFacility && isFirebaseSession && access === undefined) {
    return null;
  }

  const isInvoiceViewAuthorized = () => {
    if (
      includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.INVOICES) ||
      includes(permissions, FACILITY_USER_PERMISSIONS.MANAGE_INVOICES)
    ) {
      return true;
    }

    if (isFacility && !isFirebaseSession) {
      return true;
    }

    return false;
  };

  const showAccountSwitcher = !noScroll && session.admin;
  const showFacilitySwitcher = !noScroll && isFacility && isFirebaseSession;
  if ((loading || !ready) && session.userId) return null;

  const showsBadge =
    ldFlags["chat"] &&
    (includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.DOCUMENTS) ||
      includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT));
  const cssMainDisplay = SHOW_CONTENTS_PATHS.find((path) =>
    location.pathname.startsWith(path),
  )
    ? "contents"
    : "block";
  const isPayrollViewEnabled = !Boolean(
    ldFlags["admin-app-hide-payroll-table"],
  );
  const configuration = {
    isPayrollViewEnabled,
  };
  const isRevampPages = PATHS_WITH_NEW_LAYOUT.some((path) =>
    matchPath(pathName, { path, exact: true }),
  );

  const { minimumWorkplaceCreationDate } =
    userFlags?.[FeatureFlag.HCF_ONBOARDING] ?? {};
  const workplaceInOnboardingExperiment =
    minimumWorkplaceCreationDate && profile?.createdAt
      ? new Date(profile.createdAt) > new Date(minimumWorkplaceCreationDate)
      : false;
  const hasPermissionToAccessSelfOnboardingPages =
    workplaceInOnboardingExperiment &&
    (includes(access, FACILITY_ROLES.ADMIN) ||
      includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
      admin) &&
    isFacility;

  return (
    <Fragment>
      <SideMenuDrawer
        title={
          <LogoContainer to={routes.enter}>
            <ClipboardLogo height={63} width={100} />
            <BunnyLogo height={63} width={70} />
          </LogoContainer>
        }
        placement="left"
        onClose={toggleCollapsible("drawer")}
        visible={!collapsed.drawer}
      >
        {showAccountSwitcher && (
          <AccountSwitcher
            admin={admin}
            pseudo={props.pseudo}
            closeMenu={toggleCollapsible("drawer")}
          />
        )}
        {showFacilitySwitcher && (
          <FacilityAccountSwitcher
            userId={session.userId}
            pseudo={props.pseudo}
            closeMenu={toggleCollapsible("drawer")}
          />
        )}
        {!noScroll && (
          <RightContainerOfHeader>
            <LocalTime tz={props.tz} />
            {showNotificationCenter && (
              <NotificationCenterButton brazeInitialized={brazeInitialized} />
            )}
          </RightContainerOfHeader>
        )}
        {!noScroll && (
          <AppMenu
            style={{ width: 256 }}
            isAdmin={isAdmin}
            isAgent={isAgent}
            isFacility={isFacility}
            logout={logout}
            hasSession={hasSession}
            profile={props.profile}
            configuration={configuration}
            closeMenu={toggleCollapsible("drawer")}
            unreadChannelCount={unreadChannelCount}
            emailOfLoggedInUser={session?.user?.email}
          />
        )}
      </SideMenuDrawer>
      <div>
        <Layout style={noScroll ? null : { height: "100vh" }}>
          <EnvironmentRibbon />
          <HeaderContainer>
            {hasSession && (
              <ResponsiveDiv style={{ position: "absolute", right: 0 }}>
                <Trigger
                  className="trigger"
                  onClick={toggleCollapsible("drawer")}
                />
                {showsBadge && (
                  <Badge
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#e94a4d",
                      position: "relative",
                      top: -25,
                      right: 20,
                    }}
                    count={unreadChannelCount}
                  />
                )}
              </ResponsiveDiv>
            )}
            <LogoContainer to={routes.enter}>
              <ClipboardLogo height={60} width={140} />
              <BunnyLogo height={63} width={60} />
            </LogoContainer>
            {showAccountSwitcher && (
              <AccountSwitcher
                admin={admin}
                pseudo={props.pseudo}
                className="hide-on-med-and-down"
              />
            )}
            {showFacilitySwitcher && (
              <FacilityAccountSwitcher
                userId={session.userId}
                pseudo={props.pseudo}
                adjustUIForSmallScreens={true}
              />
            )}
            {!noScroll && (
              <RightContainerOfHeader className="hide-on-med-and-down">
                <LocalTime tz={props.tz} />
                {showNotificationCenter && (
                  <NotificationCenterButton
                    brazeInitialized={brazeInitialized}
                  />
                )}
              </RightContainerOfHeader>
            )}
          </HeaderContainer>
          <Layout className={isRevampPages ? "layout-v1" : ""}>
            {isAuthorized && !noScroll && (
              <Sider
                style={{ overflowY: "auto" }}
                width="15vw"
                className="hide-on-med-and-down"
                collapsible
                collapsed={collapsed.sideMenu}
                onCollapse={toggleCollapsible("sideMenu")}
              >
                <AppMenu
                  className="hide-on-med-and-down"
                  admin={admin}
                  isAdmin={isAdmin}
                  isAgent={isAgent}
                  isFacility={isFacility}
                  logout={logout}
                  hasSession={hasSession}
                  configuration={configuration}
                  profile={props.profile}
                  mode="inline"
                  selectable={true}
                  enableDefaultSelectedKeys={true}
                  unreadChannelCount={unreadChannelCount}
                  emailOfLoggedInUser={session?.user?.email}
                />
              </Sider>
            )}
            <Content
              id="content-box"
              style={{ ...(noScroll ? null : { overflowY: "scroll" }) }}
              className={isLoginPath ? "full-screen" : ""}
            >
              {/*
                we only want to show worker reviews banner in workers and facility dashboard pages,
                it works well in workers page since it is a new styling UI (not wrapping in any tag),
                for facility dashboard to work we need to move it up here to not nest in `main` tag
              */}
              <PrivateRoute
                exact
                path={routes.facilityDashboard}
                component={WorkerReviewsBanner}
                isAuthorized={isFacility || admin}
              />

              <Suspense fallback={<FallbackLoader />}>
                <>
                  <PrivateRoute
                    exact
                    path={routes.workplaceWorkers}
                    component={ListWorkers}
                    isAuthorized={isFacility || admin}
                  />

                  <PrivateRoute
                    exact
                    path={routes.workplaceWorkerDetails}
                    component={ListWorkersWorker}
                    isAuthorized={isFacility || admin}
                  />
                  <PrivateRoute
                    exact
                    path={routes.workersDirectoryWorkerDetails}
                    component={WorkersDirectoryWorker}
                    isAuthorized={hasPermissionToAccessSelfOnboardingPages}
                  />
                  <PrivateRoute
                    exact
                    path={routes.workplaceSignContract}
                    component={WorkplaceSignContract}
                    isAuthorized={isFacility || admin}
                  />
                  <PrivateRoute
                    exact
                    path={routes.myAccount}
                    component={MyAccount}
                    isAuthorized={
                      userFlags?.[FeatureFlag.MY_ACCOUNT_PAGE] &&
                      isFacility &&
                      !admin
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.workplaceUsers}
                    component={TeamMembers}
                    isAuthorized={
                      userFlags?.[FeatureFlag.NEW_USERS_PAGE] &&
                      !(access?.includes("DMT") && access.length === 1) &&
                      (isFacility || admin)
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityOnboarding}
                    component={FacilityOnboarding}
                    isAuthorized={hasPermissionToAccessSelfOnboardingPages}
                  />

                  <PrivateRoute
                    exact
                    path={routes.workersDirectory}
                    component={WorkersDirectory}
                    isAuthorized={hasPermissionToAccessSelfOnboardingPages}
                  />

                  <PrivateRoute
                    exact
                    path={routes.workplaceBlockShifts}
                    component={WorkplaceBlockShifts}
                    isAuthorized={hasPermissionToAccessSelfOnboardingPages}
                  />
                </>
              </Suspense>
              <main style={{ display: cssMainDisplay }} id="legacy-routes">
                <Suspense fallback={<FallbackLoader />}>
                  {props.profile?.status === FacilityStatusObj.SUSPENDED && (
                    <FacilitySuspensionAlert userId={session.userId} />
                  )}
                  {props.profile?.status === FacilityStatusObj.PENDING && (
                    <FacilityPendingAlert />
                  )}
                  <Route
                    exact
                    path={routes.root}
                    render={() => <Redirect to={routes.enter} />}
                  />
                  <Route exact path={routes.admin}>
                    <Redirect to={routes.enter} />
                  </Route>
                  {(process.env.REACT_APP_NODE_ENV ?? import.meta.env.MODE) !==
                    "production" && (
                    <Route exact path={routes.blank} component={blankPage} />
                  )}
                  {/* Animated component route */}
                  <Route
                    exact
                    path={routes.signUpExperiment}
                    component={AnimatedLoading}
                  />
                  <Route path={routes.reference} component={ReferenceForm} />
                  <PrivateRoute
                    exact
                    path={routes.loginVerify}
                    component={FirebaseLoginVerify}
                    isAuthorized={!isAuthorized}
                  />
                  <Route
                    exact
                    path={routes.workers}
                    component={WorkerLanding}
                  />
                  <PrivateRoute
                    exact
                    path={routes.document}
                    component={DocumentView}
                    isAuthorized={isDocumentAuthorized()}
                  />
                  <PrivateRoute
                    exact
                    path={routes.calendar}
                    component={() => <AdminCalendar pseudo={props.pseudo} />}
                    isAuthorized={isAdmin}
                    pseudo={props.pseudo}
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminInvoice}
                    component={InvoiceView}
                    isAuthorized={
                      isAdmin || isInvoiceViewAuthorized() || isToken
                    }
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminInvoicePdf}
                    component={PDFGenerator}
                    isAuthorized={
                      isAdmin || isInvoiceViewAuthorized() || isToken
                    }
                  />
                  <PrivateRoute
                    exact
                    path={routes.timesheetRedirect}
                    component={TimesheetViewRedirect}
                    isAuthorized={isAdmin || isFacility}
                  />
                  <Route
                    exact
                    path={routes.timesheet}
                    component={TimesheetView}
                  />
                  <PrivateRoute
                    exact
                    path={routes.timesheetPdf}
                    component={PDFGenerator}
                    isAuthorized={
                      isAdmin || isInvoiceViewAuthorized() || isToken
                    }
                  />
                  <Route
                    exact
                    path={routes.signatorySign}
                    component={AuthorizedSignPage}
                  />
                  <Route
                    path={routes.enter}
                    location={location}
                    render={renderEnter}
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityDashboard}
                    component={FacilityDashboard}
                    isAuthorized={isFacility}
                  />
                  <PrivateRoute
                    exact
                    path={routes.facilityLiveWorkerETA}
                    component={LiveMapView}
                    isAuthorized={isFacility}
                  />
                  <PrivateRoute
                    exact
                    path={routes.facilityUsers}
                    component={FacilityUser}
                    isAuthorized={
                      !userFlags?.[FeatureFlag.NEW_USERS_PAGE] &&
                      (isFacility || admin)
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityWorkerInstructions}
                    component={FacilityWorkerInstructions}
                    isAuthorized={isFacility}
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityCovidData}
                    component={FacilityCovidData}
                    isAuthorized={isFacility}
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityTimeclockSettings}
                    component={FacilityTimeClockSettings}
                    isAuthorized={isFacility}
                  />

                  <PrivateRoute
                    path={routes.facilityChatWithAgent}
                    component={ChatPage}
                    isAuthorized={
                      ldFlags["chat"] &&
                      isFacility &&
                      (includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.DOCUMENTS) ||
                        includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityInvoiceHistory}
                    component={FacilityInvoiceHistory}
                    isAuthorized={
                      isFacility &&
                      (admin ||
                        includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.INVOICES) ||
                        includes(
                          permissions,
                          FACILITY_USER_PERMISSIONS.MANAGE_INVOICES,
                        ))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityInvoiceUpcomingCharges}
                    component={FacilityUpcomingCharges}
                    isAuthorized={
                      isFacility &&
                      (admin ||
                        includes(access, FACILITY_ROLES.ADMIN) ||
                        includes(access, FACILITY_ROLES.INVOICES) ||
                        includes(access, FACILITY_ROLES.SHIFT_MANAGEMENT) ||
                        includes(
                          permissions,
                          FACILITY_USER_PERMISSIONS.ACCESS_UPCOMING_CHARGES,
                        ))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityInvoiceContractTerms}
                    component={ContractTerms}
                    isAuthorized={
                      isAdmin ||
                      (isFacility &&
                        (admin ||
                          includes(access, FACILITY_ROLES.ADMIN) ||
                          includes(access, FACILITY_ROLES.INVOICES) ||
                          includes(
                            permissions,
                            FACILITY_USER_PERMISSIONS.ACCESS_CONTRACT_TERMS,
                          )))
                    }
                  />

                  <PrivateRoute
                    exact
                    path={routes.facilityProfile}
                    component={FacilityUserProfile}
                    isAuthorized={isFacility}
                  />

                  <Route
                    exact
                    path={routes.agentDashboard}
                    location={location}
                    render={renderAgentDashboardRoute}
                  />
                  {isPayrollViewEnabled && (
                    <PrivateRoute
                      path={routes.agentPayroll}
                      component={Payroll}
                      isAgent={!admin && isAgent}
                      isAuthorized={isAgent || isAdmin}
                    />
                  )}
                  <PrivateRoute
                    path={routes.agentProof}
                    component={ProofOfEarnings}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path={routes.agentStripePayouts}
                    component={StripePayouts}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />
                  <PrivateRoute
                    path={routes.agentAttendance}
                    component={attendanceScore}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />
                  <PrivateRoute
                    exact
                    path={routes.agentProfile}
                    component={EditProfile}
                    isAgent={!admin && isAgent}
                    isAuthorized={isAgent}
                  />

                  <PrivateRoute
                    exact
                    path={routes.agentICAgreement}
                    component={DownloadICAgreement}
                    isAuthorized={session.admin}
                  />

                  <PrivateRoute
                    path={routes.adminDashboard}
                    component={AdminDashboard}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path={routes.adminTimeclock}
                    component={TimeclockRequests}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminProfile}
                    component={EditProfile}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path={routes.adminAccounts}
                    component={Accounts}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path={routes.adminLogs}
                    component={Logs}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    path={routes.adminShiftPayments}
                    component={ShiftPayments}
                    isAuthorized={isAdmin}
                  />
                  <PrivateRoute
                    exact
                    path={routes.adminDocument}
                    component={DocumentEdit}
                    isAuthorized={session.adminProfile?.roles.includes(
                      EMPLOYEE_ROLES.DOCUMENTS,
                    )}
                  />
                  <PrivateRoute
                    path={routes.adminUrgentShiftNcns}
                    component={UrgentShiftsNcns}
                    isAuthorized={isAdmin}
                  />
                </Suspense>
              </main>
              <AppFooter className="hide-on-large-only" />
            </Content>
          </Layout>
          <AppFooter
            style={!noScroll ? { height: "48px", paddingTop: "16px" } : null}
            className="hide-on-med-and-down"
          />
        </Layout>
      </div>

      <Modal
        title="Complete your profile"
        visible={props.profile && props.profile.name === "" && !session.admin}
        closable={false}
        footer={null}
      >
        <Profile />
      </Modal>

      <Modal
        title="Welcome to Clipboard Health!"
        visible={"welcomeAgent" in urlParams}
        closable={false}
        onOk={() => props.history.push(location.path)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => props.history.push(location.path)}
          >
            Get Started
          </Button>,
        ]}
      >
        <p>
          Let’s make some money! Pick a day, pick a shift, and mark your
          interest.
          <br />
          <br />
          Love,
          <br />- The Clipboard Team
        </p>
      </Modal>
      {workerReview.showModal && user && (
        <WorkerRatingPerformanceModal
          visible={workerReview.showModal}
          workerId={workerReview.workerId}
          workplaceUserId={user._id}
          workplaceId={userId}
          timezone={profile?.tmz}
          actionBy={type}
          trackData={{
            ...workerReview.trackData,
            whoCalledMe: workerReview.whoCalledMe,
          }}
          onClose={closeWorkerReviewModal}
          successCallback={workerReview.successCallback}
        />
      )}
      {timecardsListPreview?.length > 0 && (
        <Modal
          visible={timecardsListPreview?.length > 0}
          footer={false}
          onCancel={closeShiftTimecardModal}
        >
          <FilePreviewList fileList={timecardsListPreview} />
        </Modal>
      )}
      {showWorkplaceContractModal && <WorkplaceSignContractPromptModal />}
    </Fragment>
  );
};

export { AppLayout };
