import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const SHOULD_SHOW_CONTRACT_TERMS = "should-show-contract-terms";

interface Session {
  admin?: boolean;
  profile?: {
    userId: string;
    name: string;
    fullAddress: {
      metropolitanStatisticalArea: string;
      city: string;
      state: string;
    };
  };
  type?: string;
}

const showContractTerms = (isAgent: boolean): boolean => {
  const {
    admin,
    profile,
    type: userType,
  } = useSelector<{ session: Session }, Session>((state) => state.session);

  const [shouldShowContractTerms, setShouldShowContractTerms] =
    useState<boolean>(false);

  const ldFlags = useFlags();
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient && profile) {
      ldClient.identify({
        key: profile?.userId ?? "",
        name: profile?.name ?? "",
        custom: {
          msa: profile?.fullAddress?.metropolitanStatisticalArea ?? "",
          city: profile?.fullAddress?.city ?? "",
          state: profile?.fullAddress?.state ?? "",
        },
      });
    }
  }, [profile, profile?.userId]);

  useEffect(() => {
    if (ldFlags?.[SHOULD_SHOW_CONTRACT_TERMS]) {
      setShouldShowContractTerms(ldFlags[SHOULD_SHOW_CONTRACT_TERMS]);
    }
  }, [ldFlags?.[SHOULD_SHOW_CONTRACT_TERMS], setShouldShowContractTerms]);

  return shouldShowContractTerms && !isAgent;
};

export { showContractTerms };
